import React from 'react';
import VideoLoop from '../../../atoms/VideoLoop';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import { cannonStyled } from '../../../theme';

const MainFlex = cannonStyled('div', (props: { isMobile: boolean }) => ({
  width: '100%',
  height: props.isMobile ? '250px' : '520px',
}));

const SectionMiddleware = (props: { src: string }) => {
  const API_URL = useAPIURL();
  const [isMobile] = useTableOrMobile();

  return (
    <MainFlex isMobile={isMobile}>
      <VideoLoop src={API_URL + props.src} width='100%' />
    </MainFlex>
  );
};

export default SectionMiddleware;
