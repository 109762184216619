import React, { useEffect, useState } from 'react';
import { cannonStyled, NSH3, useCannonStyletron } from '../../../theme';
import SuccessIcon from '../../../assets/success-register-safe-icon.svg';
import { Button, RoundedButtonsGroup } from '../../../atoms';
import { useAPIURL, useCoreAPIURL, useTableOrMobile } from '../../../hooks';
import './modal.css';

import { SurveyOptions } from './surveyOptions';
import SurveyThanks from './SurveyThanks';
import { ErrorForm } from '../../../atoms/Cards';

interface IModalInfo {
  insuranceInterested: string;
  averageValue: string;
  commonItem: string;
}

const ButtonContainer = cannonStyled(
  'div',
  ({ isMobile }: { isMobile: boolean }) => ({
    margin: '10px',
    width: '100%',
  })
);

const ButtonsContainer = cannonStyled('div', {
  marginTop: '30px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const RegisterSurvey = () => {
  // HOOKS

  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const [modalOn, setModalOn] = useState(false);
  const [submitCompleted, setSubmitCompleted] = useState(false);
  const [readyToSend, setReadyToSend] = useState(false);
  const [showFetchError, setShowFetchError] = useState(false);
  const [surveyData, setSurveyData] = useState(SurveyOptions);
  const coreAPI_URL = useCoreAPIURL();
  const API_URL = useAPIURL();
  // EFFECTS

  useEffect(() => {
    setTimeout(() => {
      setModalOn(true);
    }, 3000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setModalOn(false);
    }, 700);
  }, [submitCompleted]);

  function handleChange(value: string, index: number) {
    const tmp = surveyData.map((val) => val);
    tmp[index].answer = value;
    setSurveyData(tmp);
  }

  useEffect(() => {
    const isAllInfoFill =
      surveyData.find((item) => item.answer === '') === undefined
        ? true
        : false;
    if (isAllInfoFill) {
      setReadyToSend(true);
    }
  }, [surveyData]);

  async function sendInfo(value: any) {
    const response = await fetch(coreAPI_URL + '/insurance', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: value }),
    })
      .then((r) => r.json())
      .then((r) => {
        return r;
      })
      .catch((e) => setShowFetchError(true));
  }

  return (
    <>
      {showFetchError && <ErrorForm />}
      {!submitCompleted && (
        <>
          <NSH3
            color={theme.cannonColors.primaryBlue}
            weight={900}
            align={'center'}
            $style={{ marginBottom: '30px' }}
          >
            Would you give us a hand?
          </NSH3>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              setSubmitCompleted(true);
              sendInfo(surveyData);
            }}
          >
            {surveyData.map((question, index) => (
              <RoundedButtonsGroup
              verticalButton={isMobile}
                currentOption={question.answer}
                options={question.options}
                question={question.question}
                setCurrentOption={(optionSelected) => {
                  handleChange(optionSelected, index);
                }}
              />
            ))}
            <ButtonsContainer>
              <ButtonContainer isMobile={isMobile}>
                {readyToSend && (
                  <Button.RoundedButton
                    size='compact'
                    color='primary'
                    clean
                    fullWidth
                    type='submit'
                  >
                    Submit answers
                  </Button.RoundedButton>
                )}
              </ButtonContainer>

              <ButtonContainer isMobile={isMobile}>
                <Button.OutlinedButton
                  size='compact'
                  color='primary'
                  fullWidth
                  onClick={() => {
                    setModalOn(false);
                  }}
                >
                  I’m not interested in helping you
                </Button.OutlinedButton>
              </ButtonContainer>
            </ButtonsContainer>
          </form>
        </>
      )}
      {submitCompleted && <SurveyThanks />}
    </>
  );
};

export default RegisterSurvey;
