import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import { cannonStyled, TWH3, TWH5, useCannonStyletron } from '../../../theme';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { IFAQ } from '../../../pages/faq';
import { CannonSafeThemeT } from '../../../theme/CannonSafesTheme';
import Arrow from '../../../assets/arrow.svg';
import FAQAccordion from '../FAQDesktop/components/FAQAccordion';
import '@brainhubeu/react-carousel/lib/style.css';

interface IFAQCarrosuel {
  data: IFAQ;
  sentence: boolean;
  buttonSelected: string;
}

const MainContainer = cannonStyled('div', (props: { isMobile: boolean }) => ({
  width: '80%',
  marginLeft: '10%',
  paddingTop: '50px',
  paddingBottom: '100px',
}));

const StyledArrow = cannonStyled(Arrow, () => ({}));

const Flex = cannonStyled('div', () => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '64px',
  cursor: 'pointer',
}));

const FlexCategories = cannonStyled('div', () => ({
  width: '85%',
  height: '220px',
  position: 'absolute',
  display: 'flex',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  marginLeft: '10px',
  marginRight: '10px',
  top: '470px',
  paddingLeft: '20px',
  paddingRight: '20px',
}));

const AccordionFlex = cannonStyled('div', () => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: '10%',
}));

const WhiteSquaredButtonStyle = cannonStyled(
  'div',
  (props: {
    active: boolean;
    marginRight: boolean;
    $theme?: CannonSafeThemeT;
    smallMobile: boolean;
  }) => ({
    marginRight: props.marginRight ? 0 : '2vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: props.smallMobile ? '150px' : '184px',
    width: props.smallMobile ? '138px' : '172px',
    borderRadius: '10px',
    border: '1px solid #F4F6FB',
    boxSizing: 'border-box',
    boxShadow: `0px 14px 26px rgba(3, 64, 120, 0.06)`,
    paddingTop: '24px',
    paddingBottom: '24px',
    paddingLeft: '24px',
    paddingRight: '24px',
    background: props.active
      ? props.$theme.cannonColors.primaryBlue
      : props.$theme.cannonColors.primaryWhiteLight,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: props.active
        ? 'none'
        : props.$theme.cannonColors.primaryWhite,
    },
  })
);

const SectionMiddleWare = (props: IFAQCarrosuel) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const API_URL = useAPIURL();
  const smallMobile = useMediaQuery({ query: `(max-width: 460px)` });

  //State for color changing
  const [active, setActive] = useState('');

  const handleChange = (item) => {
    const title = item.replaceAll(' ', '-');
    if (item === active) {
      setActive('');
      scrollTo('#top');
    } else {
      setActive(item);
      scrollTo(`#${title}`);
    }
  };

  const arrayFaqSection = props.data.strapiFaq.faqSection;

  useEffect(()=> {
    setActive(props.buttonSelected);
    scrollTo('#answers');
  }, [props.buttonSelected]);

  function arrayMarginRight(index) {
    if (index != arrayFaqSection.length - 1) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <React.Fragment>
      <FlexCategories id='categories'>
        {props.data.strapiFaq.faqSection.map((item, index) => {
          //Icon constants for active state
          const iconNormal = API_URL + item.iconButton.url;
          const iconActive = API_URL + item.iconActive.url;
          return (
            <div key={item.id} className={css({ paddingRight: '20px' })}>
              <WhiteSquaredButtonStyle
                smallMobile={smallMobile}
                onClick={() => handleChange(item.title)}
                active={active === item.title}
                marginRight={arrayMarginRight(index)}
              >
                <img
                  style={{ width: '60px', height: '60px' }}
                  src={active === item.title ? iconActive : iconNormal}
                  alt={item.title}
                />
                <TWH5
                  key={item.title}
                  color={
                    active === item.title
                      ? theme.cannonColors.primaryWhiteLight
                      : theme.cannonColors.primaryBlue
                  }
                  weight={400}
                  align={'center'}
                >
                  {item.title}
                </TWH5>
              </WhiteSquaredButtonStyle>
            </div>
          );
        })}
      </FlexCategories>
      {props.sentence && (
        <AccordionFlex>
          <MainContainer isMobile={isMobile} id='answers'>
            {props.data.strapiFaq.faqSection.map((item) => {
              return (
                <React.Fragment key={item.id}>
                  <TWH3
                    isMobile={isMobile}
                    id={item.title.replaceAll(' ', '-')}
                    color={theme.cannonColors.primaryBlue}
                    weight={700}
                    align={'left'}
                    className={css({
                      fontSize: `min(7vw, 30px)`,
                      paddingBottom: '17px',
                      marginTop: '6vh',
                    })}
                  >
                    {item.title}
                  </TWH3>
                  {item.faqQuestion.map((quest) => {
                    return (
                      <React.Fragment key={quest.id}>
                        <FAQAccordion
                          question={quest.question}
                          answerBody={quest.answerBody}
                          mediaURL={quest.mediaURL}
                        />
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            })}
            <Flex>
              <TWH5
                key={'Back to categories'}
                color={theme.cannonColors.primaryBlue}
                weight={600}
                align={'center'}
                onClick={() => {
                  scrollTo('#top');
                }}
              >
                Back to categories
              </TWH5>
              <StyledArrow />
            </Flex>
          </MainContainer>
        </AccordionFlex>
      )}
    </React.Fragment>
  );
};

export default SectionMiddleWare;
