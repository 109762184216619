import React from 'react';
import { BlogCard } from '../../../atoms/BlogComponents';
import { ArticlesContainer } from '../../../atoms/BlogComponents/BlogSection';
import { RoundedButton } from '../../../atoms/Button';
import { useTableOrMobile } from '../../../hooks';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { IAllBlogs } from '../../../pages/blog';
import { cannonStyled, NSH2, TWH4, useCannonStyletron } from '../../../theme';

interface IPropsStories {
  title: string;
  subtitle: string;
  blogEntries: any;
  actionButton: string;
  actionHref: string;
  blogCategory: Array<{
    id: string;
    Name: string;
  }>;
}

const MainFlex = cannonStyled('div', () => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '5%',
  marginBottom: '8%',
}));

const StoriesFlex = cannonStyled('div', () => ({
  justifyContent: 'center',
  alignItems: 'center',
  margin: '70px 10% 0px 10%',
}));

const SectionMiddleware = (props: IPropsStories) => {
  const { width } = useWindowDimensions();
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();

  const handleClick = (item) => {
    if (item.includes('.com')) {
      item = window.open(item, '_blank');
    } else {
      item = window.location.href = `${item}`;
    }
  };

  const cleanArray = props.blogCategory.map((item) => {
    return {
      id: parseInt(item.id.replace(/[a-zA-Z-[_]/g, '')),
      Name: item.Name,
    };
  });

  var category = ' ';

  function handleCategory(item: number) {
    cleanArray.reduce((_currentIndex, array) => {
      if (array.id == item) {
        return (category = array.Name);
      }
    });
  }

  return (
    <React.Fragment>
      <MainFlex>
        <NSH2
          color={theme.cannonColors.primaryBlue}
          align='center'
          weight={900}
        >
          {props.title.toUpperCase()}
        </NSH2>
        <TWH4
          color={theme.cannonColors.primaryGray}
          weight={400}
          align={'center'}
          className={css({ paddingTop: '20px' })}
        >
          {props.subtitle}
        </TWH4>
        <StoriesFlex>
          <ArticlesContainer isOurLegacy={true} width={width}>
            {props.blogEntries.map((item) => {
              handleCategory(item.blogCategory);
              return (
                <BlogCard
                  isLegacy={true}
                  title={item.title}
                  previewText={item.previewText}
                  previewImage={item.previewImage}
                  mainArticle={item.mainArticle}
                  mainMedia={item.mainMedia}
                  slug={item.slug}
                  blogCategory={{
                    Name: category,
                    id: item.id,
                  }}
                />
              );
            })}
          </ArticlesContainer>
        </StoriesFlex>
        <RoundedButton
          onClick={() => handleClick(props.actionHref)}
          size='compact'
          color='primary'
          clean
          smallBtn
          margin={80}
          fontSize='16px'
          $style={{ marginTop: '4vh' }}
        >
          {props.actionButton}
        </RoundedButton>
      </MainFlex>
    </React.Fragment>
  );
};

export default SectionMiddleware;
