import * as React from 'react';
import SimpleReactLightbox from 'simple-react-lightbox';
import { IFileImage } from '../../../@types';
import {
  BlogGallery,
  BlogMainText,
  BlogSideBySide,
  BlogVideo,
} from '../../../atoms/BlogComponents';
import { useAPIURL, useOrientation, useTableOrMobile } from '../../../hooks';
import { cannonStyled, NSH1, TWH1, useCannonStyletron } from '../../../theme';
import { CannonSafeThemeT } from '../../../theme/CannonSafesTheme';

interface MarkDownProps {
  color?: string;
  isMobile?: boolean;
  isTablet?: boolean;
  margin?: number;
  $theme?: CannonSafeThemeT;
  isRotated?: boolean;
}

const MainBlogArticleContainer = cannonStyled('div', {
  marginBottom: '10vh',
});

const MainImgContainer = cannonStyled('div', (props: MarkDownProps) => ({
  marginTop: props.isMobile ? '15px' : '80px',
  marginBottom: '40px',
  width: '100%',
  height: props.isMobile ? '480px' : '400px',
  borderRadius: '8px',
  overflow: 'hidden',
  position: 'relative',
}));

const MainImg = cannonStyled('img', (props: MarkDownProps) => ({
  width: props.isMobile ? 'auto' : '100%',
  height: props.isMobile ? '100%' : 'auto',
  objectFit: 'cover',
  verticalAlign: 'middle',
}));

const ZindexTop = cannonStyled('div', (props: MarkDownProps) => ({
  zIndex: 9,
  width: '80%',
  position: 'absolute',
  margin: props.isRotated ? '380px 6% 0 6%' : '335px 6% 0 6%',
}));

const ImgWrap = cannonStyled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const Overlay = cannonStyled('div', (props: MarkDownProps) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.62) 0%, rgba(0, 0, 0, 0) 90%)`,
  opacity: 0.6,
}));

const TextContainer = cannonStyled('div', {
  textAlign: 'left',
  margin: '25px 0 15px 0',
  width: '100%',
});

const Container = cannonStyled('div', {
  margin: '0 0 30px 0',
});

const VideoContainer = cannonStyled('div', {
  margin: '40px 0',
});

interface PostDetail {
  title: string;
  mainMedia: IFileImage;
  blogCategory: string;
  bodyText?: string;
  blogEntries?: any;
}

const PostDetail = (props: PostDetail) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const { isRotated } = useOrientation();

  const API_URL = useAPIURL();

  const show = (arrayElement) => {
    if (arrayElement.length > 0) return true;
  };

  // Find object for image gallery
  const galleryArray = props.blogEntries.filter(
    (item) => item.strapi_component === 'blog.gallery'
  );

  // Find first video from component named blog.video
  const blogVideoArray = props.blogEntries.filter(
    (item) => item.strapi_component === 'blog.video'
  );

  // Find all text and images from component named blog.video
  const textAndImageArray = props.blogEntries.filter(
    (item) => item.strapi_component === 'blog.text-and-image'
  );

  //Find if there are more text
  const moreText = props.blogEntries.filter(
    (item) => item.strapi_component === 'blog.only-text'
  );

  const isBodyText: boolean =
    props.bodyText === null ? true : props.bodyText.length === 0 ? true : false;

  // Condition to hide image banner when only video is in the article
  const ImgContainerShow: boolean =
    isBodyText && galleryArray.length === 0 && textAndImageArray.length === 0;
  return (
    <MainBlogArticleContainer>
      {!ImgContainerShow && (
        <MainImgContainer isMobile={isMobile}>
          <ImgWrap aria-label={props.title}>
            <MainImg src={API_URL + props.mainMedia.url} isMobile={isMobile} alt={props.title} />
          </ImgWrap>

          {isMobile && (
            <>
              <Overlay />
              <ZindexTop isRotated={isRotated}>
                <TWH1
                  color={theme.cannonColors.primaryWhiteLight}
                  weight={700}
                  align='left'
                  $style={{
                    fontSize: isMobile ? '15px' : `22px`,
                  }}
                >
                  {props.blogCategory}
                </TWH1>
                <NSH1
                  color={theme.cannonColors.primaryWhiteLight}
                  weight={900}
                  align='left'
                  $style={{
                    fontSize: isMobile ? '24px' : `50px`,
                    lineHeight: isMobile ? '32px' : `58px`,
                  }}
                >
                  {props.title}
                </NSH1>
              </ZindexTop>
            </>
          )}
        </MainImgContainer>
      )}

      {!isMobile && (
        <TextContainer>
          <TWH1
            color={theme.cannonColors.secondaryOrange}
            weight={700}
            align='left'
            $style={{
              fontSize: isMobile ? '15px' : `22px`,
            }}
          >
            {props.blogCategory}
          </TWH1>
          <NSH1
            color={theme.cannonColors.primaryBlue}
            weight={900}
            align='left'
            $style={{
              fontSize: isMobile ? '24px' : `50px`,
            }}
          >
            {props.title}
          </NSH1>
        </TextContainer>
      )}

      {props.bodyText && <BlogMainText mainText={props.bodyText} />}

      {show(galleryArray) && (
        <SimpleReactLightbox>
          <BlogGallery mediaArray={galleryArray[0].media} $label={props.title} />
        </SimpleReactLightbox>
      )}

      {show(textAndImageArray) && (
        <Container>
          {textAndImageArray.map((element) => {
            return (
              <BlogSideBySide
                leftImage={element.leftImage}
                leftText={element.leftText}
                leftTitle={element.leftTitle}
                rightImage={element.rightImage}
                rightText={element.rightText}
                rightTitle={element.rightTitle}
              />
            );
          })}
        </Container>
      )}

      {show(blogVideoArray) && (
        <VideoContainer>
          {blogVideoArray.map((videoItem) => {
            return (
              <BlogVideo
                video={videoItem.video}
                videoUrl={videoItem.videoUrl}
                videoPreviewImage={videoItem.videoPreviewImage}
                title={props.title}
              />
            );
          })}
        </VideoContainer>
      )}

      {show(moreText) && <BlogMainText mainText={moreText[0].moreText} />}

    </MainBlogArticleContainer>
  );
};

export default PostDetail;
