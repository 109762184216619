import React, { useRef, useState } from 'react';
import { useCannonStyletron } from '../../../../theme/CannonSafesTheme';
import { useAPIURL, useTableOrMobile } from '../../../../hooks';
import { Player, ControlBar, BigPlayButton } from 'video-react';
import './MediaRounded.css';
import PlayIcon from '../../../../assets/play-icon.svg';

export default ({
  ext,
  src,
  videoPreview = '',
  isOurLegacy,
}: {
  ext?: string;
  src: string;
  alt?: string;
  $label?: string;
  videoPreview?: string;
  width?: string;
  isOurLegacy?: boolean;
}) => {
  // HOOKS
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const API_URL = useAPIURL();
  const [videoStrappiPlayed, setVideoStrappiPlayed] = useState(false);
  const iframeRef = useRef() as any;
  const videoRef = useRef() as any;

  //HANDLERS && FUNCTIONS

  function getTypeOfMedia(_ext: string, _src: string) {
    let tmp = 'image';
    if (_src.includes('youtu') || _src.includes('vimeo')) {
      tmp = 'iframe';
    } else if (_ext.includes('mp4' || 'mov' || 'wmv' || 'flv')) {
      tmp = 'video';
    }
    return tmp;
  }
  function getUrlFormated(value: string) {
    if (value.includes('youtu')) {
      const tmp = value.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      const id =
        tmp[2] !== undefined ? tmp[2].split(/[^0-9a-z_\-]/i)[0] : tmp[0];
      return 'https://www.youtube.com/embed/' + id;
    } else if (value.includes('vimeo')) {
      const tmp = value.split('.com/');
      return 'https://player.vimeo.com/video/' + tmp[1];
    } else return value;
  }

  // CSS CLASSES

  const fullScreenVideoContainer = css({
    position: 'relative',
    height: '100%',
    width: isMobile ? '90vw' : '70vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    overflow: 'hidden',
  });

  const fullScreenVideoContainerVideo = css({
    position: 'absolute',
    width: '100%',
    height: 'auto',
    minWidth: '100%',
    minHeight: '100%',
    top: isOurLegacy ? 0 : '50%',
    left: isOurLegacy ? 0 : '50%',
    transform: isOurLegacy ? 'none' : 'translate(-50%, -50%)',
  });

  const mainContainer = css({
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  });

  const videoPreviewContainer = css({
    fontSize: '50px',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  });

  // RENDER
  const typeOfMedia = getTypeOfMedia(ext || '', src);

  return (
    <div className={mainContainer}>
      {typeOfMedia === 'video' ? (
        <div className={fullScreenVideoContainer} id='embed-container'>
          {isMobile && !videoStrappiPlayed && videoPreview.length > 0 ? (
            <div
              className={videoPreviewContainer}
              onClick={() => {
                videoRef.current.play();
                setVideoStrappiPlayed(true);
              }}
            >
              <PlayIcon />
            </div>
          ) : (
            <></>
          )}
          <Player
            className={fullScreenVideoContainerVideo}
            autoplay
            fluid
            ref={videoRef}
            poster={`${API_URL + videoPreview}`}
          >
            <source src={src} />
            <BigPlayButton position='center' />
            <ControlBar autoHide={true} />
          </Player>
        </div>
      ) : (
        <></>
      )}
      {typeOfMedia === 'iframe' ? (
        <div className={fullScreenVideoContainer}>
          <iframe
            id='iframe'
            width='100%'
            height='100%'
            style={{ border: 'none', background: isMobile ? 'black' : 'none' }}
            className={fullScreenVideoContainerVideo}
            ref={iframeRef}
            src={getUrlFormated(src)}
            title='Video player'
            allow='autoplay, fullscreen'
            allowFullScreen
          ></iframe>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
