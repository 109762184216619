import React from 'react';
import {
  CenteredContainer,
  ResponsiveContainer,
} from '../../../atoms/Container';
import { BigSpace } from '../../../atoms/Space';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import { cannonStyled, NSH2, TWH4, useCannonStyletron } from '../../../theme';
import { CannonSafeThemeT } from '../../../theme/CannonSafesTheme';
import { ISeriesFeatures } from '../../../templates/safesSeries';
import WhiteCard from '../../../atoms/Cards/WhiteCard';

interface ISeriesFeaturesProps {
  isMobile?: boolean;
  $theme?: CannonSafeThemeT;
  isLeft?: boolean;
  title?: string;
  subtitle?: string;
  cardHeader?: string;
  cardTitle?: string;
  cardBody?: string;
  src?: string;
  list?: Array<ISeriesFeatures>;
}

const WrapperMain = cannonStyled('div', (props: ISeriesFeaturesProps) => ({
  display: 'column',
  paddingLeft: props.isMobile ? '1%' : '15%',
  paddingRight: props.isMobile ? '5%' : '15%',
  lineHeight: 'auto !important',
}));

const ImgStack = cannonStyled('div', (props: ISeriesFeaturesProps) => ({
  display: 'flex',
  position: 'relative',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  marginLeft: 0,
  marginRight: 0,
  flexDirection: 'column',
  justifyContent: 'center',
  lineHeight: 'auto !important',
  height: props.isMobile ? '45vh' : '60vh',
}));

const ImgContainer = cannonStyled('img', (props: ISeriesFeaturesProps) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  left: props.isMobile ? 0 : props.isLeft ? 0 : '43%',
  width: props.isMobile ? '100%' : '40vw',
  height: '100%',
  borderRadius: '10px',
  objectFit: 'cover',
  zIndex: 0,
}));

const SectionMiddleware = (props: ISeriesFeaturesProps) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const API_URL = useAPIURL();

  return (
    <>
      <WrapperMain isMobile={isMobile}>
        <CenteredContainer>
          <ResponsiveContainer flexDirection='column'>
            <NSH2
              weight={900}
              color={theme.cannonColors.primaryBlue}
              align={'center'}
              className={css({ paddingTop: '140px', margin: 0 })}
            >
              {props.title.toUpperCase()}
            </NSH2>
            <TWH4
              isMobile={isMobile}
              weight={400}
              color={theme.cannonColors.primaryGray}
              align={'center'}
              margin={0}
              className={css({
                paddingBottom: '140px',
                fontSize: `min(4vw, 24px) !important`,
              })}
            >
              {props.subtitle}
            </TWH4>
          </ResponsiveContainer>
        </CenteredContainer>

        {props.list.map((data, index) => {
          if (index % 2 == 0 || index == 0) {
            return (
              <>
                <ImgStack isMobile={isMobile}>
                  <ImgContainer
                    isMobile={isMobile}
                    src={API_URL + data.backgroundImage.url}
                    isLeft={true}
                    alt={data.cardTitle}
                  />
                  <WhiteCard
                    label={data.cardHeader}
                    title={data.cardTitle}
                    description={data.cardDescription}
                    isMobile={isMobile}
                    isLeft={true}
                  />
                </ImgStack>
                <BigSpace isMobile={isMobile} direction='vertical' />
              </>
            );
          } else {
            return (
              <>
                <ImgStack isMobile={isMobile}>
                  <ImgContainer
                    isMobile={isMobile}
                    src={API_URL + data.backgroundImage.url}
                    isLeft={false}
                    alt={data.cardTitle}
                  />
                  <WhiteCard
                    label={data.cardHeader}
                    title={data.cardTitle}
                    description={data.cardDescription}
                    isMobile={isMobile}
                    isLeft={false}
                  />
                </ImgStack>
                <BigSpace isMobile={isMobile} direction='vertical' />
              </>
            );
          }
        })}
      </WrapperMain>
    </>
  );
};

export default SectionMiddleware;
