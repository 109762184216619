import React, { useEffect, useState } from 'react';
import { cannonStyled, NSH2, TWH4, TWParagraph3 } from '../../../theme';
import {
  CannonSafeThemeT,
  useCannonStyletron,
} from '../../../theme/CannonSafesTheme';
import { useAPIURL, useOrientation, usePostService, useTableOrMobile } from '../../../hooks';
import SignMeUpForm from './signMeUpForm';
import SignMeUpThanks from './signMeUpThanks';

import { ISignMeUpResponse } from '../../../pages';

interface ISignUpProps {
  isMobile: boolean;
  isRotated?: boolean;
  $theme?: CannonSafeThemeT;
  bgImage?: string;
}

const SignUpContainer = cannonStyled('div', (props: ISignUpProps) => ({
  position: 'relative',
  height: props.isMobile && props.isRotated ? '100vh' : '45vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: `url(${props.bgImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}));

const SignUpMask = cannonStyled('div', (props: ISignUpProps) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  left: props.isMobile ? 0 : '40%',
  background: 'rgba(0, 0, 0, 0.5)',
  padding: '0px 7vw 0px 7vw',
}));

const SectionMiddleware = (data: ISignMeUpResponse) => {
  const [isMobile] = useTableOrMobile();
  const { isRotated } = useOrientation();
  const [css, theme] = useCannonStyletron();
  const [submited, setSubmited] = useState(false);

  const API_URL = useAPIURL();

  if (!data.strapiSignMeUp.enabled) return null;

  return (
    <SignUpContainer 
    isMobile={isMobile} 
    isRotated={isRotated} 
    bgImage={API_URL + data.strapiSignMeUp.background.url}
    >
      <SignUpMask isMobile={isMobile}>
        {!submited && (
          <SignMeUpForm
            description={data.strapiSignMeUp.description}
            text={data.strapiSignMeUp.text}
            acknowledgment={data.strapiSignMeUp.acknowledgment}
            showThanks={() => {
              setSubmited(true);
            }}
          />
        )}
        {submited && <SignMeUpThanks />}
      </SignUpMask>
    </SignUpContainer>
  );
};

export default SectionMiddleware;
