import React from 'react';
import { ResponsiveContainer } from '../../../atoms/Container';
import { ImageWithCenteredC } from '../../../atoms/Image';
import {
  useAPIURL,
  useNewLineText,
  useOrientation,
  useTableOrMobile,
} from '../../../hooks';
import { Wrapper } from '../../layout';
import { Button } from '../../../atoms';

import { cannonStyled, NSH1, TWH4, useCannonStyletron } from '../../../theme';
import { IResponseFindASafe } from '../../../pages';
import useFindYourSafe from '../../../atoms/FindYourSafe/useFindYourSafe';
import ArrowRight from '../../../assets/arrow-right.svg';

const Overlay = cannonStyled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 0,
  background:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(.png)',
});

const SectionMiddleware = (data: IResponseFindASafe) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const API_URL = useAPIURL();
  const { isRotated } = useOrientation();

  const { toggleOverlay } = useFindYourSafe();
  if (!data.strapiFindASafe.enabled) return null;

  const title = useNewLineText(data.strapiFindASafe.title);
  const description = useNewLineText(data.strapiFindASafe.description);

  return (
    <Wrapper
      style={isRotated && isMobile ? { height: '400px' } : {}}
      noScroll
      isCentered
      direction='vertical'
    >
      <ImageWithCenteredC
        height={data.strapiFindASafe.image.height}
        width={data.strapiFindASafe.image.width}
        src={API_URL + data.strapiFindASafe.image?.url}
        alt='nono'
      >
        <Overlay />
        <ResponsiveContainer
          $style={{
            ...{ paddingBottom: '5%', paddingTop: '5%', zIndex: 1 },
            ...(isRotated && isMobile ? { height: 'fit-content', position: 'absolute', bottom: 0 } : {}),
          }}
          bottomLeft
          fullHeight
          flexDirection='column'
        >
          <NSH1
            color={theme.cannonColors.primaryWhiteLight}
            margin={0}
            $style={{ fontSize: 'min(8vw, 50px)' }}
            align='left'
            weight={900}
          >
            {title}
          </NSH1>
          <TWH4
            style={{ maxWidth: isMobile ? '100%' :  '60%' }}
            color={theme.cannonColors.primaryWhiteLight}
            align='left'
            weight={400}
            margin={10}
          >
            {description}
          </TWH4>
          <Button.RoundedButton
            size='compact'
            margin={20}
            color='tertiary'
            endEnhancer={
              <ArrowRight style={{ stroke: theme.cannonColors.primaryBlue }} />
            }
            onClick={toggleOverlay}
            fullWidth={isMobile}
          >
            {data.strapiFindASafe.action}
          </Button.RoundedButton>
        </ResponsiveContainer>
      </ImageWithCenteredC>
    </Wrapper>
  );
};

export default SectionMiddleware;
