import React, { useState } from 'react';
import { cannonStyled, NSH1, TWH4 } from '../../../theme';
import { ResponsiveContainer } from '../../../atoms/Container';
import { StoryCard } from '../../../atoms/Cards';
import { Wrapper } from '../../layout';
import {
  CannonSafeThemeT,
  useCannonStyletron,
} from '../../../theme/CannonSafesTheme';
import { SmallSpace } from '../../../atoms/Space';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import { IResponseStories } from '../../../pages';
import Carousel, { Dots, autoplayPlugin } from '@brainhubeu/react-carousel';

interface IStoryGrid {
  isMobile: boolean;
  children: JSX.Element[];
  $theme?: CannonSafeThemeT;
}

const StoryGridBase = cannonStyled('div', (props: IStoryGrid) => ({
  display: 'grid',
  flex: 1,
  gridTemplateColumns: props.isMobile ? '1fr' : '5fr 1fr 5fr',
  ...(props.isMobile
    ? {}
    : {
        gridTemplateRows: '1fr 4fr 1fr 15px '.repeat(
          Math.ceil(props.children.length / 2)
        ),
      }),
  gridRowGap: props.isMobile ? '40px' : '0px',
  width: '90%',
  maxWidth: '1280px',
  minHeight: `${Math.ceil(props.children.length / 2) * 65}vh`,
  marginTop: '90px',
  marginBottom: props.isMobile ? '0px' : '90px',
}));

const StoryGrid = (props: IStoryGrid) => {
  const [css, theme] = useCannonStyletron();

  const createStyle = (refIndex: number) => {
    let referenceQuad = Math.floor(refIndex / 4);
    let rowSpan = referenceQuad * 8;
    refIndex -= referenceQuad * 4;

    if (props.isMobile) {
      return css({ padding: '10px' });
    } else {
      switch (refIndex) {
        case 0:
          return css({
            gridColumn: '1',
            gridRow: `${rowSpan + 1} / span 4`,
            padding: '10px',
          });
        case 1:
          return css({
            gridColumn: '3',
            gridRow: `${rowSpan + 2} / span 1`,
            padding: '10px',
          });
        case 2:
          return css({
            gridColumn: '1',
            gridRow: `${rowSpan + 6} / span 1`,
            padding: '10px',
          });
        case 3:
          return css({
            gridColumn: '3',
            gridRow: `${rowSpan + 4} / span 4`,
            padding: '10px',
          });
        default:
          break;
      }
    }
  };

  return (
    <StoryGridBase className='story grid' {...props}>
      {React.Children.map(props.children, (child, index) =>
        React.cloneElement(child, { styled: createStyle(index) })
      )}
    </StoryGridBase>
  );
};

const WrapperWithMargin = cannonStyled(
  Wrapper,
  ({ isMobile }: { isMobile: boolean }) => ({
    marginTop: isMobile ? '' : '140px',
    marginBottom: isMobile ? '' : '90px',
  })
);

const Thumbnail = cannonStyled('div', () => ({
  width: '29.33px',
  height: '2px',
  marginBottom: '30px',
}));

const SectionMiddleware = (data: IResponseStories) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const API_URL = useAPIURL();

  // STATE
  const [mobileCarouselValue, setMobileCarouselValue] = useState(0);
  const dataLength = data.allStrapiBlogEntries.nodes.length;

  // HANDLERS && FUNCTIONS

  function handleCarouselChange(value: any) {
    setMobileCarouselValue(value);
  }

  const highlightActiveDot = (index) => {
    if (mobileCarouselValue < 0) {
      return Math.abs(
        mobileCarouselValue % dataLength === 0
          ? 0
          : (mobileCarouselValue % dataLength) + dataLength
      ) === index
        ? 'rgba(200, 109, 40, 1)'
        : 'rgba(158, 162, 169, 1)';
    }

    return mobileCarouselValue % dataLength === index
      ? 'rgba(200, 109, 40, 1)'
      : 'rgba(158, 162, 169, 1)';
  };

  const renderThumbnails = () => {
    return Array(dataLength)
      .fill(0)
      .map((_, index) => (
        <Thumbnail
          className={css({ backgroundColor: highlightActiveDot(index) })}
        />
      ));
  };

  if (!data.strapiStories.enabled) return null;

  let firstFourArticles = data.allStrapiBlogEntries.nodes
    .filter((article) => article.showInHome === true)
    .slice(0, 4);

  return (
    <WrapperWithMargin
      isMobile={isMobile}
      noScroll
      autoHeight
      isCentered
      direction='vertical'
    >
      <NSH1
        $style={{
          textTransform: 'uppercase',
          fontSize: isMobile ? '40px' : '42px',
        }}
        color={theme.cannonColors.primaryBlue}
        align='center'
        weight={900}
      >
        {data.strapiStories.title}
      </NSH1>
      <TWH4 color={theme.cannonColors.primaryGray} align='center' weight={400}>
        {data.strapiStories.description}
      </TWH4>
      <SmallSpace direction='vertical' />
      <ResponsiveContainer>
        {isMobile ? (
          <div style={{ width: '100%', marginTop: '30px' }}>
            <Carousel
              value={mobileCarouselValue}
              onChange={handleCarouselChange}
              animationSpeed={1000}
              plugins={[
                'infinite',
                {
                  resolve: autoplayPlugin,
                  options: {
                    interval: 10000,
                  },
                },
              ]}
            >
              {firstFourArticles.map((item) => (
                <StoryCard
                  key={item.title}
                  src={API_URL + item.mainMedia.url}
                  title={item.title}
                  condensedText={item.previewText}
                  actionTitle={item.homeBtnText}
                  category={item.blogCategory.Name}
                  slug={item.slug}
                />
              ))}
            </Carousel>
            <Dots
              value={mobileCarouselValue}
              onChange={handleCarouselChange}
              number={4}
              thumbnails={renderThumbnails()}
            />
          </div>
        ) : (
          <StoryGrid isMobile={isMobile}>
            {firstFourArticles.map((item) => (
              <StoryCard
                key={item.title}
                src={API_URL + item.mainMedia.url}
                title={item.title}
                condensedText={item.previewText}
                actionTitle={item.homeBtnText}
                category={item.blogCategory.Name}
                slug={item.slug}
              />
            ))}
          </StoryGrid>
        )}
      </ResponsiveContainer>
    </WrapperWithMargin>
  );
};

export default SectionMiddleware;
