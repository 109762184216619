import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Wrapper } from '../../layout';
import { H1 } from 'baseui/typography';
import { GridContainer, ResponsiveContainer } from '../../../atoms/Container';
import { cannonStyled, NSH2, TWH4, useCannonStyletron } from '../../../theme';
import { SafeCategoryCard } from '../../../atoms/Cards';
import { SmallSpace } from '../../../atoms/Space';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import { IResponseCategories } from '../../../pages';

const WrapperWithMargin = cannonStyled(Wrapper, {
  marginTop: '90px',
  marginBottom: '90px',
});

const SectionMiddleware = (data: IResponseCategories) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const API_URL = useAPIURL();

  const direction = 'horizontal';
  const width = isMobile || isTablet ? '90%' : '70%';

  if (
    !data.strapiCategories.enabled ||
    !data.allStrapiSafeCategory.nodes.length
  )
    return null;

  function handleCardClicked(_title: string) {
    if (_title.toLowerCase().includes('gun'))
      window.location.href = '/safes/?type=Gun+Safes';
    else window.location.href = '/safes/?type=Home+Safes';
  }

  return (
    <WrapperWithMargin noScroll compact isCentered direction='vertical'>
      <NSH2
        style={{ textTransform: 'uppercase' }}
        color={theme.cannonColors.primaryBlue}
        align='center'
        weight={900}
      >
        {data.strapiCategories.title}
      </NSH2>
      <TWH4 color={theme.cannonColors.primaryGray} align='center' weight={400}>
        {/* TODO: Make this come from strapi */}
        {data.strapiCategories.description}
      </TWH4>
      <ResponsiveContainer $style={{ marginTop: '90px', marginBottom: '90px' }}>
        <SmallSpace direction='vertical' />
        <GridContainer
          width={width}
          isMobile={isMobile}
          isTablet={isTablet}
          items={2}
          direction={direction}
          spacing={isMobile ? '20px' : '40px'}
        >
          {data.allStrapiSafeCategory.nodes.map((safeCategory) => (
            <SafeCategoryCard
              key={safeCategory.title}
              actionTitle='Nope'
              actionURL='nada'
              src={API_URL + safeCategory.identifierImage.url}
              title={safeCategory.title}
              onClick={() => handleCardClicked(safeCategory.title)}
            />
          ))}
        </GridContainer>
      </ResponsiveContainer>
    </WrapperWithMargin>
  );
};

export default SectionMiddleware;
