import React from 'react';
import { useTableOrMobile } from '../../hooks';
import { useCannonStyletron } from '../../theme';
import VideoLoop from '../VideoLoop';

interface Props {
  src: string;
  alt?: string;
  isVideo?: boolean;
  children: any;
  $borderRadius: string;
}

export default ({ src, alt, children, isVideo = false, $borderRadius }: Props) => {

  // HOOKS
  const [isMobile] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();

  const containerClass = css({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  });

  const imgClass = css({
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: isMobile ? '12px' : '',
  });

  const imageContainerClass = css({
    position: 'relative',
    width: '90%',
    height: '85%',
    maxWidth: '1280px',
    overflow: 'hidden',
    borderRadius: $borderRadius ? $borderRadius : 0,
  });

  const childContainerClass = css({
    position: 'absolute',
    padding: theme.sizing.scale1400,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow:  isMobile ? '' : '0px 7px 44px rgba(51, 51, 51, 0.25)',
    borderRadius: isMobile ? '12px' : '',
    background:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(.png)',
  });

  return (
    <div className={containerClass}>
      <div className={imageContainerClass}>
        {isVideo ? (
          <VideoLoop src={src} />
        ) : (
          <img className={imgClass} src={src} alt={alt} />
        )}
        <div className={childContainerClass}>{children}</div>
      </div>
    </div>
  );
};
