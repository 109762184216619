import React from 'react';
import { H1, Paragraph3 } from 'baseui/typography';
import { MediaRounded } from '../../../atoms/Image';
import { Wrapper } from '../../layout';

import { useAPIURL, useNewLineText, useTableOrMobile } from '../../../hooks';
import { cannonStyled, NSH1, TWH4, useCannonStyletron } from '../../../theme';
import { IResponseLifestyleBanner } from '../../../pages';
import { ResponsiveContainer } from '../../../atoms/Container';
import { IFileImage } from '../../../@types';

const WrapperWithMargin = cannonStyled(Wrapper, ({isMobile}:{isMobile: boolean}) => ({
  marginTop: isMobile ? '90px' : '180px',
  marginBottom: '180px',
}));

const SectionMiddleware = (data: IResponseLifestyleBanner) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();

  if (!data.strapiLifestyleBanner.enabled) return null;

  const title = useNewLineText(data.strapiLifestyleBanner.title);

  const {
    media = {} as IFileImage,
    videoLink = '',
    videoPreviewImage = {} as IFileImage,
  } = data.strapiLifestyleBanner;

  const { url: videoPreviewImageUrl = '' } = videoPreviewImage || {} as IFileImage;

  const API_URL = useAPIURL();

  const mediaUrl =
    videoLink?.length > 0
      ? videoLink
      : media?.url.length > 0
      ? `${API_URL}${media.url}`
      : null;

  return (
    <WrapperWithMargin
      noScroll
      autoHeight
      compact
      isCentered
      direction='vertical'
      isMobile={isMobile}
    >
      <ResponsiveContainer flexDirection='column'>
        <NSH1
          color={theme.cannonColors.primaryGray}
          align='center'
          weight={400}
        >
          {title}
        </NSH1>
        <TWH4
          color={theme.cannonColors.secondarySilverDark}
          align='center'
          weight={400}
          margin={40}
        >
          {data.strapiLifestyleBanner.description}
        </TWH4>
      </ResponsiveContainer>
        <MediaRounded
          videoPreview={videoPreviewImageUrl}
          src={mediaUrl || ''}
          alt={data.strapiLifestyleBanner.title}
          ext={data.strapiLifestyleBanner.media?.ext || ''}
        />
    </WrapperWithMargin>
  );
};

export default SectionMiddleware;
