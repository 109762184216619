import React from 'react';
import { IFileImage } from '../../../@types';
import { RoundedButton } from '../../../atoms/Button';
import { ResponsiveContainer } from '../../../atoms/Container';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import {
  cannonStyled,
  NSH2,
  TWParagraph3,
  useCannonStyletron,
} from '../../../theme';
import { Wrapper } from '../../layout';

const BackgroundWrapper = cannonStyled(
  Wrapper,
  ({ isMobile, isTablet }: { isMobile: boolean; isTablet: boolean }) => ({
    height: isTablet ? '800px' : '1000px',
    minWidth: '100%',
    minHeight: '100%',
    objectFit: 'cover',
    // background:
    // `linear-gradient(180deg, transparent 0%, rgba(199,199,199,0.8295693277310925) 250px, rgba(0,0,0,1) 100%)`,
    justifyContent: 'flex-start',
  })
);

const TitleContainer = cannonStyled(
  ResponsiveContainer,
  ({ isMobile, isTablet }: { isMobile: boolean; isTablet: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: isMobile ? '70px' : isTablet ? '30px' : '130px',
    justifyContent: 'flex-start',
  })
);

const Image = cannonStyled('img', ({ isMobile }: { isMobile: boolean }) => ({
  position: 'absolute',
  top: '0px',
  left: '0px',
  right: '0px',
  width: '100%',
  objectFit: 'cover',
  height: '100%',
  maskImage: `-webkit-gradient(linear, center top, center bottom, 
    color-stop(0.00,  rgba(0,0,0,0)),
    color-stop(0.5,  rgba(0,0,0,1)),
    color-stop(0.50,  rgba(0,0,0,1)),
    color-stop(0.65,  rgba(0,0,0,1)),
    color-stop(1.00,  rgba(0,0,0,1)))
  `,
}));

interface IProductPunchlineProps {
  title: string;
  description: string;
  image: IFileImage;
}

const SectionMiddleware = (props: IProductPunchlineProps) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const API_URL = useAPIURL();

  return (
    <BackgroundWrapper
      isTablet={isTablet}
      isMobile={isMobile}
      noScroll
      isCentered
      direction='vertical'
    >
      <Image isMobile={isMobile} src={API_URL + props.image?.url} alt={`${props.title} image`} />
      <TitleContainer isTablet={isTablet} isMobile={isMobile}>
        <NSH2
          align='center'
          color='#000000'
          weight={900}
          className={css({
            fontSize: `${isMobile ? '31' : '42px'} !important`,
            fontStyle: 'normal',
            lineHeight: 'auto',
            letterSpacing: '0em',
            overflow: 'auto',
          })}
          margin={0}
        >
          {props.title}
        </NSH2>
        <TWParagraph3
          weight={400}
          align='center'
          color={theme.cannonColors.secondarySilverDark}
          className={css({
            fontSize: 'min(6vw,18px)!important',
            fontStyle: 'normal !important',
            lineHeight: 'auto',
            letterSpacing: '0em',
            textAlign: 'center',
            paddingTop: '16px',
            overflow: 'auto',
          })}
        >
          {props.description}
        </TWParagraph3>
        <RoundedButton
          className={css({
            fontFamily: 'Titillium Web !important',
            fontSize: '15px !important',
            fontWeight: 400,
            marginTop: isMobile ? '20px' : '50px',
            overflow: 'auto',
          })}
          color='primary'
          size='compact'
          onClick={() => {
            window.location.href = '/product-lines';
          }}
        >
          View all product lines
        </RoundedButton>
      </TitleContainer>
    </BackgroundWrapper>
  );
};

export default SectionMiddleware;
