import React from 'react';
import { useTableOrMobile } from '../../../hooks';
import { IStoriesLegacy } from '../../../pages/our-legacy';
import {
  ABH1,
  cannonStyled,
  NSH3,
  TWH4,
  useCannonStyletron,
} from '../../../theme';
import MediaComponent from './MediaComponent';

interface IPropsStories {
  data: Array<IStoriesLegacy>;
}

const MainFlex = cannonStyled('div', (props: { isMobile: boolean }) => ({
  width: '80%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '10vh 10%',
}));

const TextFlex = cannonStyled(
  'div',
  (props: { isMobile: boolean; isRight: boolean }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingBottom: props.isMobile ? '30px' : 'none',
    justifyContent: 'flex-start',
    marginRight: props.isMobile ? 'none' : props.isRight ? '5vw' : 'none',
    marginLeft: props.isRight || props.isMobile ? 'none' : '40px',
    textAlign: 'justify',
  })
);

const ContainerFlex = cannonStyled(
  'div',
  (props: { isMobile: boolean; isRight: boolean }) => ({
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: props.isMobile ? 'column' : 'row',
    marginTop: props.isMobile ? '10px' : '40px',
    alignItems: 'center',
    position: 'relative',
    marginBottom: props.isMobile ? '2vh' : '20px',
  })
);

const MediaContainer = cannonStyled(
  'div',
  (props: { isMobile: boolean; isRight: boolean }) => ({
    display: 'flex',
    width: '100%',
    height: 'auto',
    justifyContent: 'center',
  })
);

const SectionMiddleware = (props: IPropsStories) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();

  return (
    <MainFlex isMobile={isMobile}>
      {props.data.map((item, index) => {
        if (index % 2 == 0 || index == 0) {
          return (
            <React.Fragment key={item.id}>
              <ContainerFlex isRight={false} isMobile={isMobile}>
                <MediaContainer isMobile={isMobile} isRight={false}>
                  <MediaComponent
                    isBorder={true}
                    media={{
                      url: item.media?.url,
                      ext: item.media?.ext,
                    }}
                    mediaPreview={item.mediaPreview?.url}
                    videoLink={item.videoLink}
                    $label={item.title}
                  />
                </MediaContainer>
                <TextFlex isRight={false} isMobile={isMobile}>
                  <TWH4
                    color={theme.cannonColors.secondarySilver}
                    weight={700}
                    align={'left'}
                    className={css({
                      paddingBottom: isMobile ? '10px' : '18px',
                      fontSize: `min(3.2vw, 20px)`,
                    })}
                  >
                    {item.title}
                  </TWH4>
                  <NSH3
                    color={theme.cannonColors.primaryBlue}
                    weight={900}
                    align={'left'}
                    className={css({
                      paddingBottom: isMobile ? '10px' : '25px',
                    })}
                  >
                    {item.subtitle}
                  </NSH3>
                  <ABH1
                    color={theme.cannonColors.secondarySilverDark}
                    weight={400}
                    $style={{
                      fontSize: `min(4vw, 16px)`,
                    }}
                    align={'justify'}
                  >
                    {item.bodyText}
                  </ABH1>
                </TextFlex>
              </ContainerFlex>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={item.id}>
              {isMobile ? (
                <ContainerFlex isRight={true} isMobile={isMobile}>
                  <MediaContainer isMobile={isMobile} isRight={true}>
                    <MediaComponent
                      isBorder={true}
                      media={{
                        url: item.media?.url,
                        ext: item.media?.ext,
                      }}
                      mediaPreview={item.mediaPreview?.url}
                      videoLink={item.videoLink}
                      $label={item.title}
                    />
                  </MediaContainer>
                  <TextFlex isRight={true} isMobile={isMobile}>
                    <TWH4
                      color={theme.cannonColors.secondarySilver}
                      weight={700}
                      align={'left'}
                      className={css({
                        paddingBottom: isMobile ? '10px' : '18px',
                        fontSize: `min(3.2vw, 20px)`,
                      })}
                    >
                      {item.title}
                    </TWH4>
                    <NSH3
                      color={theme.cannonColors.primaryBlue}
                      weight={900}
                      align={'left'}
                      className={css({
                        paddingBottom: isMobile ? '10px' : '25px',
                      })}
                    >
                      {item.subtitle}
                    </NSH3>
                    <ABH1
                      color={theme.cannonColors.secondarySilverDark}
                      weight={400}
                      align={'justify'}
                      $style={{
                        fontSize: `min(4vw, 16px)`,
                      }}
                    >
                      {item.bodyText}
                    </ABH1>
                  </TextFlex>
                </ContainerFlex>
              ) : (
                <ContainerFlex isRight={true} isMobile={isMobile}>
                  <TextFlex isRight={true} isMobile={isMobile}>
                    <TWH4
                      color={theme.cannonColors.secondarySilver}
                      weight={700}
                      align={'left'}
                      className={css({
                        paddingBottom: isMobile ? '10px' : '18px',
                        fontSize: `min(3.2vw, 20px)`,
                      })}
                    >
                      {item.title}
                    </TWH4>
                    <NSH3
                      color={theme.cannonColors.primaryBlue}
                      weight={900}
                      align={'left'}
                      className={css({
                        paddingBottom: isMobile ? '10px' : '25px',
                      })}
                    >
                      {item.subtitle}
                    </NSH3>
                    <ABH1
                      color={theme.cannonColors.secondarySilverDark}
                      weight={400}
                      align={'justify'}
                      $style={{
                        fontSize: `min(4vw, 16px)`,
                      }}
                    >
                      {item.bodyText}
                    </ABH1>
                  </TextFlex>
                  <MediaContainer isMobile={isMobile} isRight={true}>
                    <MediaComponent
                      isBorder={true}
                      media={{
                        url: item.media?.url,
                        ext: item.media?.ext,
                      }}
                      mediaPreview={item.mediaPreview?.url}
                      videoLink={item.videoLink}
                      $label={item.title}
                    />
                  </MediaContainer>
                </ContainerFlex>
              )}
            </React.Fragment>
          );
        }
      })}
    </MainFlex>
  );
};

export default SectionMiddleware;
