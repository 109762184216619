import React from 'react';
import { Button } from '../../../atoms';
import { ResponsiveContainer } from '../../../atoms/Container';
import { cannonStyled, useCannonStyletron, NSH2, TWH4 } from '../../../theme';
import { Wrapper } from '../../layout';
import { SafeCard } from '../../../atoms/Cards';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import { ICannonSafe } from '../../../pages';
import Carousel, {
  arrowsPlugin,
  slidesToShowPlugin,
  slidesToScrollPlugin,
  autoplayPlugin,
} from '@brainhubeu/react-carousel';

import ArrowLeft from '../../../assets/arrow-left.svg';
import ArrowRight from '../../../assets/arrow-right.svg';
import '@brainhubeu/react-carousel/lib/style.css';
import { AspectRatioBox, AspectRatioBoxBody } from 'baseui/aspect-ratio-box';
import SeriesCard from '../../../atoms/Cards/SeriesCard';
import { useMediaQuery } from 'react-responsive';

interface IProductShowCase {
  title: string;
  enabled: boolean;
  description: string;
  action: string;
  list: Array<ICannonSafe>;
  showSubtitle?: boolean;
  showCaption?: boolean;
  applyMobileDesign?: boolean;
}

const MainWrapper = cannonStyled(
  Wrapper,
  ({ mobileDesign = false }: { mobileDesign?: boolean }) => ({
    margin: mobileDesign ? '45px 0px 0px 0px' : '90px 0px',
    marginBottom: mobileDesign ? '' : '180px',
  })
);

const SectionMiddleware = (data: IProductShowCase) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const API_URL = useAPIURL();
  const isViewport = useMediaQuery({
    query: '(min-width: 768px) and (max-width: 800px)',
  });

  const maxShow = isMobile ? 1 : isTablet ? 3 : 4;
  const maxSlide = isMobile ? 1 : isTablet ? 3 : 4;

  if (!data.enabled) return null;

  // RENDER

  const { applyMobileDesign = false } = data;

  return (
    <MainWrapper
      noScroll
      compact
      isPadded
      autoHeight
      isCentered
      direction='vertical'
      mobileDesign={isMobile && applyMobileDesign}
    >
      <NSH2
        align='center'
        color={theme.cannonColors.primaryBlue}
        weight={900}
        aria-label={data.title}
      >
        {(data.title || '').toUpperCase()}
      </NSH2>
      <TWH4
        align='center'
        color={theme.cannonColors.primaryGray}
        weight={400}
        aria-label={
          data.description ? data.description : 'description text empty'
        }
      >
        {data.description}
      </TWH4>
      <ResponsiveContainer
        $style={{
          marginTop: isMobile && applyMobileDesign ? '40px' : '80px',
          marginBottom: '0px',
        }}
      >
        <Carousel
          plugins={[
            {
              resolve: arrowsPlugin,
              options: {
                arrowLeft: (
                  <Button.IconButton
                    aria-label='Previous'
                    $style={{
                      ...{
                        backgroundColor: theme.cannonColors.primaryBlue,
                        borderRadius: '4px',
                      },
                      ...(isMobile && applyMobileDesign
                        ? {
                            position: 'absolute',
                            top: isViewport ? '320px' : '250px',
                            left: '12px',
                            zIndex: 1000,
                          }
                        : {}),
                    }}
                  >
                    <ArrowLeft style={{ stroke: 'white' }} />
                  </Button.IconButton>
                ),
                arrowLeftDisabled: (
                  <Button.IconButton
                    aria-label='Previous'
                    $style={{
                      ...{
                        backgroundColor: theme.cannonColors.primaryBlue,
                        borderRadius: '4px',
                      },
                      ...(isMobile && applyMobileDesign
                        ? {
                            position: 'absolute',
                            top: isViewport ? '320px' : '250px',
                            left: '12px',
                            zIndex: 1000,
                          }
                        : {}),
                    }}
                  >
                    <ArrowLeft style={{ stroke: 'white' }} />
                  </Button.IconButton>
                ),
                arrowRight: (
                  <Button.IconButton
                    aria-label='Next'
                    $style={{
                      ...{
                        backgroundColor: theme.cannonColors.primaryBlue,
                        borderRadius: '4px',
                      },
                      ...(isMobile && applyMobileDesign
                        ? {
                            position: 'absolute',
                            top: isViewport ? '320px' : '250px',
                            right: '12px',
                            zIndex: 1000,
                          }
                        : {}),
                    }}
                  >
                    <ArrowRight style={{ stroke: 'white' }} />
                  </Button.IconButton>
                ),
                arrowRightDisabled: (
                  <Button.IconButton
                    aria-label='Next'
                    $style={{
                      ...{
                        backgroundColor: theme.cannonColors.primaryBlue,
                        borderRadius: '4px',
                      },
                      ...(isMobile && applyMobileDesign
                        ? {
                            position: 'absolute',
                            top: isViewport ? '320px' : '250px',
                            right: '12px',
                            zIndex: 1000,
                          }
                        : {}),
                    }}
                  >
                    <ArrowRight style={{ stroke: 'white' }} />
                  </Button.IconButton>
                ),
                addArrowClickHandler: true,
              },
            },
            'infinite',
            {
              resolve: autoplayPlugin,
              options: {
                interval: applyMobileDesign && isMobile ? 5000 : 30000,
              },
            },
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: applyMobileDesign && isMobile ? 1.2 : maxShow,
              },
            },
            {
              resolve: slidesToScrollPlugin,
              options: {
                numberOfSlides: maxSlide,
              },
            },
          ]}
          animationSpeed={900}
        >
          {data.list.map((cannonSafe) => (
            <SafeCard
              key={cannonSafe.displayName}
              displayImage={API_URL + cannonSafe.displayImage?.url}
              title={cannonSafe.displayName}
              slug={cannonSafe.slug}
              subTitle={`${cannonSafe.series?.title} Series`}
              description={''}
              reviews={cannonSafe.reviews}
              showSubtitle={data.showSubtitle}
              popular={cannonSafe.popular}
              showCaption={data.showCaption}
              // caption={cannonSafe.safeCategory.title}
              applyMobileDesign={applyMobileDesign}
              action={() =>
                (window.location.href = `/product/${cannonSafe.slug}`)
              }
            />
          ))}
        </Carousel>
      </ResponsiveContainer>
    </MainWrapper>
  );
};

export default SectionMiddleware;
