import React, { useState } from 'react';
import { cannonStyled, NSH2 } from '../../../theme';
import { useCannonStyletron } from '../../../theme/CannonSafesTheme';
import { usePostService, useTableOrMobile } from '../../../hooks';
import { H2, Paragraph3, Paragraph4 } from 'baseui/typography';
import { Wrapper } from '../../layout';
import { ResponsiveContainer } from '../../../atoms/Container';
import { RoundedButton, UnderlinedButton } from '../../../atoms/Button';
import { ReviewCard, TitleReviewCard } from '../../../atoms/Cards';
import RightArrow from '../../../assets/arrow-right.svg';
import { Review } from '../../../pages';
import { IThemeProp } from '../../../atoms/Cards/CapabilityCard';

interface IGridContainer extends IThemeProp {
  isMobile?: boolean;
}

const GridContaier = cannonStyled('div', (props: IGridContainer) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: props.isMobile ? '1fr' : '1fr 1fr',
  margin: '20px',
  padding: '10px',
  paddingTop: '40px',
}));

const ReviewCardContainer = cannonStyled('div', {
  padding: '40px 0px 0px 0px',
});

const SectionMiddleware = ({
  reviews = [] as Review[],
}: {
  reviews: Array<Review>;
}) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();

  //STATE

  const [viewAll, setViewAll] = useState(false);

  // EFFECTS

  const reviewSum = reviews.reduce(
    (prevValue, current) => prevValue + current.value,
    0
  );
  const reviewCount = reviews.length;
  const reviewAvg = (reviewSum / reviewCount).toFixed(1);

  return (
    <>
      {reviews.length > 0 && (
        <Wrapper
          $style={{ paddingBottom: '5vh', marginTop: '100px' }}
          noScroll
          isPadded
          autoHeight
          isCentered
          direction='vertical'
        >
          <>
            <ResponsiveContainer flexDirection='column'>
              <NSH2
                className={css({
                  fontFamily: 'Noto Serif !important',
                  fontSize: 'min(6vw, 42px) !important',
                })}
                weight={900}
                align='center'
                color={theme.cannonColors.primaryBlue}
              >
                OUR CUSTOMER REVIEWS
              </NSH2>

              <ReviewCardContainer>
                <TitleReviewCard
                  title={`${reviewAvg} /  5`}
                  description={`Based on ${reviewCount} consumer reviews`}
                  rating={parseInt(reviewAvg)}
                />
              </ReviewCardContainer>
              <GridContaier isMobile={isMobile}>
                {reviews
                  .sort((a, b) => a.value - b.value)
                  .slice(0, viewAll ? reviews.length : 4)
                  .map((review) => (
                    <ReviewCard
                      key={review.id}
                      title={review.title}
                      description={review.description}
                      rating={review.value}
                    />
                  ))}
              </GridContaier>
            </ResponsiveContainer>
          </>

          {reviews.length > 4 && !viewAll && (
            <RoundedButton
              color='primary'
              size='compact'
              onClick={() => {
                setViewAll(true);
              }}
              endEnhancer={<RightArrow style={{ stroke: 'white' }} />}
              className={css({
                fontFamily: 'Titillium Web !important',
                fontSize: `16px !important`,
                fontWeight: 400,
                marginTop: '26px',
              })}
            >
              View all reviews
            </RoundedButton>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default SectionMiddleware;
