import { Paragraph3, Paragraph4 } from 'baseui/typography';
import React, { useRef, useState } from 'react';
import { useIsVisible, useTableOrMobile } from '../../hooks';
import {
  CannonSafeTheme,
  cannonStyled,
  NSH3,
  TWH4,
  TWH5,
  useCannonStyletron,
} from '../../theme';
import Animation, { AppearUp } from '../../atoms/Animations/index';

interface ICardContainer {
  isMobile: boolean;
  isLeft?: boolean;
  title: string;
  label: string;
  description: string;
  $theme?: CannonSafeTheme;
}

const CardContainer = cannonStyled('div', (props: ISeriesFeaturesProps) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: props.isMobile ? 'center' : 'none',
  width: props.isMobile ? '30' : '39vw',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: '3px',
  boxShadow: '0px 22px 46px rgba(0, 0, 0, 0.04)',
  backdropFilter: 'blur(16px)',
  marginTop: props.isMobile ? '25em' : 'initial',
  marginLeft: props.isMobile ? `5%` : props.isLeft ? 'auto' : 0,
  marginRight: props.isMobile ? '5%' : props.isLeft ? 0 : 'auto',
  padding: props.isMobile ? '28px' : '40px',
  lineHeight: 'auto !important',
  zIndex: 2,
}));

export default (props: ICardContainer) => {
  const [css, theme] = useCannonStyletron();
  const [hovered, setHovered] = useState(true);
  const contentRef = useRef();
  const isVisible = useIsVisible(contentRef);
  const [isMobile] = useTableOrMobile();

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      ref={contentRef}
    >
      <Animation>
        <div style={isVisible ? AppearUp : {}}>
          <CardContainer isMobile={isMobile} isLeft={props.isLeft}>
            <TWH4
              isMobile={isMobile}
              weight={700}
              color={theme.cannonColors.primaryBlue}
              align={'start'}
              className={css({ fontSize: `min(3.6vw, 24px)` })}
            >
              {props.label}
            </TWH4>
            <NSH3
              isMobile={isMobile}
              weight={900}
              color={'#111111 !important'}
              align={'start'}
              className={css({
                fontSize: `min(4.5vw, 36px)`,
                paddingBottom: '16px',
              })}
            >
              {props.title}
            </NSH3>
            <TWH5
              weight={400}
              align={isMobile ? 'justify' : 'start'}
              color={'#4E4E4E'}
              className={css({
                fontSize: isMobile ? '18px' : `min(3vw, 20px)`,
              })}
            >
              {props.description}
            </TWH5>
          </CardContainer>
        </div>
      </Animation>
    </div>
  );
};
