import { Accordion, Panel } from 'baseui/accordion';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import {
  CenteredContainer,
  ResponsiveContainer,
} from '../../../../atoms/Container';
import { TWParagraph2, TWA } from '../../../../atoms/DocumentParser';
import { MediaRounded } from '../../../../atoms/Image';
import { useTableOrMobile } from '../../../../hooks';
import { tokenize } from '../../../../hooks/useFilterSearch';
import { cannonStyled } from '../../../../theme';
import {
  CannonSafeThemeT,
  useCannonStyletron,
} from '../../../../theme/CannonSafesTheme';

interface IAccordionProps {
  sentence?: string;
  question: string;
  answerBody: string;
  mediaURL: string;
}

//Styled Components
const CustomPanel = cannonStyled(
  'div',
  (props: { isExpanded: boolean; $theme?: CannonSafeThemeT }) => ({
    textAlign: 'left',
    fontFamily: 'Titillium Web',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: `min(4vw, 20px)`,
    lineHeight: 'auto',
    color: props.isExpanded
      ? props.$theme.cannonColors.secondaryOrange
      : props.$theme.cannonColors.primaryBlue,
  })
);

const TWLI = cannonStyled(
  'li',
  (props: { color: string; isMobile?: boolean; margin?: number }) => ({
    fontFamily: 'Titillium Web',
    display: 'inline-block',
    margin: props.margin,
    fontSize: props.isMobile ? '17px' : `20px`,
    fontWeight: 400,
    color: props.color,
    textAlign: 'justify',
  })
);

const FAQAccordion = (props: IAccordionProps) => {
  //Hooks
  const [isMobile, isTablet] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();
  const [isExpanded, setIsExpanded] = useState(false);

  var mobileOrNot;

  //Function Panel for mobile
  if (props.sentence) {
    mobileOrNot = (
      <div
        dangerouslySetInnerHTML={{
          __html: changeBold(props.sentence, props.question),
        }}
      />
    );
  } else {
    mobileOrNot = <>{props.question}</>;
  }

  //Function to change matching words
  function changeBold(sentence, question) {
    const tokenizedSearch = tokenize(sentence);

    const boldQuestion = tokenizedSearch.reduce(
      (result: string, currentSearchToken: string) => {
        const rgxp = new RegExp(currentSearchToken, 'ig');
        return result.replace(rgxp, (match) => `<b>${match}</b>`);
      },
      question
    );

    return boldQuestion;
  }
  return (
    <div
      className={css({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      <Accordion
        onChange={() => {
          setIsExpanded(!isExpanded);
        }}
        accordion
        overrides={{
          Header: {
            style: () => ({
              maxWidth: `calc(100% - 40px)`,
              width: '100%',
              border: 'none',
              backgroundColor: `white`,
              paddingTop: '21px',
              transition: '1s',
              lineHeight: 'auto',
              outlineOffset: 'none',
              ':hover': {
                boxShadow: isExpanded
                  ? 'none'
                  : '0px 8px 17px 0px rgba(3, 64, 120, 0.08)',
                backdropFilter: isExpanded ? 'none' : 'blur(17px)',
              },
            }),
          },
          PanelContainer: {
            style: () => ({
              width: 'auto',
              border: `1.5px #F4F6FB solid`,
              borderRadius: '6px',
              marginBottom: '17px',
              borderBottom: `1px #F4F6FB solid`,
              borderBottomColor: 'none',
            }),
          },
          Content: {
            style: () => ({
              paddingTop: isMobile ? '0px' : 'inherit',
              paddingBottom: isMobile ? '20px' : 'inherit',
              lineHeight: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }),
          },
          ToggleIcon: {
            style: {
              width: '22px',
              height: '22px',
              color: isExpanded
                ? theme.cannonColors.secondaryOrange
                : '#111111',
            },
          },
        }}
      >
        <Panel
          title={
            <CustomPanel isExpanded={isExpanded}>{mobileOrNot}</CustomPanel>
          }
        >
          <ReactMarkdown
            components={{
              p: ({ node, ...props }) => (
                <TWParagraph2
                  isMobile={isMobile}
                  color={theme.cannonColors.secondarySilver}
                  {...props}
                  aria-label='FAQ Text'
                />
              ),
              li: ({ node, ...props }) => (
                <TWLI
                  isMobile={isMobile}
                  color={theme.cannonColors.secondarySilver}
                  {...props}
                  aria-label='FAQ Answer'
                />
              ),
              a: ({ node, ...props }) => (
                <TWA
                  margin={0}
                  isMobile={isMobile}
                  color={theme.cannonColors.primaryBlue}
                  {...props}
                  aria-label='FAQ Link'
                />
              ),
              img: ({ node, ...props }) => (
                <img
                  style={{
                    paddingLeft: '10px',
                    maxWidth: `min(calc(100% - 48px - 10px), 300px)`,
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                  }}
                  alt='FAQ Image'
                  {...props}
                />
              ),
            }}
            rehypePlugins={[rehypeRaw]}
          >
            {props.answerBody}
          </ReactMarkdown>
          {props.mediaURL && (
            <CenteredContainer>
              <ResponsiveContainer>
                <MediaRounded src={props.mediaURL || ''} />
              </ResponsiveContainer>
            </CenteredContainer>
          )}
        </Panel>
      </Accordion>
    </div>
  );
};

export default FAQAccordion;
