import React, { useState, useRef, useEffect } from 'react';

import { IPLListing } from '../../../@types';
import { Button } from '../../../atoms';
import { ResponsiveContainer } from '../../../atoms/Container';
import { IThemeProp } from '../../../atoms/Cards/CapabilityCard';
import {
  cannonStyled,
  NSH3,
  TWH4,
  TWH5,
  useCannonStyletron,
  TWButtonMini,
} from '../../../theme';

import Carousel, {
  arrowsPlugin,
  slidesToShowPlugin,
  slidesToScrollPlugin,
} from '@brainhubeu/react-carousel';
import ArrowLeft from '../../../assets/arrow-left.svg';
import ArrowRight from '../../../assets/arrow-right.svg';
import '@brainhubeu/react-carousel/lib/style.css';

import { Wrapper } from '../../layout';

import { useAPIURL, useTableOrMobile } from '../../../hooks';
import { ProductLinesCarousel } from '../../../atoms/Cards';
import { useMediaQuery } from 'react-responsive';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { RoundedButton } from '../../../atoms/Button';

const Grid = cannonStyled(
  'div',
  (
    props: IThemeProp & {
      isMobile: boolean;
      isMinHeight: boolean;
    }
  ) => ({
    display: 'grid',
    gridTemplateColumns: props.isMobile ? '1fr' : '46% auto',
    gridTemplateRows: props.isMobile
      ? props.isMinHeight
        ? `auto calc(100vh - 73px)`
        : 'auto 400px'
      : '650px',
    gridTemplateAreas: props.isMobile
      ? `
    'info'
    'list'
  `
      : `
    'info list'
  `,
    margin: 0,
    padding: 0,
  })
);

const InfoContainer = cannonStyled(
  'div',
  (props: IThemeProp & { isMobile?: boolean }) => ({
    backgroundColor: props.$theme?.cannonColors.primaryWhite,
    gridArea: 'info',
    display: 'flex',
    alignItems: 'center',
    ...(props.isMobile
      ? {
          padding: '10%',
        }
      : {
          paddingLeft: '115px',
          paddingRight: '10%',
        }),
  })
);

const InfoText = cannonStyled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
});

const ListContainer = cannonStyled(
  'div',
  (
    props: IThemeProp & {
      bgColor: string;
    }
  ) => ({
    gridArea: 'list',
    backgroundColor: props.$theme.cannonColors[props.bgColor],
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    overflowX: 'hidden',
  })
);

const Line = cannonStyled(
  'span',
  (
    props: IThemeProp & {
      opacity: string;
      isMinHeight: boolean;
      isMobile: boolean;
    }
  ) => ({
    width: '100%',
    height: '5px',
    backgroundColor: props.$theme.cannonColors.primaryWhiteLight,
    opacity: props.opacity,
    marginBottom: props.isMobile
      ? props.isMinHeight
        ? '2.5%'
        : '3.3%'
      : '4.5%',
  })
);

const CarouselContainer = cannonStyled(
  'div',
  (props: { isMobile: boolean; isTablet: boolean }) => ({
    position: 'absolute',
    left: 0,
    right: '0px',
    paddingLeft: props.isMobile ? '5%' : props.isTablet ? '12%' : '25%',
    overflow: 'hidden',
  })
);

const CounterDiv = cannonStyled('div', {
  textAlign: 'right',
  height: '30px',
  maxWidth: '412px',
  margin: '0 auto',
});

const CardWrapper = cannonStyled('div', {
  height: '',
  margin: '0 auto',
  width: '85%',
  cursor: 'pointer',
});

export default (
  props: IPLListing & { isMobile?: boolean; isTablet?: boolean }
) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const API_URL = useAPIURL();

  const minHeight = useMediaQuery({ query: '(max-height: 465px)' });

  const bgColors = [
    'primaryBlueDark',
    'primaryGreenDark',
    'secondaryBlueDark',
    'secondaryCarmineDark',
    'secondarySilverDark',
    'secondaryOrangeDark',
    'secondaryBrownDark',
  ];

  const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
  };

  const [count, setCount] = useState(1);
  const [bgColorOnClic, setbgColorOnClic] = useState('primaryGreenDark');

  const countLimit = props.listingSeries.length;

  const countCarouselUp = () => {
    setCount(count + 1 > countLimit ? 1 : count + 1);
    setbgColorOnClic(bgColors[getRandomInt(0, 7)]);
  };

  const countCarouselDown = () => {
    setCount(count - 1 <= 0 ? countLimit : count - 1);
    setbgColorOnClic(bgColors[getRandomInt(0, 7)]);
  };

  // Calculates height of carousel container
  const [carouselHeight, setHeight] = useState(0);
  const heightCarouselRef = useRef(null);

  useEffect(() => {
    setHeight(heightCarouselRef.current.clientHeight);
    function handleResize() {
      setHeight(heightCarouselRef.current.clientHeight);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  let carouselHeightPx = JSON.stringify(carouselHeight) + 'px';

  return (
    <Grid
      className='pl_showcase_grid'
      isMobile={isMobile}
      isMinHeight={minHeight}
    >
      <InfoContainer isMobile={isMobile}>
        <InfoText>
          <TWH4
            color={theme.cannonColors.secondarySilver}
            align='left'
            weight={700}
          >
            {props.subtitle}
          </TWH4>
          <NSH3
            color={theme.cannonColors.primaryBlue}
            align='left'
            weight={900}
            margin={15}
          >
            {props.title}
          </NSH3>
          <TWH5
            color={theme.cannonColors.secondarySilverDark}
            align='left'
            weight={400}
            $style={{ marginBottom: '60px' }}
          >
            {props.description}
          </TWH5>
          <Button.RoundedButton
            color='primary'
            size='compact'
            margin={0}
            onClick={() => (window.location.href = `${props.href}`)}
            endEnhancer={<img src={API_URL + props.iconButton} alt={props.action}/>}
          >
            {props.action}
          </Button.RoundedButton>
        </InfoText>
      </InfoContainer>

      <ListContainer bgColor={bgColorOnClic} ref={heightCarouselRef}>
        <Line opacity='0.45' isMinHeight={minHeight} isMobile={isMobile} />
        <Line opacity='0.4' isMinHeight={minHeight} isMobile={isMobile} />
        <Line opacity='0.35' isMinHeight={minHeight} isMobile={isMobile} />
        <Line opacity='0.3' isMinHeight={minHeight} isMobile={isMobile} />
        <Line opacity='0.25' isMinHeight={minHeight} isMobile={isMobile} />
        <Line opacity='0.2' isMinHeight={minHeight} isMobile={isMobile} />
        <Line opacity='0.15' isMinHeight={minHeight} isMobile={isMobile} />
        <Line opacity='0.1' isMinHeight={minHeight} isMobile={isMobile} />
        <Line opacity='0.05' isMinHeight={minHeight} isMobile={isMobile} />

        <CarouselContainer isMobile={isMobile} isTablet={isTablet}>
          <ResponsiveContainer
            $style={{
              width: '140%',
            }}
          >
            <Carousel
              show={2.5}
              plugins={[
                {
                  resolve: arrowsPlugin,
                  options: {
                    arrowLeft: (
                      <Button.IconButton
                        aria-label="Previous"
                        onClick={countCarouselDown}
                        $style={{
                          backgroundColor: theme.cannonColors[bgColorOnClic],
                          width: isMobile ? '21px' : '26px',
                          height: isMobile ? '21px' : '26px',
                          borderRadius: '4px',
                          border: '2px solid white',
                          marginRight: 0,
                          position: 'absolute',
                          bottom: '4px',
                          zIndex: 2,
                          left: props.isMobile
                            ? '10%'
                            : props.isTablet
                            ? '17%'
                            : '30%',
                          padding: '2px 0 0 0',
                        }}
                      >
                        <ArrowLeft style={{ stroke: 'white', width: '55%' }} />
                      </Button.IconButton>
                    ),
                    arrowRight: (
                      <Button.IconButton
                        aria-label="Next"
                        onClick={countCarouselUp}
                        $style={{
                          backgroundColor: theme.cannonColors[bgColorOnClic],
                          width: isMobile ? '21px' : '26px',
                          height: isMobile ? '21px' : '26px',
                          borderRadius: '4px',
                          border: '2px solid white',
                          marginRight: 0,
                          position: 'absolute',
                          bottom: '4px',
                          zIndex: 2,
                          left: props.isMobile
                            ? '19%'
                            : props.isTablet
                            ? '25%'
                            : '36%',
                          padding: '2px 0 0 0',
                        }}
                      >
                        <ArrowRight style={{ stroke: 'white', width: '55%' }} />
                      </Button.IconButton>
                    ),
                    addArrowClickHandler: true,
                  },
                },
                'infinite',
                'fastSwipe',
                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: 2,
                  },
                },
                {
                  resolve: slidesToScrollPlugin,
                  options: {
                    numberOfSlides: 1,
                  },
                },
              ]}
            >
              {props.listingSeries.map((carouselItem) => {
                return (
                  <CardWrapper
                    onClick={() => {
                      window.location.href = `/product-lines/${carouselItem.safesSeries.node.slug}`;
                    }}
                  >
                    <Button.RoundedButton
                      size='compact'
                      endEnhancer={<ArrowRight style={{ stroke: 'white' }} />}
                      style={{
                        backgroundColor: theme.cannonColors[bgColorOnClic],
                        zIndex: 9,
                        position: 'absolute',
                        bottom: isMobile ? '20%' : '15%',
                        right: 0,
                        left: 0,
                        width: '70%',
                        margin: '0 auto',
                        borderRadius: '4px',
                        color: theme.cannonColors.primaryWhite,
                      }}
                      onClick={() => {
                        window.location.href = `/product-lines/${carouselItem.safesSeries.node.slug}`;
                      }}
                    >
                      View Full Line
                    </Button.RoundedButton>

                    <ProductLinesCarousel
                      count={count}
                      isMinHeight={minHeight}
                      displayImage={API_URL + carouselItem.seriesImage.url}
                      title={carouselItem.safesSeries.node.title}
                      slug={carouselItem.safesSeries.node.slug}
                      subTitle={carouselItem.safesSeries.node.subtitle}
                      description={carouselItem.safesSeries.node.description}
                      colorText={theme.cannonColors[bgColorOnClic]}
                      action={() =>
                        (window.location.href = `/product-lines/${carouselItem.safesSeries.edges.node.slug}`)
                      }
                    />
                    <CounterDiv>
                      <TWButtonMini
                        color={theme.cannonColors.primaryWhiteLight}
                        align='right'
                        weight={100}
                        margin={8}
                      >
                        {count}/{countLimit} Product lines
                      </TWButtonMini>
                    </CounterDiv>
                  </CardWrapper>
                );
              })}
            </Carousel>
          </ResponsiveContainer>
        </CarouselContainer>
      </ListContainer>
    </Grid>
  );
};
