import React from 'react';
import { BlackOverlay, CenteredContainer } from '../../../atoms/Container';
import { ImageWithCenteredC } from '../../../atoms/Image';
import { useAPIURL, useNewLineText, useTableOrMobile } from '../../../hooks';
import { Wrapper } from '../../layout';
import { Button } from '../../../atoms';

import ArrowRight from '../../../assets/arrow-right.svg';

import { NSH1, TWH4, useCannonStyletron, cannonStyled } from '../../../theme';
import { IPLHistory } from '../../../@types';

interface ICallToAction {
  isMobile: boolean;
}

const WrapperDiv = cannonStyled('div', (props: ICallToAction) => ({
  height: props.isMobile ? '700px' : '550px',
  position: 'relative',
  margin: 0,
  padding: 0,
}));

const SectionMiddleware = (data: IPLHistory) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const API_URL = useAPIURL();

  if (!data.enabled) return null;

  const title = useNewLineText(data.title);
  const description = useNewLineText(
    data.description
  );

  return (
    <WrapperDiv isMobile={isMobile}>
      <ImageWithCenteredC
        height='100%'
        width='100%'
        src={
          API_URL + data.backgroundImage.url
        }
        alt='nono'
      >
        <CenteredContainer>
          <BlackOverlay>
            <NSH1
              color={theme.cannonColors.primaryWhiteLight}
              margin={0}
              align='center'
              weight={900}
            >
              {title}
            </NSH1>
            <TWH4
              style={{ maxWidth: '60%', margin: '22px auto' }}
              color={theme.cannonColors.primaryWhiteLight}
              align='center'
              weight={400}
            >
              {description}
            </TWH4>
            <Button.RoundedButton
              size='compact'
              margin={20}
              color='tertiary'
              onClick={() => {window.location.href = data.href}}
              endEnhancer={
                <ArrowRight
                  style={{ stroke: theme.cannonColors.primaryBlue }}
                />
              }
            >
              {data.action}
            </Button.RoundedButton>
          </BlackOverlay>
        </CenteredContainer>
      </ImageWithCenteredC>
    </WrapperDiv>
  );
};

export default SectionMiddleware;
