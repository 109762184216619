import React from 'react';
import { DetailFeatureCard } from '../../../atoms/Cards';
import { ResponsiveContainer } from '../../../atoms/Container';
import { useAPIURL } from '../../../hooks';
import { ISpecification } from '../../../pages';
import {
  CannonSafeThemeT,
  cannonStyled,
  useCannonStyletron,
} from '../../../theme/CannonSafesTheme';
import { Wrapper } from '../../layout';

interface IGridContainer {
  isMobile: boolean;
  children: JSX.Element[];
  $theme?: CannonSafeThemeT;
}

const GridContaier = cannonStyled('div', (props: IGridContainer) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto',
  paddingBottom: '60px',
}));

const SectionMiddleware = (props: {
  specifications: Array<ISpecification>;
}) => {
  const [css, theme] = useCannonStyletron();
  const API_URL = useAPIURL();

  return (
    <Wrapper noScroll autoHeight isCentered direction='vertical'>
      <ResponsiveContainer>
        <GridContaier isMobile={false}>
          {props.specifications.map((data, index) => (
            <DetailFeatureCard
              title={data.title}
              items={data.list}
              reversed={index % 2 !== 0}
              imageSrc={API_URL + data.image?.url}
            />
          ))}
        </GridContaier>
      </ResponsiveContainer>
    </Wrapper>
  );
};

export default SectionMiddleware;
