import React from 'react';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';
import { cannonStyled, TWH5, useCannonStyletron, NSH4 } from '../../theme';
import { IAllBlogs } from '../../pages/blog';
import { useAPIURL, useTableOrMobile } from '../../hooks';

interface BlogComponentProps {
  isMobile?: boolean;
  $theme?: CannonSafeThemeT;
}

const ArticlesContainer = cannonStyled('a', (props: BlogComponentProps) => ({
  width: '100%',
  marginBottom: '30px',
  boxShadow: `0px 8px 16px ${props.$theme.cannonColors.primaryWhiteDark}`,
  overflow: 'hidden',
  overflowWrap: 'break-word',
  ':link': {
    textDecoration: 'none',
  },
  ':hover': {
    opacity: 0.8,
  },
}));

const ImageContainer = cannonStyled('div', (props: BlogComponentProps) => ({
  width: '100%',
  height: props.isMobile ? '180px' : '190px',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '6px 6px 0 0',
}));

const ImageFit = cannonStyled('img', (props: BlogComponentProps) => ({
  height: 'auto',
  width: '100%',
  objectFit: 'cover',
}));

const InfoContainer = cannonStyled('div', {
  height: '140px',
  padding: '20px',
  width: '90%',
});

const ReadMore = cannonStyled('span', (props: BlogComponentProps) => ({
  color: props.$theme.cannonColors.secondaryOrange,
  fontWeight: 600,
}));

const BlogCard = (props: IAllBlogs & { isLegacy?: boolean }) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const API_URL = useAPIURL();

  const category = props.blogCategory.Name.trim()
    .toLowerCase()
    .replace(/\./g, '')
    .replace(/ /g, '-');

  const countTitle = props.title.length;
  // Set the max number of characters for the description based on the title length
  let maxChar = countTitle < 28 ? 100 : countTitle < 55 ? 88 : 0;

  const textExtract =
    maxChar > 0 ? props.previewText.slice(0, maxChar) + '...' : '';

  return (
    <ArticlesContainer
      isMobile={isMobile}
      href={`/blog/${category}/${props.slug}`}
    >
      <ImageContainer aria-label={props.title}>
        <ImageFit src={API_URL + props.mainMedia.url} alt={props.title} />
      </ImageContainer>

      <InfoContainer>
        <TWH5
          color={theme.cannonColors.secondaryOrangeLight}
          weight={400}
          align='left'
          margin={5}
          $style={{
            fontSize: isMobile ? `min(4vw, 14px)` : `min(4vw, 16px)`,
          }}
          aria-label={
            props.blogCategory.Name
              ? props.blogCategory.Name
              : 'Blog Category Empty'
          }
        >
          {props.blogCategory.Name}
        </TWH5>
        <NSH4
          color={theme.cannonColors.primaryBlue}
          weight={900}
          align='left'
          $style={{
            fontSize: isMobile ? `min(4.2vw, 18px)` : `min(4.2vw, 20px)`,
            margin: '5px 0 3px 0',
            hover: {
              opacity: 0.5,
            },
          }}
        >
          {props.title}
        </NSH4>

        <TWH5
          color={theme.cannonColors.secondaryBlackLight}
          weight={400}
          align='left'
          $style={{
            fontSize: isMobile ? `min(4vw, 14px)` : `min(4vw, 16px)`,
          }}
        >
          {textExtract}
          {maxChar > 0 && <ReadMore> Read More</ReadMore>}
        </TWH5>
      </InfoContainer>
    </ArticlesContainer>
  );
};

export default BlogCard;
