import React from 'react';
import { useState } from 'react';
import { useCannonStyletron, cannonStyled } from '../../theme';
import { useTableOrMobile } from '../../hooks';
import ParallaxCurve from '../../assets/border-effect.svg';
import Flames from '../../images/banner-sparks.png';

export default ({ bg, safes, children }) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const [height, setHeight] = useState(window.innerHeight);

  const containerClass = css({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: '100%',
    height: `calc(100vh - 114px)`,
    backgroundSize: 'cover',
    backgroundImage: `url(${bg})`,
    backgroundColor: '#000',
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed',
  });

  const flamesImg = css({
    bottom: 0,
    width: '100%',
    height: '100%',
    margin: 0,
    top: 'auto',
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    backgroundImage: `url(${Flames})`,
    backgroundPosition:
      height < 500 ? 'center 10%' : height > 800 ? 'center 90%' : 'center 70%',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
  });

  const safesImg = css({
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '100vh',
    zIndex: 99,
    backgroundSize: 'auto 48vh',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${safes})`,
    backgroundPosition: `center calc(100vh - ${isMobile ? '49vh' : '55vh'})`,
  });

  const childContainerClass = css({
    position: 'absolute',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 'auto',
    display: 'flex',
    justifyContent: 'center',
  });

  const SVGCurveContainer = css({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: 0,
    margin: 0,
  });

  const SVGCurve = css({
    display: 'block',
    position: 'absolute',
    bottom: '-10px',
    margin: 0,
    padding: 0,
    width: '100%',
  });

  return (
    <div
      ref={(el) => {
        // el can be null - see https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
        if (!el) return;

        el.getBoundingClientRect().height; // prints 200px
      }}
      className={containerClass}
    >
      <div className={flamesImg}>
        <div className={SVGCurveContainer}>
          <ParallaxCurve clasName={SVGCurve} />
        </div>

        <div className={safesImg}></div>
      </div>
      <div className={childContainerClass}>{children}</div>
    </div>
  );
};
