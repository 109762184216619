import React, { useEffect } from 'react';
import { useTableOrMobile } from '../../../hooks';
import { cannonStyled, TWH3, useCannonStyletron } from '../../../theme';

const MainContainer = cannonStyled(
  'div',
  ({ isMobile }: { isMobile: boolean }) => ({
    // height: isMobile ? '600px' : 'auto',
    marginTop: isMobile ? 0 : '-10px',
    overflow: 'auto',
  })
);

const ProductLocator = cannonStyled('div', {
  fontFamily: 'Titillium Web',
});

interface Props {
  upc: string;
  retailerCategory: string;
}

const LocallyProductLocator = ({ upc, retailerCategory }: Props) => {
  //HOOKS

  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();

  // EFFECTS

  useEffect(() => {
    const lcllyScript = document.createElement('script');
    lcllyScript.id = 'lcly-script-0';
    lcllyScript.async = true;

    const lcllyScriptConfig = document.createElement('script');
    lcllyScriptConfig.id = 'lcly-config-0';
    
    var lcly_config_0 = {
      company_name: 'Cannon Safe',
      button_id: 'HTML',
      company_id: '159746',
      css: '6',
      color_0: '#001F54',
      color_1: '#001F54',
      always_hide_button: '1',
      upc,
      n_related_styles: '3',
      show_location_switcher: '1',
      show_location_prompt: '1',
      // Future Release
      // show_only_upc_stocking_dealers: '1',
      category: retailerCategory,
      lang: 'en-us',
      show_dealers: '1',
      n_dealers: '3',
      link_opens_modal: '1',
    };
    var lcly_query_0 = Object.keys(lcly_config_0)
      .reduce(function (a, k) {
        a.push(
          encodeURIComponent(k) + '=' + encodeURIComponent(lcly_config_0[k])
        );
        return a;
      }, [])
      .join('&');
    var lcly_endpoint_0 =
      'https://cannon-safe.locally.com/stores/map.js?' + lcly_query_0;
    lcllyScript.src = lcly_endpoint_0;

    document.body.appendChild(lcllyScript);
    document.body.appendChild(lcllyScriptConfig);
  }, [upc]);

  return (
    <>
      <TWH3
        color={theme.cannonColors.primarySolidBlack}
        weight={700}
        align='left'
        $style={{ fontSize: '16px', margin: '16px 0', overflow: 'auto' }}
      >
        Available near you:{' '}
      </TWH3>
      <MainContainer isMobile={isMobile}>
        <ProductLocator
          id='lcly-button-0'
          data-switchlive='true'
          data-switchlive-impression='true'
          data-switchlive-impression-id-PL='1'
        >
          <a
            id='lcly-link-0'
            data-switchlive='true'
            data-switchlive-mode='auto'
            data-switchlive-id-PL='6'
            href='https://brands.locally.com'
            target='_blank'
          >
            Product Locator by Locally
          </a>
        </ProductLocator>
      </MainContainer>
    </>
  );
};

export default LocallyProductLocator;
