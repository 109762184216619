import React, { useEffect } from 'react';
import {
  cannonStyled,
  NSH4,
  TWParagraph3,
  useCannonStyletron,
} from '../../../theme';
import { Button } from '../../../atoms';

import ThanksMail from '../../../assets/thanks-mail.svg';
import { CannonSafeThemeT } from '../../../theme/CannonSafesTheme';

import scrollTo from 'gatsby-plugin-smoothscroll';

const MainContainer = cannonStyled('div', {
  height: '60vh',
  paddingTop: '30px',
});

const ButtonContainer = cannonStyled('div', {
  margin: '10px',
});

const ButtonsContainer = cannonStyled('div', {
  marginTop: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const SurveyThanks = () => {
  const [css, theme] = useCannonStyletron();

  useEffect(() => {
    setTimeout(() => {
      scrollTo('#thanksContainer');
    }, 500);
  }, []);

  return (
    <>
      <ThanksMail id='thanksContainer' />
      <NSH4
        style={{ fontSize: '36px', paddingTop: '30px' }}
        color={theme.cannonColors.primaryBlue}
        weight={900}
        align='center'
      >
        Thanks!
      </NSH4>
      <TWParagraph3
        style={{ fontSize: '19px', paddingTop: '12px' }}
        align='center'
        color={theme.cannonColors.secondarySilverDark}
        weight={400}
      >
        This will help us to bring better products for you
      </TWParagraph3>
    </>
  );
};

export default SurveyThanks;
