import React, { useState } from 'react';
import { cannonStyled, useCannonStyletron } from '../../../../theme';
import ArrowDown from '../../../../assets/accordion-arrow-down.svg';
import ArrowRight from '../../../../assets/accordion-arrow-right.svg';

interface IAccordionProps {
  title: string;
  initiallyOpened: boolean;
  blueIcon?: boolean;
}

const AccordionWrapper = cannonStyled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderTop: '1px solid #E7E9EE',
  padding: '15px 0',
});

const AccordionButton = cannonStyled('button', {
  color: '#001F54',
  fontFamily: 'Titillium Web',
  fontWeight: 700,
  fontSize: '20px',
  cursor: 'pointer',
  width: '100%',
  textAlign: 'left',
  border: 'none',
  outline: 'none',
  transition: '0.4s',
  background: 'none',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const AccordionPanel = cannonStyled('div', {
  paddingTop: '5px',
  paddingLeft: '10px',
  display: 'none',
  overflow: 'hidden',
  fontSize: '18px',
  fontFamily: 'Titillium Web',
});

const Accordion: React.FC<IAccordionProps> = ({
  blueIcon = false,
  ...data
}) => {
  const [active, setActive] = useState(data.initiallyOpened);
  const [css, theme] = useCannonStyletron();
  const activeCss = css({
    display: 'block !important',
  });

  const blueSvgCss = css({
    stroke: theme.cannonColors.primaryBlue,
  });

  const Icon = () => {
    return active ? (
      <ArrowDown className={blueIcon && blueSvgCss} />
    ) : (
      <ArrowRight className={blueIcon && blueSvgCss} />
    );
  };
  return (
    <AccordionWrapper>
      <AccordionButton onClick={() => setActive(!active)}>
        {data.title}
        <Icon />
      </AccordionButton>
      <AccordionPanel className={active && activeCss}>
        {data.children}
      </AccordionPanel>
    </AccordionWrapper>
  );
};

export default Accordion;
