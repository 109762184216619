import React, { useEffect, useRef, useState } from 'react';
import {
  LandingSelectorImage,
  LandingSelectorImageContainer,
  VideoContainer,
} from '.';
import Carousel, {
  arrowsPlugin,
  slidesToShowPlugin,
  slidesToScrollPlugin,
} from '@brainhubeu/react-carousel';
import { Button } from '../../../atoms';
import { cannonStyled, useCannonStyletron } from '../../../theme';
import { IFileImage } from '../../../@types';
import ArrowRight from '../../../assets/arrow-right.svg';
import PlayIcon from '../../../assets/play-icon.svg';
import { useAPIURL } from '../../../hooks';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const MainContainer = cannonStyled('div', () => ({
  position: 'relative',
}));

interface Props {
  vertical?: boolean;
  images: IFileImage[];
  displayImage: IFileImage;
  onImageSelectedChange: (value: IFileImage) => void;
}

//create your forceUpdate hook
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

const LandingImagesCarousel = ({
  images = [],
  onImageSelectedChange,
  vertical = false,
  displayImage = {} as IFileImage,
}: Props) => {
  // HOOKS

  const forceUpdate = useForceUpdate();

  const [css, theme] = useCannonStyletron();
  const buttonRef = useRef(null) as any;
  const API_URL = useAPIURL();
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  // STATE

  // EFFECTS

  useEffect(() => {
    if (vertical) {
      document.documentElement.getElementsByTagName('ul')[0].style.transform =
        'translateX(0)';
      document.documentElement.getElementsByTagName('ul')[0].style.width =
        '3500px';
      forceUpdate();
    }
  }, [windowWidth, windowHeight]);

  // STYLES

  const stylesCarouselContainer = css(
    vertical
      ? {
          transform: 'rotate(90deg) !important',
          width: '500px !important',
          position: 'absolute',
          left: '-200px',
          top: '200px',
        }
      : {}
  );

  // RENDER
  return (
    <MainContainer>
      <div id='items-container' className={stylesCarouselContainer}>
        <div>{forceUpdate}</div>
        <Carousel
          plugins={[
            {
              resolve: arrowsPlugin,
              options: {
                arrowLeft: <></>,
                arrowLeftDisabled: <></>,
                arrowRight: (
                  <Button.IconButton
                    ref={buttonRef}
                    $style={{
                      ...{
                        backgroundColor: theme.cannonColors.primaryBlue,
                        width: '26px !important',
                        height: '26px !important',
                        borderRadius: '4px',
                        border: '2px solid white',
                        marginRight: 0,
                      },
                      ...(vertical
                        ? {
                            position: 'absolute',
                            left: '510px',
                            top: '30px',
                          }
                        : {}),
                    }}
                  >
                    <ArrowRight style={{ stroke: 'white', width: '90%' }} />
                  </Button.IconButton>
                ),
                arrowRightDisabled: <></>,
                addArrowClickHandler: true,
              },
            },
            'infinite',
            'fastSwipe',
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 5,
              },
            },
            {
              resolve: slidesToScrollPlugin,
              options: {
                numberOfSlides: 1,
              },
            },
          ]}
        >
          {images.map((img, index) => {
            return (
              <LandingSelectorImageContainer
                className='selectorImageContainer'
                key={img?.id}
                onClick={() => {
                  onImageSelectedChange(img);
                  buttonRef.current.click();
                }}
                $style={{ transform: vertical ? 'rotate(-90deg)' : '' }}
              >
                <LandingSelectorImage
                  src={
                    !img?.mime?.includes('video') && !img.youtubeLink
                      ? API_URL + img?.url
                      : API_URL + displayImage?.url
                  }
                  alt={`Image ${index} on carusel`}
                />
                {img.youtubeLink && (
                  <VideoContainer>
                    <PlayIcon />
                  </VideoContainer>
                )}
                {img?.mime?.includes('video') && (
                  <VideoContainer>
                    <PlayIcon />
                  </VideoContainer>
                )}
              </LandingSelectorImageContainer>
            );
          })}
        </Carousel>
      </div>
    </MainContainer>
  );
};

export default LandingImagesCarousel;
