import React from 'react';

import { IPLActions } from '../../../@types';

import { Wrapper } from '../../layout';
import { GridContainer, ResponsiveContainer } from '../../../atoms/Container';
import { cannonStyled, NSH2, TWH4, useCannonStyletron } from '../../../theme';
import { ActionButtonsCard } from '../../../atoms/Cards';
import { SmallSpace } from '../../../atoms/Space';
import { useAPIURL, useTableOrMobile } from '../../../hooks';

const WrapperWithMargin = cannonStyled(Wrapper, {
  marginTop: '80px',
  marginBottom: '120px',
});

const ActionButtons = (props: IPLActions) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const API_URL = useAPIURL();

  const direction = 'horizontal';
  const width = isMobile ? '90%' : '70%';

  const countElements = props.links.length;

  return (
    <WrapperWithMargin noScroll compact isCentered direction='vertical'>
      <ResponsiveContainer $style={{ marginTop: '40px', marginBottom: '40px' }}>
        <SmallSpace direction='vertical' />
        <GridContainer
          width={width}
          isMobile={isMobile}
          isTablet={isTablet}
          items={countElements}
          direction={direction}
          spacing={theme.sizing.scale1000}
        >
          {props.links.map((item) => (
            <ActionButtonsCard
              actionTitle=''
              actionURL=''
              src={API_URL + item?.icon?.url}
              title={item.title}
              text={item.description}
              onClick={() => (window.location.href = item.linkToPage)}
            />
          ))}
        </GridContainer>
      </ResponsiveContainer>
    </WrapperWithMargin>
  );
};

export default ActionButtons;
