import React, { useEffect, useState } from 'react';
import {
  CannonSafeThemeT,
  cannonWithStyle,
  useCannonStyletron,
} from '../../../theme/CannonSafesTheme';
import { cannonStyled, NSH2, TWH4, TWParagraph3 } from '../../../theme';
import { ParagraphSmall } from 'baseui/typography';
import {
  usePostService,
  useCoreAPIURL,
  useTableOrMobile,
} from '../../../hooks';
import { Input, StyledRoot, InputProps } from 'baseui/input';
import { Button } from 'baseui/button';
import { ErrorForm } from '../../../atoms/Cards';
import MailIcon from '../../../assets/mailing.svg';

const RootWithStyle = cannonWithStyle(StyledRoot, (props: any) => {
  const { $disabled, $isFocused, $positive, $theme, $error } = props;
  const stateColor = $error
    ? $theme.cannonColors.secondaryOrangeLight
    : $theme.cannonColors.primaryWhiteLight;
  return {
    borderLeftColor: stateColor,
    borderRightColor: stateColor,
    borderTopColor: stateColor,
    borderBottomColor: stateColor,
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
    borderWidth: '1px',
  };
});

const SignUpContent = cannonStyled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'flex-start',
  width: '100%',
  height: '70%',
  position: 'relative',
});

const FloatElement = cannonStyled('div', {
  position: 'absolute',
  top: '10px',
  right: '10px',
  zIndex: 2,
  transition: 'all 250ms linear 2s',
});

const FormContainer = cannonStyled(
  'form',
  ({ isMobile }: { isMobile: boolean }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: isMobile ? 'column' : 'row',
    minWidth: isMobile ? 'calc(100vw-50px)': '300px',
  })
);

interface ISignUpHome extends InputProps {
  requiredStyle?: boolean;
  text: string;
  description: string;
  acknowledgment: string;
  $theme?: CannonSafeThemeT;
  showThanks: () => void;
}

const SignMeUpForm = ({
  requiredStyle = false,
  // onChange = () => {},
  showThanks,
  text = '',
  description = '',
  acknowledgment = '',
  ...other
}: ISignUpHome) => {
  // HOOKS
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();

  // STATE
  const [email, setEmail] = useState('');
  const [send, setSend] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [signUpMail, setSignUpMail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [isShowingAlert, setShowingAlert] = useState(false);

  const coreAPI_URL = useCoreAPIURL();

  //HANDLERS && FUNCTIONS
  function checkMail(value: string) {
    const valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
    setEmailValid(valid);
    setEmail(value);
    setErrorShow((email === '' && submitClicked) || !valid);
    // setSignUpMail(email);
  }

  async function sendData(value: object) {
    const response = await fetch(coreAPI_URL + '/newsletter', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Accept: 'application/json',
      },
      body: JSON.stringify(value),
    })
      .then((r) => showThanks())
      .catch((e) => setShowingAlert(true));
  }

  {
    /* for future development
    const [status, update, error] = usePostService(
    API_URL + '/newsletter-users',
    { email },
    send
); */
  }

  const errorMsgStyle = css({
    paddingTop: '8px',
    paddingBottom: '8px',
    fontWeight: 'bold',
    fontFamily: 'Titillium Web !important',
    fontSize: '16px',
    textAlign: 'left',
  });

  const alertShown = css({
    opacity: 1,
    transition: 'all 250ms linear',
  });

  const alertHidden = css({
    opacity: 0,
    transition: 'all 250ms linear 6s',
  });

  return (
    <SignUpContent>
      <FloatElement
        className={isShowingAlert ? alertShown : alertHidden}
        onTransitionEnd={() => setShowingAlert(false)}
      >
        <ErrorForm bgColor={theme.cannonColors.primaryWhite} noScroll />
      </FloatElement>

      <NSH2
        color={theme.cannonColors.primaryWhiteLight}
        align='left'
        weight={900}
      >
        {text}
      </NSH2>
      <TWH4
        color={theme.cannonColors.primaryWhiteLight}
        align='left'
        weight={400}
      >
        {description}
      </TWH4>
      <FormContainer
        isMobile={isMobile}
        onSubmit={(e) => {
          e.preventDefault();
          if (emailValid) {
            sendData({ data: { email: email } });
          }
        }}
      >
        <Input
          size='default'
          value={email}
          placeholder='Write your email'
          onChange={(e: any) => checkMail(e.currentTarget.value)}
          error={errorShow}
          overrides={{
            Root: {
              style: {
                backgroundColor: 'rgb(255, 255, 255, 0.2)',
              },
              component: RootWithStyle,
            },
            InputContainer: {
              style: {
                backgroundColor: 'transparent',
              },
            },
            Input: {
              style: {
                color: 'white',
                caretColor: 'white',
                '::placeholder': {
                  color: 'white',
                  fontFamily: 'Titillium Web !important',
                },
              },
            },
            StartEnhancer: {
              style: {
                backgroundColor: 'transparent',
              },
            },
          }}
          startEnhancer={<MailIcon />}
        />
        {errorShow && isMobile && (
        <ParagraphSmall
          color={theme.cannonColors.primaryWhiteLight}
          className={errorMsgStyle}
          margin={0}
        >
          {email !== '' && !emailValid ? 'Email Invalid' : 'Field Required'}
        </ParagraphSmall>
      )}
        <Button
          onClick={() => setSubmitClicked(true)}
          size='mini'
          disabled={send}
          $style={{
            backgroundColor: theme.cannonColors.secondaryOrangeLight,
            width: isMobile ? '100%' : '100px',
            marginTop: isMobile ? '10px' : '',
            fontSize: '16px',
            fontFamily: 'Titillium Web !importat',
            borderRadius: isMobile ? '4px' : '0px 4px 4px 0px !important',
            padding: isMobile ? '10px' : ''
          }}
        >
          Join now
        </Button>
      </FormContainer>
      {errorShow &&!isMobile && (
        <ParagraphSmall
          color={theme.cannonColors.primaryWhiteLight}
          className={errorMsgStyle}
          margin={0}
        >
          {email !== '' && !emailValid ? 'Email Invalid' : 'Field Required'}
        </ParagraphSmall>
      )}

      <TWParagraph3
        color={theme.cannonColors.primaryWhiteLight}
        align='left'
        weight={200}
      >
        {acknowledgment}
      </TWParagraph3>
    </SignUpContent>
  );
};

export default SignMeUpForm;
