import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { cannonStyled, useCannonStyletron, NSH44, TWH5 } from '../../../theme';

import { IContactDetails } from '../../../pages/contact';
import { CannonSafeThemeT } from '../../../theme/CannonSafesTheme';
import { ContactUsCard } from '../../../atoms/Cards';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import InformationForm from './InformationForm';
import Thanks from './Thanks';
import { RoundedButton } from '../../../atoms/Button';

export interface IContactProps {
  src?: string;
  isMobile?: boolean;
  $theme?: CannonSafeThemeT;
}

export interface IPersonalInformation {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  comment: string;
  fileUpload: File;
  subscribe: string;
  contactBy: string;
}

interface Props {
  informationProp: IPersonalInformation;
}

const MainContainer = cannonStyled('div', (props: { isMobile: boolean }) => ({
  width: '100%',
  marginTop: props.isMobile ? '30px' : '50px',
}));

const FlexColumn = cannonStyled('div', (props: IContactProps) => ({
  width: '100%',
  display: 'flex',
  flexDirection: props.isMobile ? 'column' : 'row',
  justifyContent: 'space-between',
}));

const ColumnDiv = cannonStyled('div', (props: IContactProps) => ({
  width: props.isMobile ? '100%' : '45%',
}));

const ContactForm = (props: IContactDetails) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const API_URL = useAPIURL();
  // STATE
  const [information, setInformation] = useState({} as IPersonalInformation);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [submited, setSubmited] = useState(false);

  //EFFECT

  // HANDLERS && FUNCTIONS

  function handleChange(value: string, key: string) {
    if (key === 'email') {
      const valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
      setEmailInvalid(!valid);
    }
    const tmp = { ...information };
    tmp[key] = value;
    setInformation(tmp);
  }

  const {
    firstName = '',
    lastName = '',
    email = '',
    phone = '',
    comment = '',
    fileUpload,
    subscribe = '',
    contactBy = '',
  } = information;
  return (
    <MainContainer isMobile={isMobile}>
      <FlexColumn isMobile={isMobile}>
        <ColumnDiv isMobile={isMobile}>
          <NSH44
            color={theme.cannonColors.primaryBlue}
            margin={0}
            align='left'
            weight={900}
            $style={{
              paddingBottom: isMobile ? '16px' : 'auto',
              lineHeight: 'auto',
              marginLeft: isMobile ? '-0.5%' : 0, //Negative value to fix a gap caused by the font in mobile portview
            }}
          >
            Contact Us
          </NSH44>
          <TWH5
            color={theme.cannonColors.secondarySilverDark}
            margin={0}
            align='left'
            weight={400}
            $style={{ fontSize: `min(4.5vw, 20px)` }}
          >
            <ReactMarkdown
              components={{
                a: ({ node, ...props }) => (
                  <a
                    style={{
                      color: theme.cannonColors.secondaryOrange,
                      fontWeight: 700,
                    }}
                    {...props}
                  />
                ),
              }}
            >
              {props.contactDescription}
            </ReactMarkdown>
          </TWH5>
          {!isMobile && (
            <ContactUsCard
              telephone={props.telephone}
              address={props.address}
              hoursDays={props.hoursDays}
            />
          )}
        </ColumnDiv>

        <ColumnDiv isMobile={isMobile}>
          {/* {!submited && (
            <InformationForm
              showThanks={() => {
                setSubmited(true);
              }}
              firstName={''}
              lastName={''}
              email={''}
              phone={''}
              comment={''}
              fileUpload={undefined}
              subscribe
              contactBy={''}
            />
          )}
          {submited && <Thanks />} */}
          <RoundedButton
            onClick={() => {
              window.location.href =
                'https://customersupport.cannonsafe.com/portal/en/newticket';
            }}
            clean
            color='secondary'
            size='compact'
            $style={{ margin: '80px 0', padding: '20px 50px' }}
          >
            Open a Ticket
          </RoundedButton>
          {isMobile && (
            <ContactUsCard
              telephone={props.telephone}
              address={props.address}
              hoursDays={props.hoursDays}
            />
          )}
        </ColumnDiv>
      </FlexColumn>
    </MainContainer>
  );
};

export default ContactForm;
