import React from 'react';
import { useTableOrMobile } from '../../../../hooks';
import {
  cannonStyled,
  NSH3,
  TWH1,
  useCannonStyletron,
} from '../../../../theme';

interface ITitleAndSubtitle {
  title: string;
  subTitle: string;
}

const TextAlignContainer = cannonStyled(
  'div',
  ({ isMobile }: { isMobile: boolean }) => ({
    textAlign: isMobile ? 'left' : 'center',
    width: '100%',
  })
);

const TitleAndSubtitleAssitance = (props: ITitleAndSubtitle) => {
  const [isMobile, isTablet] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();

  return (
    <TextAlignContainer isMobile={isMobile}>
      <NSH3
        weight='bold'
        align={isMobile ? 'left' : 'center'}
        $style={{ fontSize: isMobile ? '36px' : '44px' }}
        color={theme.cannonColors?.primaryBlue}
        margin={20}
      >
        {props.title}
      </NSH3>
      <TWH1
        color={theme.cannonColors.primaryGray}
        weight={400}
        align={isMobile ? 'left' : 'center'}
        style={{ fontSize: `min(19px, 22px)`, marginBottom: '40px' }}
      >
        {props.subTitle}
      </TWH1>
    </TextAlignContainer>
  );
};

export default TitleAndSubtitleAssitance;
