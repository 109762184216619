import { AspectRatioBox } from 'baseui/aspect-ratio-box';
import React from 'react';
import { IThemeProp } from '../../../../atoms/Cards/CapabilityCard';
import { useAPIURL } from '../../../../hooks';
import { ISpecification, SpecificationCapability } from '../../../../pages';
import { cannonStyled, useCannonStyletron } from '../../../../theme';

interface ICapabilityProps extends IThemeProp {
  isMobile?: boolean;
  specification?: ISpecification;
}

const Grid = cannonStyled('div', (props: ICapabilityProps) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${props.isMobile ? 2 : 3}, 1fr)`,
  padding: '30px 0px',
  gridGap: 'min(4vw, 30px)',
}));

const CardContainer = cannonStyled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const StyledAspectRatio = cannonStyled('div', (props: IThemeProp) => ({
  height: 'auto',
  width: 'min(15vw, 50px)',
  objectFit: 'cover',
  overflow: 'hidden',
  display: 'flex',
  marginRight: '20px',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Image = cannonStyled('img', {
  width: '100%',
});

const DetailText = cannonStyled(
  'span',
  ({ isMobile }: { isMobile: boolean }) => ({
    fontFamily: 'Titillium Web',
    fontSize: isMobile ? '14.5px' : 'min(2vw, 15px)',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#4E4E4E',
    '::after': {
      content: 'a',
      whiteSpace: 'pre',
    },
  })
);

const Component = (props: ICapabilityProps) => {
  const [css, theme] = useCannonStyletron();
  const API_URL = useAPIURL();

  return (
    <Grid isMobile={props.isMobile}>
      {props.specification.list.map((item) => (
        // TODO: Somebody should refactor this and put it into atoms/Cards when we have the time
        <CardContainer>
          <StyledAspectRatio>
            <Image src={API_URL + item.image?.url} alt={item.name}/>
          </StyledAspectRatio>
          <DetailText isMobile={props.isMobile}>{item.name}</DetailText>
        </CardContainer>
      ))}
    </Grid>
  );
};

export default Component;
