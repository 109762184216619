import React from 'react';
import { cannonStyled, TWH1, useCannonStyletron } from '../../../../theme';
import { ISpecification, SpecificationFeature } from '../../../../pages';
import {
  DividerDecorator,
  ItemFlex,
  SpecContainer,
  SpecGrid,
  SpecTitle,
  SpecValueContainer,
} from './Capacity';
import { useAPIURL, useTableOrMobile } from '../../../../hooks';

const Image = cannonStyled('img', {
  height: '36px',
  marginRight: '6px'
});
const FlexContainer = cannonStyled('div', {
  display: 'flex',
  alignItems: 'center',
});

const Component = (props: {
  isMobile?: boolean;
  specification: ISpecification;
}) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const API_URL = useAPIURL();

  const rowSize = Math.ceil(props.specification.list.length / 2);

  return (
    <SpecGrid isTablet={isTablet} isMobile={props.isMobile}>
      {props.isMobile ? (
        <div>
          {props.specification.list.map((spec, index) => (
            <SpecContainer
              key={spec.id}
              isMobile={props.isMobile}
              lastItem={index === props.specification.list.length - 1}
              $style={{ paddingBottom: '0px important' }}
            >
              <FlexContainer>
                {spec.image?.url && <Image src={API_URL + spec.image?.url} alt={spec.value}/>}
                <SpecTitle>{spec.name}</SpecTitle>
              </FlexContainer>
              <SpecValueContainer>
                <TWH1
                  style={{ fontSize: '14px' }}
                  color={theme.cannonColors.primaryBlue}
                  align='left'
                  weight={700}
                >
                  {spec.value}
                </TWH1>
              </SpecValueContainer>
            </SpecContainer>
          ))}
        </div>
      ) : (
        <>
          <div>
            {props.specification.list.slice(0, rowSize).map((spec, index) => (
              <SpecContainer key={spec.id} lastItem={index === rowSize - 1}>
                <FlexContainer>
                  {spec.image?.url && <Image src={API_URL + spec.image?.url} alt={spec.value}/>}
                  <SpecTitle>{spec.name}</SpecTitle>
                </FlexContainer>
                <SpecValueContainer>
                  <TWH1
                    style={{ fontSize: '16px' }}
                    color={theme.cannonColors.primaryBlue}
                    align='left'
                    weight={700}
                  >
                    {spec.value}
                  </TWH1>
                </SpecValueContainer>
              </SpecContainer>
            ))}
          </div>
          <DividerDecorator color={'#969BA6'} />
          <div>
            {props.specification.list.slice(rowSize).map((spec, index) => (
              <SpecContainer key={spec.id} lastItem={index === rowSize - 1}>
                <FlexContainer>
                  {spec.image?.url && <Image src={API_URL + spec.image?.url} alt={spec.value}/>}
                  <SpecTitle>{spec.name}</SpecTitle>
                </FlexContainer>
                <SpecValueContainer>
                  <TWH1
                    style={{ fontSize: '16px' }}
                    color={theme.cannonColors.primaryBlue}
                    align='left'
                    weight={700}
                  >
                    {spec.value}
                  </TWH1>
                </SpecValueContainer>
              </SpecContainer>
            ))}
          </div>
        </>
      )}
    </SpecGrid>
  );
};

export default Component;
