import React, { useState, useEffect } from 'react';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';
import { cannonStyled } from '../../theme';
import BlogCard from './BlogCard';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Button } from '../../atoms';

interface BlogComponentProps {
  isMobile?: boolean;
  $theme?: CannonSafeThemeT;
}

export const ArticlesContainer = cannonStyled(
  'div',
  (props: BlogComponentProps & { width: number; isOurLegacy?: boolean }) => ({
    width: '100%',
    maxWidth: '1280px',
    display: 'grid',
    justifyContent: 'center',
    gap: '10px 20px',
    gridTemplateColumns:
      props.width > 1279
        ? '1fr 1fr 1fr'
        : props.isOurLegacy && props.width <= 1279
        ? '1f'
        : props.width > 815
        ? '1fr 1fr'
        : '1fr',
    gridTemplateRows: '1fr',
  })
);

const BlogSection = (props: { dataInfo: any }) => {
  const { width } = useWindowDimensions();

  // Array of all blog articles
  const allArticles = props.dataInfo;

  // State for the list
  let numberOfArticles = width > 1279 ? 6 || 9 : width > 815 ? 2 : 1;
  const [list, setList] = useState([...allArticles.slice(0, numberOfArticles)]);

  useEffect(() => {
    setList([...allArticles.slice(0, numberOfArticles)]);
  }, [props.dataInfo, numberOfArticles]);

  const loadMore = () => {
    const currentLength = list.length;
    const isMore = currentLength === allArticles.length;
    const nextResults = !isMore
      ? allArticles.slice(currentLength, currentLength + numberOfArticles)
      : [];
    setList([...list, ...nextResults]);
  };

  return (
    <>
      <ArticlesContainer width={width}>
        {list.map((article) => {
          return (
            <BlogCard
              title={article.title}
              previewText={article.previewText}
              previewImage={article.previewImage}
              mainArticle={article.mainArticle}
              mainMedia={article.mainMedia}
              blogCategory={article.blogCategory}
              slug={article.slug}
            />
          );
        })}
      </ArticlesContainer>
      {list.length != allArticles.length ? (
        <Button.RoundedButton
          onClick={loadMore}
          size='compact'
          color='primary'
          clean
          smallBtn
          margin={80}
          fontSize='16px'
        >
          Load more articles
        </Button.RoundedButton>
      ) : (
        ''
      )}
    </>
  );
};

export default BlogSection;
