import React from 'react';
import { cannonStyled } from '../../../theme';
import { CannonSafeThemeT } from '../../../theme/CannonSafesTheme';

interface IProductBannerProps {
  src?: string;
  $theme?: CannonSafeThemeT;
  isMobile?: boolean;
}

const ProductBannerContainer = cannonStyled(
  'div',
  (props: IProductBannerProps) => ({
    width: '100%',
    height: '450px',
    backgroundSize: 'cover',
    backgroundImage: `url(${props.src})`,
    backgroundPosition: '50% 50%',
    backgroundAttachment: 'fixed',
    marginTop: '100px',
  })
);

const ProductBannerImage = cannonStyled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const SectionMiddleware = (props: IProductBannerProps) => {
  return (
    <>
      {props.isMobile ? (
        <div style={{height: '200px'}}>
          <ProductBannerImage src={props.src} alt='Son and father hunters image'/>
        </div>
      ) : (
        <ProductBannerContainer src={props.src} />
      )}
    </>
  );
};

export default SectionMiddleware;
