import React, { Key, useEffect, useState } from 'react';
import { cannonStyled, NSH2, TWParagraph3 } from '../../../theme';
import {
  CannonSafeThemeT,
  useCannonStyletron,
} from '../../../theme/CannonSafesTheme';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import { Wrapper } from '../../layout';
import { ResponsiveContainer } from '../../../atoms/Container';
import { H1 } from 'baseui/typography';
import { Tabs, Tab, FILL } from 'baseui/tabs-motion';
import { Capability, Capacity, Features, Design } from './components';
import { ISpecification } from '../../../pages';
import Background from '../../../images/Texture-Background-Detail-Page.jpeg';

interface IProductSpecs {
  specifications?: Array<ISpecification>;
  isMobile?: boolean;
  $theme?: CannonSafeThemeT;
  title?: string;
  backgroundImage?: string;
  regulationParagraph?: string;
  regulationActive?: boolean;
}

const GridSetup = cannonStyled('div', (props: IProductSpecs) => ({
  position: 'relative',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: props.isMobile ? '1fr' : '9fr 4fr',
  gridTemplateRows: props.isMobile ? 'auto auto auto' : 'auto auto',
  gridTemplateAreas: props.isMobile
    ? `
    "image"
    "tabs"
  `
    : `
    "tabs image"
  `,
  gridRowGap: props.$theme.sizing.scale800,
  gridColumnGap: props.$theme.sizing.scale1200,
  paddingTop: '60px',
  paddingBottom: '60px',
}));

const ContentImageContainer = cannonStyled(
  'div',
  ({ isMobile }: { isMobile: boolean }) => ({
    gridArea: 'image',
    minHeight: '50vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: isMobile ? '0px' : '30px',
  })
);

const ContentSupportDetail = cannonStyled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'flex-start',
});

const ContentSupportDetailItem = cannonStyled('div', {
  flex: '0 1 45%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  height: '45px',
  marginTop: '16px',
});

const ContentSupportDetailSpec = cannonStyled(
  'span',
  (props: { title?: boolean }) => ({
    fontFamily: 'Titillium Web',
    fontSize: '16px',
    fontWeight: props.title ? 700 : 400,
    color: '#4E4E4E',
  })
);

const ContentImage = cannonStyled(
  'img',
  ({ isMobile }: { isMobile: boolean }) => ({
    // here change the size
    width: isMobile ? '80%' : '100%',
  })
);

const ContainerWrapper = cannonStyled(Wrapper, () => ({
  paddingTop: '130px',
  marginBottom: '60px',
  backgroundImage: `url('${Background}')`,
  backgroundSize: 'cover',
}));

const SectionMiddleware = (props: IProductSpecs) => {
  const specifications = props.specifications.sort((a, b) => {
    if (a.title === 'Features') {
      return -1;
    }
    if (b.title === 'Capacity') {
      return 1;
    }
    return 0;
  });

  const [isMobile] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();
  const [active, setActive] = useState<Key>(specifications[0].title);
  const API_URL = useAPIURL();

  const currentSpec = specifications.find((spec) => spec.title === active);

  return (
    <ContainerWrapper
      id='container'
      noScroll
      autoHeight
      isCentered
      direction='vertical'
      style={{ height: 'auto', minHeight: 'auto' }}
    >
      <ResponsiveContainer flexDirection='column'>
        <NSH2
          color={theme.cannonColors.primaryBlue}
          weight={900}
          align='center'
        >
          {props.title} specs
        </NSH2>
        <GridSetup isMobile={isMobile}>
          <Tabs
            activeKey={active}
            onChange={({ activeKey }) => setActive(activeKey)}
            activateOnFocus
            fill={FILL.fixed}
            overrides={{
              Root: {
                style: () => ({
                  minHeight: '60vh',
                  // overflowX: 'scroll'
                }),
              },
              TabHighlight: {
                style: () => ({
                  backgroundColor: theme.cannonColors.secondaryOrange,
                  height: '2px',
                  '&:hover': {
                    background: 'transparent',
                  },
                  // borderBottom: `2px solid ${theme.cannonColors.secondaryOrange}`
                }),
              },
              TabBorder: {
                style: () => ({
                  backgroundColor: theme.cannonColors.secondarySilver,
                  borderRadius: '2.5px',
                  marginTop: '3px',
                  height: '2px',
                }),
              },
            }}
          >
            {specifications.map((specification) => (
              <Tab
                key={specification.title}
                title={specification.title}
                overrides={{
                  Tab: {
                    style: () => ({
                      background: 'transparent',
                      fontFamily: 'Titillium Web !important',
                      fontSize: 'min(3.4vw, 17px) !important',
                      fontWeight: active === specification.title ? 'bold' : 400,
                      color:
                        active === specification.title
                          ? theme.cannonColors.primaryBlue
                          : theme.cannonColors.secondarySilver,
                      '&:active': {
                        fontSize: '16px !important',
                      },
                      '&:hover': {
                        background: 'transparent',
                      },
                    }),
                  },
                }}
              >
                {specification.title === 'Capacity' && (
                  <Capacity isMobile={isMobile} specification={specification} />
                )}
                {specification.title === 'Capability' && (
                  <Capability
                    isMobile={isMobile}
                    specification={specification}
                  />
                )}
                {specification.title === 'Features' && (
                  <Features isMobile={isMobile} specification={specification} />
                )}
                {specification.title === 'Design' && (
                  <Design isMobile={isMobile} specification={specification} />
                )}
              </Tab>
            ))}
          </Tabs>
          {props.regulationActive && (
            <div
              className={css({
                margin: '2vh',
                position: 'absolute',
                top: isMobile ? '90%' : '85%',
                left: '5%',
                width: isMobile ? '85%' : '50%',
              })}
            >
              <TWParagraph3
                color={theme.cannonColors.secondarySilver}
                weight={400}
                align={'center'}
              >
                {props.regulationParagraph}
              </TWParagraph3>
            </div>
          )}
          <ContentImageContainer isMobile={props.isMobile}>
            <ContentImage
              isMobile={props.isMobile}
              src={API_URL + currentSpec.image?.url}
              alt={props.title}
            />
            <ContentSupportDetail>
              {currentSpec.hightlight.map((detail) => (
                <ContentSupportDetailItem>
                  <ContentSupportDetailSpec title>
                    {detail.name}
                  </ContentSupportDetailSpec>
                  <ContentSupportDetailSpec>
                    {detail.value}
                  </ContentSupportDetailSpec>
                </ContentSupportDetailItem>
              ))}
            </ContentSupportDetail>
          </ContentImageContainer>
        </GridSetup>
      </ResponsiveContainer>
    </ContainerWrapper>
  );
};

export default SectionMiddleware;
