import { assign } from 'core-js/core/object';
import React from 'react';
import { SRLWrapper } from 'simple-react-lightbox';
import { useAPIURL, useTableOrMobile } from '../../hooks';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { cannonStyled } from '../../theme';
import {
  CannonSafeThemeT,
  useCannonStyletron,
} from '../../theme/CannonSafesTheme';

interface BlogComponentProps {
  isMobile?: boolean;
  $theme?: CannonSafeThemeT;
  elementsCount?: number;
}
const options = {
  buttons: {
    showDownloadButton: false,
    showFullscreenButton: false,
    showThumbnailsButton: false,
  },
};

const ArticlesContainer = cannonStyled(
  'div',
  (props: BlogComponentProps & { width: number }) => ({
    width: '100%',
    marginTop: '40px',
    marginBottom: '60px',
    maxWidth: '1280px',
    display: 'grid',
    justifyContent: 'center',
    gap: '40px 40px',
    gridTemplateColumns:
      props.width > 1279
        ? '1fr 1fr 1fr'
        : props.width > 815
        ? '1fr 1fr'
        : '1fr',
    gridTemplateRows: '1fr',
    cursor: 'pointer',
  })
);

const ArticlesContainerMobile = cannonStyled(
  'div',
  (props: BlogComponentProps) => ({
    width: '100%',
    marginTop: '40px',
    maxWidth: '1280px',
    display: 'grid',
    justifyContent: 'center',
    gap: '10px 10px',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridTemplateRows: `repeat(14, 80px)`,
    cursor: 'pointer',
  })
);

const ImgCardContainer = cannonStyled('div', {
  width: 'auto',
  height: '360px',
  borderRadius: '8px',
  overflow: 'hidden',
});

const ImgCard = cannonStyled('img', {
  width: '100%',
  height: '360px',
  objectFit: 'cover',
  transition: 'transform .5s ease',
  ':hover': {
    transform: 'scale(1.05)',
  },
});

const ImgCardMobile = cannonStyled('img', {
  maxHeight: '360px',
  width: 'auto',
  objectFit: 'cover',
  transition: 'transform .5s ease',
  ':hover': {
    transform: 'scale(1.05)',
  },
});

interface BlogGalleryProps {
  mediaArray: Array<MediaGallery>;
  $label: string;
}

interface MediaGallery {
  url: string;
  width: number;
}

const BlogGallery = (props: BlogGalleryProps) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const { width } = useWindowDimensions();
  const API_URL = useAPIURL();

  const elementsCount = props.mediaArray.length;

  let count = -1;

  let assignStyle = (index: number) => {
    let referenceQuad = Math.floor(index / 4);
    let rowSpan = referenceQuad * 6;

    count = count > 2 ? 0 : (count += 1);

    switch (count) {
      case 0:
        return {
          backgroundColor: 'red',
          gridColumnStart: 1,
          gridColumnEnd: 3,
          gridRow: `${rowSpan + 1} / span 2`,
          borderRadius: '4px',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        };
      case 1:
        return {
          backgroundColor: 'orange',
          gridColumnStart: 3,
          gridColumnEnd: 5,
          gridRow: `${rowSpan + 1} / span 4`,
          borderRadius: '4px',
          overflow: 'hidden',
          maxHeight: '350px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        };
      case 2:
        return {
          backgroundColor: 'yellow',
          gridColumnStart: 1,
          gridColumnEnd: 3,
          gridRow: `${rowSpan + 3} / span 4`,
          borderRadius: '4px',
          overflow: 'hidden',
          maxHeight: '350px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        };
      case 3:
        return {
          backgroundColor: 'blue',
          gridColumnStart: 3,
          gridColumnEnd: 5,
          gridRow: `${rowSpan + 5} / span 2`,
          borderRadius: '4px',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        };
      default:
        return {};
    }
  };

  return (
    <>
      {isMobile ? (
        <SRLWrapper options={options}>
          <ArticlesContainerMobile elementsCount={elementsCount}>
            {props.mediaArray.map((article, index) => {
              return (
                <div style={assignStyle(index)}>
                  <ImgCardMobile src={API_URL + article.url} alt={`${props.$label} + ${index}`} />
                </div>
              );
            })}
          </ArticlesContainerMobile>
        </SRLWrapper>
      ) : (
        <SRLWrapper options={options}>
          <ArticlesContainer width={width}>
            {props.mediaArray.map((article, index) => {
              return (
                <ImgCardContainer>
                  <ImgCard src={API_URL + article.url} alt={`${props.$label} + ${index}`} />
                </ImgCardContainer>
              );
            })}
          </ArticlesContainer>
        </SRLWrapper>
      )}
    </>
  );
};

export default BlogGallery;
