import React, { useState } from 'react';
import { Button, CustomInput } from '../../../atoms';
import { useTableOrMobile } from '../../../hooks';
import { cannonStyled, NSH3, TWH1, useCannonStyletron } from '../../../theme';
import ArrowRight from '../../../assets/arrow-right.svg';
import { graphql, useStaticQuery } from 'gatsby';
import TitleAndSubtitleAssitance from '../KeyAndCombinationForm/components/TitleAndSubtitleAssitance';

const query = graphql`
  query {
    site {
      siteMetadata {
        paymentToken
      }
    }
  }
`;

interface IData {
  site: {
    siteMetadata: {
      paymentToken: string;
    };
  };
}

const TextAlignContainer = cannonStyled(
  'div',
  ({ isMobile }: { isMobile: boolean }) => ({
    textAlign: isMobile ? 'left' : 'center',
    width: '100%',
  })
);

const FormPassword = cannonStyled('form', {
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
});

const FlexContainer = cannonStyled(
  'div',
  ({ direction }: { direction?: 'column' | 'row' }) => ({
    display: 'flex',
    flexDirection: direction,
  })
);

const SubmitButtonContainer = cannonStyled(
  'div',
  ({ isMobile }: { isMobile: boolean }) => ({
    marginTop: '10vh',
    width: isMobile ? '100%' : '50%',
  })
);

const ErrorMsgStyle = cannonStyled('div', {
  paddingTop: '8px',
  paddingBottom: '8px',
  fontWeight: 'bold',
  fontFamily: 'Titillium Web !important',
  fontSize: '16px',
  textAlign: 'left',
});

const PaypalPayment = ({ secondStep }: { secondStep: () => void }) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();

  const data = useStaticQuery<IData>(query);

  const [password, setPassword] = useState('');
  const [validationError, setValidationError] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const checkValidation = () => {
    password === data.site.siteMetadata.paymentToken
      ? secondStep()
      : setValidationError(true);
  };

  return (
    <TextAlignContainer isMobile={isMobile}>
      <TitleAndSubtitleAssitance
        title={'Enter your password'}
        subTitle={
          'This content is password protected. To view it please enter your password below:'
        }
      />

      <FormPassword
        onSubmit={(e) => {
          e.preventDefault();
          if (password != '') {
            checkValidation();
          }
        }}
      >
        <CustomInput
          noMarginLeft
          type='password'
          errorMessage='Field Required'
          error={password === '' && submitClicked}
          label='Password'
          placeholder='Enter the password provided by the Agent'
          value={password}
          onChange={(e: any) => setPassword(e.currentTarget.value)}
          positive={password !== ''}
          required
          requiredStyle
        />
        {validationError && (
          <ErrorMsgStyle
            style={{ color: theme.cannonColors.secondaryCarmineLight }}
          >
            The password is incorrect. Please try again.
          </ErrorMsgStyle>
        )}
        <FlexContainer
          direction='row'
          style={{ justifyContent: 'center', width: '100%' }}
        >
          <SubmitButtonContainer isMobile={isMobile}>
            <Button.RoundedButton
              onClick={() => {
                setSubmitClicked(true);
              }}
              endEnhancer={() => <ArrowRight size={26} />}
              size='compact'
              type='submit'
              color='primary'
              fullWidth
            >
              Continue to payment
            </Button.RoundedButton>
          </SubmitButtonContainer>
        </FlexContainer>
      </FormPassword>
    </TextAlignContainer>
  );
};

export default PaypalPayment;
