import React, { useState } from 'react';
import { Wrapper } from '../../layout';
import { H1, Paragraph3 } from 'baseui/typography';
import { ResponsiveContainer } from '../../../atoms/Container';
import { useCannonStyletron, cannonStyled, NSH2, TWH4 } from '../../../theme';
import { ImageCard } from '../../../atoms/Cards';
import { SmallSpace } from '../../../atoms/Space';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import { IResponseASafeForYou } from '../../../pages';
import Carousel, { Dots, autoplayPlugin } from '@brainhubeu/react-carousel';

interface IGridContainer {
  isMobile: boolean;
  isTablet: boolean;
  spacing: string;
  children?: React.ReactNode;
  carrousel?: boolean;
  width: string;
  direction: 'vertical' | 'horizontal';
}

const GridContainer = cannonStyled('div', (props: IGridContainer) => ({
  display: 'grid',
  gridRowGap: props.spacing,
  gridColumnGap: props.spacing,
  width: props.isMobile || props.isTablet ? '90%' : '90%',
  maxWidth: '1280px',
  gridTemplateColumns: props.isMobile
    ? '1fr'
    : props.isTablet
    ? ' 1fr'.repeat(2)
    : ' 1fr'.repeat(4),
}));

const WrapperWithMargin = cannonStyled(Wrapper, {
  marginTop: '90px',
  marginBottom: '0px',
});

const Thumbnail = cannonStyled('div', () => ({
  width: '29.33px',
  height: '2px',
  marginBottom: '30px',
}));

const SectionMiddleware = (data: IResponseASafeForYou) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const API_URL = useAPIURL();

  const dataLength = data.allStrapiLifeStyleVariation.edges.length;

  // STATE

  const [mobileCarouselValue, setMobileCarouselValue] = useState(0);

  // HANDLERS && FUNCTIONS

  function handleCarouselChange(value: any){
    setMobileCarouselValue(value);
  }

  const highlightActiveDot = (index) => {
    if (mobileCarouselValue < 0) {
      return Math.abs(
        mobileCarouselValue % dataLength === 0 ? 0 : (mobileCarouselValue % dataLength) + dataLength
      ) === index
        ? 'rgba(200, 109, 40, 1)'
        : 'rgba(158, 162, 169, 1)';
    }

    return mobileCarouselValue % dataLength === index
      ? 'rgba(200, 109, 40, 1)'
      : 'rgba(158, 162, 169, 1)';
  };

  const renderThumbnails = () => {
    return Array(dataLength)
      .fill(0)
      .map((_, index) => (
        <Thumbnail
          className={css({ backgroundColor: highlightActiveDot(index) })} />
      ));
  };

  // RENDER

  const containerWidth = isMobile ? '90%' : '100%';
  const direction = 'horizontal';

  if (
    !data.strapiASafeForYou.enabled ||
    !data.allStrapiLifeStyleVariation.edges.length
  )
    return null;

  return (
    <WrapperWithMargin
      compact
      noScroll
      autoHeight
      isCentered
      direction='vertical'
    >
      <NSH2
        color={theme.cannonColors.primaryBlue}
        style={{ textTransform: 'uppercase' }}
        weight={900}
        align='center'
      >
        {data.strapiASafeForYou.title}
      </NSH2>
      <TWH4 align='center' color={theme.cannonColors.primaryGray} weight={400}>
        A safe for every lifestyle
      </TWH4>
      <SmallSpace direction='vertical' />
      <ResponsiveContainer $style={{ marginTop: '40px', marginBottom: '0px', flexDirection: isMobile ? 'column' : 'row' }}>
        <SmallSpace direction='vertical' />
        {isMobile ? (
          <>
            <Carousel
              value={mobileCarouselValue}
              onChange={handleCarouselChange}
              animationSpeed={1000}
              plugins={[
                'infinite',
                {
                  resolve: autoplayPlugin,
                  options: {
                    interval: 10000,
                  },
                },
              ]}
            >
              {data.allStrapiLifeStyleVariation.edges.map(
                (lifestyleVariation) => (
                  <ImageCard
                    src={API_URL + lifestyleVariation.node.fullImage.url}
                    title={lifestyleVariation.node.title}
                    actionTitle='Explore'
                    actionURL={`/safes/?bestFor=${lifestyleVariation.node.title}`}
                  />
                )
              )}
            </Carousel>
            <SmallSpace direction='vertical' />
            <Dots
              value={mobileCarouselValue}
              onChange={handleCarouselChange}
              number={4}
              thumbnails={renderThumbnails()}
            />
          </>
        ) : (
          <GridContainer
            width={containerWidth}
            direction={direction}
            isMobile={isMobile}
            isTablet={isTablet}
            spacing={isMobile ? '20px' : theme.sizing.scale1000}
          >
            {data.allStrapiLifeStyleVariation.edges.map(
              (lifestyleVariation) => (
                <ImageCard
                  src={API_URL + lifestyleVariation.node.fullImage.url}
                  title={lifestyleVariation.node.title}
                  actionTitle='Explore'
                  actionURL={`/safes/?bestFor=${lifestyleVariation.node.title}`}
                />
              )
            )}
          </GridContainer>
        )}
      </ResponsiveContainer>
    </WrapperWithMargin>
  );
};

export default SectionMiddleware;
