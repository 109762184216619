import React from 'react';
import { Wrapper } from '../../../layout';

import { useTableOrMobile } from '../../../../hooks';
import { cannonStyled, useCannonStyletron } from '../../../../theme';
import VideosCard from './VideosCard';
import { IVideoInfo } from '../../../../pages/videos';

interface IVideosProps {
  videosArr: Array<IVideoInfo>;
}

const WrapperWithMargin = cannonStyled(
  Wrapper,
  ({ isMobile }: { isMobile: boolean }) => ({
    marginTop: isMobile ? '90px' : '180px',
    marginBottom: '180px',
  })
);

const TwoColumnsContainer = cannonStyled('div', () => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  gap: '25px',
  justifyContent: 'space-between',
  maxWidth: '1080px',
}));

const VideosGallery = (data: IVideosProps) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();

  const categoryFullName = (category: string) => {
    const categories = {
      howToHelp: 'How to - Help',
      exclusiveFeatures: 'Exclusive features',
      cannonSeries: 'Cannon Series',
      warrantyFireProtection: 'Warranty - Fire Protection',
      faqs: 'FAQs',
    };

    return categories[category];
  };

  return (
    <WrapperWithMargin
      noScroll
      autoHeight
      compact
      isCentered
      direction='vertical'
      isMobile={isMobile}
      style={{ width: '100%', padding: 0, margin: '25px 0' }}
    >
      <TwoColumnsContainer>
        {data.videosArr.map((item) => {
          return (
            <VideosCard
              videoData={item}
              category={categoryFullName(item.category)}
            />
          );
        })}
      </TwoColumnsContainer>
    </WrapperWithMargin>
  );
};

export default VideosGallery;
