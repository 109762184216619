import React, { useRef, useState } from 'react';
import { useAPIURL, useIsVisible, useTableOrMobile } from '../../../hooks';
import { cannonStyled, useCannonStyletron } from '../../../theme';
import Animation, { AppearUp } from '../../../atoms/Animations/index';
import { VideoContainer } from '../ProductLanding';
import { MediaRounded } from '../../../atoms/Image';

interface IMediaContainer {
  media?: {
    url: string;
    ext: string;
  };
  isBorder?: boolean;
  mediaPreview?: string;
  videoLink?: string;
  url?: string;
  $label?: string;
}

const VideoDiv = cannonStyled('div', {
  width: '100%',
  margin: 0,
  textAlign: 'center',
  padding: 0,
});

const MediaComponent = (props: IMediaContainer) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const API_URL = useAPIURL();
  const [hovered, setHovered] = useState(true);
  const contentRef = useRef();
  const isVisible = useIsVisible(contentRef);

  // Function for valid URL in videoLink
  function isValidUrl(videoLink: string) {
    try {
      new URL(videoLink);
      return true;
    } finally {
      return false;
    }
  }

  const mediaUrl = isValidUrl(props.videoLink)
    ? props.videoLink
    : props.media?.url.length > 0
    ? `${API_URL}${props.media.url}`
    : null;

  return (
    <>
      <VideoDiv
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        ref={contentRef}
      >
        <Animation>
          <div style={isVisible ? AppearUp : {}}>
            <MediaRounded
              isOurLegacy={true}
              videoPreview={props.mediaPreview}
              src={mediaUrl || ''}
              ext={props.media?.ext || ''}
              $label={props.$label}
            />
          </div>
        </Animation>
      </VideoDiv>
    </>
  );
};

export default MediaComponent;
