import React from 'react';

import { ImageContainer } from '../../../atoms/Container';

import { ImageRounded } from '../../../atoms/Image';
import { H1, Paragraph2 } from 'baseui/typography';
import { Button } from '../../../atoms';

import ArrowRight from '../../../assets/arrow-right.svg';
import {
  useAPIURL,
  useNewLineText,
  useOrientation,
  useTableOrMobile,
} from '../../../hooks';

import { NSH1, TWH4, useCannonStyletron } from '../../../theme';
import { IResponseMainBanner } from '../../../pages';
import { IFileImage } from '../../../@types';

const SectionMiddleware = (data: IResponseMainBanner) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const { isRotated } = useOrientation();
  const title = useNewLineText(data.strapiMainBanner.title);
  const API_URL = useAPIURL();

  // RENDER

  const {
    showVideo = false,
    frontImage = {} as IFileImage,
    frontVideo = {} as IFileImage,
  } = data.strapiMainBanner;
  const mainMediaUrl = showVideo ? frontVideo.url : frontImage.url;

  return (
    <ImageContainer
      backgroundColor={data.strapiMainBanner.backgroundColor}
      imageSrc={API_URL + data.strapiMainBanner.backgroundImage.url}
      $alt={'background image'}
    >
      <ImageRounded
        src={API_URL + mainMediaUrl}
        isVideo={showVideo}
        alt={'main Image'}
      >
        <NSH1
          $style={{
            textShadow: '0px 2px 9px rgba(0, 0, 0, 0.6)',
            fontSize:
              isMobile && isRotated ? `min(8vh, 26px)` : `min(8vw, 56px)`,
          }}
          align='center'
          color={theme.cannonColors.primaryWhiteLight}
          weight={900}
        >
          {title}
        </NSH1>
        <TWH4
          $style={{
            textShadow: '0px 2px 9px rgba(0, 0, 0, 0.6)',
            fontSize:
              isMobile && !isRotated
                ? '24px'
                : isMobile && isRotated
                ? `min(4vh, 24px)`
                : `min(4vw, 24px)`,
          }}
          align='center'
          color={theme.cannonColors.primaryWhiteLight}
          weight={400}
        >
          {data.strapiMainBanner.description}
        </TWH4>
        <Button.RoundedButton
          arial-label={data.strapiMainBanner.action}
          margin={42}
          size={isMobile ? 'mini' : 'compact'}
          color='clear'
          onClick={() => (window.location.href = '/safes')}
          endEnhancer={
            <ArrowRight style={{ stroke: theme.cannonColors.primaryBlue }} />
          }
        >
          {data.strapiMainBanner.action}
        </Button.RoundedButton>
      </ImageRounded>
    </ImageContainer>
  );
};

export default SectionMiddleware;
