import React, { useState } from 'react';
import { useCannonQuality } from './query';
import { cannonStyled, NSH2, TWH4, TWParagraph3 } from '../../../theme';
import { useCannonStyletron } from '../../../theme/CannonSafesTheme';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import { Wrapper } from '../../layout';
import { ResponsiveContainer } from '../../../atoms/Container';
import { IThemeProp } from '../../../atoms/Cards/CapabilityCard';
import { QualityCard } from '../../../atoms/Cards';
import UserManual from '../../../assets/user-manual.svg';
import { Button } from '../../../atoms';
import Carousel, { Dots, autoplayPlugin } from '@brainhubeu/react-carousel';
import { navigate, Link as GatsbyLink } from 'gatsby';

interface IGridContainer extends IThemeProp {
  isMobile?: boolean;
}
//GAP with vw value is causing bugs in some Safari versions
const GridContainer = cannonStyled('div', (props: IGridContainer) => ({
  width: 'auto',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: `min(2vw,20px)`,
  alignItems: 'center',
  padding: props.isMobile ? 'normal' : '30px',
  maxWidth: '100%', 
  margin: 0,
}));

const QualityWrapper = cannonStyled(Wrapper, {
  backgroundColor: '#F4F6FB !important',
  height: 'auto',
  alignItems: 'center',
});

const Space = cannonStyled('div', (props: IGridContainer) => ({
  paddingTop: props.isMobile ? '70px' : '120px',
}));

const Thumbnail = cannonStyled('div', (props: IGridContainer) => ({
  width: '29.33px',
  height: '2px',
  marginBottom: '30px',
}));

interface ICannonQualityProps {
  displayName: string;
  fileURL: string;
}

const  SectionMiddleware = (props: ICannonQualityProps) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const API_URL = useAPIURL();
  const data = useCannonQuality();
  const dataLength = data.strapiCannonQuality.item.length;

  const [value, setValue] = useState(0);
  function onChange(e) {
    setValue(e);
  }

  const highlightActiveDot = (index) => {
    if (value < 0) {
      return Math.abs(
        value % dataLength === 0 ? 0 : (value % dataLength) + dataLength
      ) === index
        ? 'rgba(200, 109, 40, 1)'
        : 'rgba(158, 162, 169, 1)';
    }

    return value % dataLength === index
      ? 'rgba(200, 109, 40, 1)'
      : 'rgba(158, 162, 169, 1)';
  };

  const renderThumbnails = () => {
    return Array(dataLength)
      .fill(0)
      .map((_, index) => (
        <Thumbnail
          className={css({ backgroundColor: highlightActiveDot(index) })}
        />
      ));
  };

  function download(action: string) {
    if (action === null) {
      alert(`Currently, there is no pdf to download.\nSorry `);
    } else {
      const path = API_URL + props.fileURL;
      var doc = document.createElement('a');
      doc.href = path;
      doc.download = props.fileURL;
      document.body.appendChild(doc);
      doc.click();
      document.body.removeChild(doc);
    }
  };

  return (
    <QualityWrapper
      compact
      noScroll
      autoHeight={false}
      isCentered
      direction='vertical'
    >
      <ResponsiveContainer flexDirection='column'>
        <Space isMobile={isMobile} />
        <NSH2
          isMobile={isMobile}
          margin={isMobile ? 0 : 15}
          color={theme.cannonColors.primaryBlue}
          weight={900}
          align={'center'}
        >
          {data.strapiCannonQuality.displayName.toUpperCase()}
        </NSH2>
        <TWH4
          color={theme.cannonColors.primaryGray}
          weight={400}
          align={'center'}
          className={css({ paddingBottom: '40px' })}
        >
          {data.strapiCannonQuality.description}
        </TWH4>
        {isMobile ? (
          <>
            <Carousel
              value={value}
              onChange={(e) => setValue(e)}
              animationSpeed={1000}
              plugins={[
                'infinite',
                {
                  resolve: autoplayPlugin,
                  options: {
                    interval: 10000,
                  },
                },
              ]}
            >
              {data.strapiCannonQuality.item.map((li) => (
                <QualityCard
                  title={li.name}
                  description={li.value}
                  iconSrc={API_URL + li.image?.url}
                />
              ))}
            </Carousel>
            <Dots
              value={value}
              onChange={onChange}
              number={3}
              thumbnails={renderThumbnails()}
            />
          </>
        ) : (
          <GridContainer isMobile={isMobile}>
            {data.strapiCannonQuality.item.map((li) => (
              <QualityCard
                title={li.name}
                description={li.value}
                iconSrc={API_URL + li.image?.url}
              />
            ))}
          </GridContainer>
        )}

        <TWParagraph3
          isMobile={isMobile}
          color={theme.cannonColors.secondarySilverDark}
          align='center'
          weight='normal'
          margin={10}
          className={css({
            paddingTop: isMobile ? '1px' : '0px',
            paddingBottom: '50px',
          })}
        >
          {data.strapiCannonQuality.bottomDescription}
        </TWParagraph3>

        <Button.RoundedButton
          href={API_URL + props.fileURL}
          // onClick={() => download(props.fileURL)}
          onClick={event => {
            event.preventDefault();
            window.location.href = '/user-manuals';
          }}
          color='primary'
          size='compact'
          style={{padding: '15px'}}
          // startEnhancer={<UserManual className={css({ fill: 'white' })} />}
        >
          Search Manual By Lock Type
        </Button.RoundedButton>
        <Space isMobile={isMobile} />
      </ResponsiveContainer>
    </QualityWrapper>
  );
};

export default SectionMiddleware;