import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { IPLRecomended } from '../../../@types';
import { ResponsiveContainer } from '../../../atoms/Container';
import { TopSafesCard } from '../../../atoms/Cards';
import { cannonStyled, NSH2, TWH4, useCannonStyletron } from '../../../theme';
import { Wrapper } from '../../layout';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import '@brainhubeu/react-carousel/lib/style.css';

const Container = cannonStyled(Wrapper, (props: { isMobile?: boolean }) => ({
  ...(props.isMobile
    ? {
        padding: '0px !important',
        margin: '80px 0',
      }
    : {
        margin: '180px 0',
        paddingRight: '35px',
        paddingLeft: '35px',
        //paddingRight: '0px !important',
        minHeight: 'auto !important',
      }),
  overflowX: 'hidden',
  height: 'auto',
}));

export default (
  props: IPLRecomended & { isMobile?: boolean; isTablet?: boolean }
) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const API_URL = useAPIURL();

  const isSmallFormFactor = useMediaQuery({ query: '(max-width: 695px)' });
  const isMediumFormFactor = useMediaQuery({ query: '(max-width: 1000px)' });

  const filter = isSmallFormFactor ? 2 : isMediumFormFactor ? 3 : 4;
  
  return (
    <Container
      className='pl_showcase_wrapper'
      isMobile={props.isMobile || props.isTablet}
      compact
      noScroll
      isPadded
      autoHeight
      isCentered
      direction='vertical'
    >
      <NSH2
        style={{ textTransform: 'uppercase' }}
        color={theme.cannonColors.primaryBlue}
        align='center'
        weight={900}
      >
        {(props.title || '').toUpperCase()}
      </NSH2>
      <TWH4 color={theme.cannonColors.primaryGray} align='center' weight={400}>
        {props.description}
      </TWH4>
      <ResponsiveContainer
        flexDirection='row'
        bottomLeft
        $style={{
          overflowX: 'clip',
        }}
      >
        {props.compareItems.slice(0, filter).map((item) => (
          <TopSafesCard
            key='Key 1'
            displayImage={API_URL + item.safe.node.displayImage?.url}
            title={item.safe.node.displayName}
            series={item.safe.node.series.title}
            safeCategory={item.safe.node.safeCategory.title.toLowerCase()}
            measures={`
            ${
              !item.safe.node.extDimensions.width
                ? ''
                : `${item.safe.node.extDimensions.width}w x`
            } 
            ${
              !item.safe.node.extDimensions.height
                ? '.'
                : `${item.safe.node.extDimensions.height}h x`
            } 
            ${
              !item.safe.node.extDimensions.depth
                ? ''
                : `${item.safe.node.extDimensions.depth}d`
            } 
            `}
            features={{
              firearmCapacity: item.safe.node.firearmCapacity,
              firewaterProof: item.firewaterProof,
              convertibleInterior:
                item.safe.node.features.convertibleInteriorText,
            }}
            action={() =>
              (window.location.href = `/product/${item.safe.node.slug}`)
            }
          />
        ))}
      </ResponsiveContainer>
    </Container>
  );
};
