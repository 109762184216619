import React from 'react';
import { ImageFullWidth } from '../../../atoms/Image';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import { cannonStyled, useCannonStyletron } from '../../../theme';

const MainFlex = cannonStyled('div', () => ({
  paddingTop: '100px',
  paddingBottom: '100px',
}));

const SectionMiddleware = ({ background }) => {
  const [isMobile] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();
  const API_URL = useAPIURL();

  const backgroundAPI = API_URL + background;

  return (
    <MainFlex>
      <div
        className={css({
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          width: '100%',
          height: isMobile ? '400px' : '800px',
          backgroundSize: 'cover',
          backgroundImage: `url(${backgroundAPI})`,
          backgroundPosition: 'center',
          backgroundAttachment: isMobile ? 'scroll': 'fixed',
        })}
      />
    </MainFlex>
  );
};

export default SectionMiddleware;
