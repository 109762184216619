import React, { useState } from 'react';
import Slider from 'rc-slider';
import ReactModal from 'react-modal';
import {
  cannonStyled,
  TWH4,
  TWParagraph3,
  ABH1,
  TWH5,
  useCannonStyletron,
} from '../../../theme';
import { useTableOrMobile } from '../../../hooks';
import { CannonSafeThemeT } from '../../../theme/CannonSafesTheme';
import { FilterOption, ISafesFilters } from '../../../pages/safes';
import Accordion from './components/Accordion';
import FilterIcon from '../../../assets/filter-white.svg';
import ResetIcon from '../../../assets/reset-arrows.svg';
import CloseIcon from '../../../assets/close.svg';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import './Filters.css';
import useFindYourSafe from '../../../atoms/FindYourSafe/useFindYourSafe';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

interface ISafesFiltersProps extends ISafesFilters {
  setFilters: (key: string, newValue: any) => void;
  resetFilters: () => void;
  typeOptions: Array<FilterOption>;
  bestForOptions: Array<FilterOption>;
  isModal?: boolean;
}

interface IFiltersWrapper {
  isModal: boolean;
}

const FiltersWrapper = cannonStyled('div', (props: IFiltersWrapper) => ({
  maxWidth: props.isModal ? '100%' : '25%',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '70px',
  paddingBottom: props.isModal ? '20px' : '5px',
  width: props.isModal ? '100%' : '300px',
}));

const MinMaxPriceLabel = cannonStyled(
  'label',
  (props: { $theme: CannonSafeThemeT }) => ({
    fontSize: '14px',
    fontFamily: 'Titillium Web',
    color: props.$theme.cannonColors.secondarySilverDark,
  })
);

const HorizontalWrapper = cannonStyled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10px',
});

const ResetButton = cannonStyled(
  'button',
  (props: { $theme: CannonSafeThemeT }) => ({
    background: 'transparent',
    color: props.$theme.cannonColors.primaryBlue,
    fontSize: '14px',
    fontFamily: 'Titillium Web',
    fontWeight: 600,
    width: 'fit-content',
    border: 'none',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
  })
);

const FixedFiltersWrapper = cannonStyled('div', {
  position: 'fixed',
  bottom: '30px',
  left: '50%',
  transform: 'translate(-50%, 0)',
  zIndex: 99999,
});

const ShowFiltersButton = cannonStyled(
  'button',
  (props: { $theme: CannonSafeThemeT }) => ({
    background: props.$theme.cannonColors.secondaryOrange,
    color: 'white',
    borderRadius: '41px',
    padding: '10px 20px',
    border: 'none',
    width: '220px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Titillium Web',
    fontSize: '16px',
  })
);

const CloseModalIcon = cannonStyled(CloseIcon, {
  position: 'absolute',
  top: '20px',
  right: '20px',
});

const FiltersModal = cannonStyled(ReactModal, {});

const fireArmsCapacityOptions = [
  { label: 'Up to 20', value: '0-20' },
  { label: 'Up to 40', value: '21-40' },
  { label: 'More than 41', value: '41-999' },
];

const featuresOptions = [
  { label: 'Waterproof', value: 'waterproof' },
  { label: 'Adjustable shelves', value: 'adjustableShelves' },
  { label: 'Biometric lock', value: 'biometricLocks' },
];

const FilterCheckbox = ({ label, value, checked, onChange }) => (
  <>
    <label style={{ color: '#232323' }} className='checkbox-container'>
      {label}
      <input
        type='checkbox'
        id={value}
        aria-labelledby= {value}
        checked={checked}
        onChange={onChange}
      />{' '}
      <span className='checkmark'></span>
    </label>
  </>
);

const minPriceRange = 100;
const maxPriceRange = 5000;

const FilterInput = ({
  label,
  value,
  onChange,
  borderColor,
  color,
  isModal = false,
}) => (
  <>
    <label>
      Max {label}
      <input
        type='number'
        value={value}
        onChange={onChange}
        style={{
          maxWidth: isModal ? '100%' : '40px',
          border: 'none',
          borderBottom: `1px solid ${borderColor}`,
          background: 'transparent',
          MozAppearance: 'textfield',
          marginRight: '10px',
          marginLeft: '10px',
          textAlign: 'center',
          fontWeight: 'bold',
          color,
        }}
      />{' '}
      in
    </label>
    <br />
  </>
);

const SafesFiltersForm: React.FC<ISafesFiltersProps> = ({
  isModal = false,
  ...data
}) => {
  const [css, theme] = useCannonStyletron();
  const [rangeValues, setRangeValues] = useState([
    data.bottomPrice,
    data.topPrice,
  ]);

  const handleChangeArrayFilter = (value, checked, array) => {
    const newArray = checked
      ? [...data[array], value]
      : data[array].filter((e) => e !== value);
    data.setFilters(array, newArray);
  };

  const handleAfterChangePriceRange = (newValues) => {
    const [newBottomPrice, newTopPrice] = newValues;
    if (newBottomPrice !== data.bottomPrice) {
      data.setFilters('bottomPrice', newBottomPrice);
    }
    if (newTopPrice !== data.topPrice) {
      data.setFilters('topPrice', newTopPrice);
    }
  };

  const handleChangePriceRange = (newValues) => {
    setRangeValues(newValues);
  };

  const handleReset = () => {
    data.resetFilters();
    setRangeValues([minPriceRange, maxPriceRange]);
  };

  return (
    <FiltersWrapper isModal={isModal}>
      <TWH4
        isMobile={isModal}
        color={theme.cannonColors.primaryBlue}
        weight={700}
        align='left'
        $style={{ marginBottom: '20px' }}
      >
        Filter your Safe
      </TWH4>
      {/* <HorizontalWrapper>
        <ABH1
          isMobile={isModal}
          weight={700}
          color={theme.cannonColors.primaryBlue}
          align='left'
        >
          By price
        </ABH1>
        <TWH5
          isMobile={isModal}
          weight={400}
          color={theme.cannonColors.primaryBlue}
          align='right'
        >
          ${data.bottomPrice} - ${data.topPrice}
        </TWH5>
      </HorizontalWrapper> 
      <HorizontalWrapper>
        <MinMaxPriceLabel style={{ marginRight: '10px' }}>
          $100
        </MinMaxPriceLabel>
        <Range
          min={minPriceRange}
          max={maxPriceRange}
          value={rangeValues}
          tipFormatter={(value) => `$${value}`}
          onChange={handleChangePriceRange}
          onAfterChange={handleAfterChangePriceRange}
          ariaLabelGroupForHandles={['minimum', 'maximum']}
        />
        <MinMaxPriceLabel style={{ marginLeft: '10px' }}>
          $5,000
        </MinMaxPriceLabel>
      </HorizontalWrapper>
      <TWParagraph3
        weight={400}
        align='left'
        margin={0}
        color={theme.cannonColors.secondarySilver}
        $style={{ marginBottom: '10px' }}
      >
        *Average pricing. Retailers price may vary
      </TWParagraph3>*/}
      <Accordion
        blueIcon={!isModal}
        title='Type'
        initiallyOpened={data.typeOptions.length > 0}
      >
        {data.typeOptions.map((opt) => (
          <FilterCheckbox
            label={opt.title}
            value={opt.title}
            checked={data.type.includes(opt.title)}
            onChange={(e) =>
              handleChangeArrayFilter(opt.title, e.target.checked, 'type')
            }
          />
        ))}
      </Accordion>

      <Accordion
        blueIcon={!isModal}
        title='Firearms capacity'
        initiallyOpened={data.fireArmsCapacity.length > 0}
      >
        {fireArmsCapacityOptions.map((opt) => (
          <FilterCheckbox
            label={opt.label}
            value={opt.value}
            checked={data.fireArmsCapacity.includes(opt.value)}
            onChange={(e) =>
              handleChangeArrayFilter(
                opt.value,
                e.target.checked,
                'fireArmsCapacity'
              )
            }
          />
        ))}
      </Accordion>

      <Accordion
        blueIcon={!isModal}
        title='Features'
        initiallyOpened={data.features.length > 0}
      >
        {featuresOptions.map((opt) => (
          <FilterCheckbox
            label={opt.label}
            value={opt.value}
            checked={data.features.includes(opt.value)}
            onChange={(e) =>
              handleChangeArrayFilter(opt.value, e.target.checked, 'features')
            }
          />
        ))}
      </Accordion>

      <Accordion
        blueIcon={!isModal}
        title='Best for'
        initiallyOpened={data.bestFor.length > 0}
      >
        {data.bestForOptions.map((opt) => (
          <FilterCheckbox
            label={opt.title}
            value={opt.title}
            checked={data.bestFor.includes(opt.title)}
            onChange={(e) =>
              handleChangeArrayFilter(opt.title, e.target.checked, 'bestFor')
            }
          />
        ))}
      </Accordion>

      <Accordion blueIcon={!isModal} title='Dimensions' initiallyOpened={false}>
        <FilterInput
          isModal={isModal}
          label='height'
          value={data.maxHeight}
          color={theme.cannonColors.primaryBlue}
          borderColor={theme.cannonColors.secondaryOrange}
          onChange={({ target: { value } }) =>
            data.setFilters('maxHeight', value)
          }
        />
        <FilterInput
          isModal={isModal}
          label='width'
          value={data.maxWidth}
          color={theme.cannonColors.primaryBlue}
          borderColor={theme.cannonColors.secondaryOrange}
          onChange={({ target: { value } }) =>
            data.setFilters('maxWidth', value)
          }
        />
        <FilterInput
          isModal={isModal}
          label='depth'
          value={data.maxDepth}
          color={theme.cannonColors.primaryBlue}
          borderColor={theme.cannonColors.secondaryOrange}
          onChange={({ target: { value } }) =>
            data.setFilters('maxDepth', value)
          }
        />
      </Accordion>
      <ResetButton onClick={handleReset}>
        <ResetIcon
          style={{
            marginRight: '10px',
            stroke: isModal
              ? theme.cannonColors.primaryWhite
              : theme.cannonColors.primaryBlue,
          }}
        />{' '}
        Reset Filters
      </ResetButton>
    </FiltersWrapper>
  );
};

const SafesFilters: React.FC<ISafesFiltersProps> = (data) => {
  const [isMobile] = useTableOrMobile();
  const [modalOpen, setModalOpen] = useState(false);
  const { openedOverlay } = useFindYourSafe();

  const closeModal = () => setModalOpen(false);

  return isMobile ? (
    <FixedFiltersWrapper>
      <FiltersModal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        className='filters-modal'
        overlayClassName='React-Modal-Overlay'
      >
        <CloseModalIcon onClick={closeModal} />
        <SafesFiltersForm {...data} isModal />
      </FiltersModal>
      {!openedOverlay && (
        <ShowFiltersButton onClick={() => setModalOpen(!modalOpen)}>
          {modalOpen ? 'Apply filters' : 'Filter results'}{' '}
          <FilterIcon style={{ marginLeft: '10px' }} />
        </ShowFiltersButton>
      )}
    </FixedFiltersWrapper>
  ) : (
    <SafesFiltersForm {...data} />
  );
};

export default SafesFilters;