import React, { useState, useEffect } from 'react';

import { IProductLinesLanding } from '../../../@types';
import { ImageFullWidth } from '../../../atoms/Image';
import { useOrientation, useAPIURL } from '../../../hooks';
import { PBH1, TWH4, useCannonStyletron, cannonStyled } from '../../../theme';
import { useMediaQuery } from 'react-responsive';

const TextPosition = cannonStyled('div', (props: { posY: string }) => ({
  position: 'relative',
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  maxWidth: '1300px',
  marginTop: props.posY,
}));

const ParallaxBanner = (data: IProductLinesLanding) => {
  const [css, theme] = useCannonStyletron();
  const { isRotated } = useOrientation();
  const API_URL = useAPIURL();

  const isLargeSize = useMediaQuery({ query: '(min-width: 850px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  const isSmallHeight = useMediaQuery({ query: '(max-height: 420px)' });
  const isMediumHeight = useMediaQuery({ query: '(max-height: 650px)' });
  const isBigHeight = useMediaQuery({ query: '(max-height: 799px)' });
  const isXLHeight = useMediaQuery({ query: '(min-height: 801px)' });
//[`min(3vh, 6vh)`, `min(56px, 68px)`, `min(30px, 50px)`]
  const mainText = isMobile && isSmallHeight // Small Smartphones vertical
      ? ['', '2.5vw', '', '']
      : isMobile && isMediumHeight
      ? ['9vh', '9vw', '', '4.8vw']
      : isMobile && isBigHeight
      ? ['2vh', '9vw', '', '6.5vw']
      : isMobile && isXLHeight
      ? ['13vh', '11vw', '', '4.5vw']
      : !isMobile && isSmallHeight // Small Smartphones rotated
      ? ['3vh', '3.5vw', '', '2.2vw']
      : !isMobile && isMediumHeight //Square monitor
      ? ['7.5vh', '7vw', '7.5vw', '2.5vw']
      : !isMobile && isBigHeight //MacBook Pro M1, tablet not-rotateds
      ? ['7vh', '7vw', '7vw', '2vw']
      : ['8vh', '6vw', '6vw', '2.3vw']; // 600x800

  return (
    <ImageFullWidth
      bg={
        API_URL + data.strapiProductLinesLanding?.banner?.backgroundImage?.url
      }
      safes={API_URL + data.strapiProductLinesLanding?.banner?.frontImage?.url}
    >
      <TextPosition posY={mainText[0]}>
        <PBH1
          align='center'
          color='#FFF'
          weight={900}
          $style={{
            fontSize: mainText[1],
            lineHeight: mainText[2],
          }}
        >
          {data.strapiProductLinesLanding.banner.title}
        </PBH1>

        <TWH4
          align='center'
          color='#FFF'
          weight={200}
          $style={{
            fontSize: mainText[3],
            margin: isLargeSize ? 'auto auto' : 'auto 30px',
          }}
        >
          {data.strapiProductLinesLanding.banner.description}
        </TWH4>
      </TextPosition>
    </ImageFullWidth>
  );
};

export default ParallaxBanner;
