import React, { useEffect, useState, useRef } from 'react';
import { IPLFits } from '../../../@types';
import { Button } from '../../../atoms';
import { IThemeProp } from '../../../atoms/Cards/CapabilityCard';
import { SafesCarousel } from '../../../atoms/Cards';
import { useMediaQuery } from 'react-responsive';
import useFindYourSafe from '../../../atoms/FindYourSafe/useFindYourSafe';

import Carousel, {
  arrowsPlugin,
  slidesToShowPlugin,
  slidesToScrollPlugin,
} from '@brainhubeu/react-carousel';

import { useAPIURL, useTableOrMobile } from '../../../hooks';

import {
  cannonStyled,
  NSH3,
  TWH4,
  TWH5,
  ABH1,
  useCannonStyletron,
} from '../../../theme';

import ArrowLeft from '../../../assets/arrow-left.svg';
import ArrowRight from '../../../assets/arrow-right.svg';
import '@brainhubeu/react-carousel/lib/style.css';

const Grid = cannonStyled(
  'div',
  (props: IThemeProp & { isMobile?: boolean; isMinHeight?: boolean }) => ({
    display: 'grid',
    gridTemplateColumns: props.isMobile ? '1fr' : 'auto 46%',
    gridTemplateRows: props.isMobile
      ? props.isMinHeight
        ? `auto calc(100vh - 73px)`
        : 'auto 400px'
      : '650px',
    gridTemplateAreas: props.isMobile
      ? `
    'info'
    'carousel'
  `
      : `
    'carousel info'
  `,
    margin: 0,
    backgroundColor: props.$theme.cannonColors.primaryWhite,
  })
);

const InfoContainer = cannonStyled(
  'div',
  (props: IThemeProp & { isMobile?: boolean }) => ({
    gridArea: 'info',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    ...(props.isMobile
      ? {
          padding: '10%',
        }
      : {
          paddingLeft: '12%',
          paddingRight: '10%',
        }),
  })
);

const CarouselContainer = cannonStyled(
  'div',
  (props: { bgImage?: string }) => ({
    gridArea: 'carousel',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    margin: 0,
    padding: 0,
    position: 'relative',
    overflowX: 'hidden',
    maxWidth: '1380px',
    backgroundImage: `url(${props.bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
  })
);

const ImageOverlayContainer = cannonStyled(
  'div',
  (props: { frontSrc?: string; frontImgYPosition?: string }) => ({
    zIndex: 2,
    bottom: 0,
    position: 'absolute',
    padding: 0,
    margin: 0,
    width: '100%',
    maxWidth: '1380px',
    overflow: 'hidden',
    height: '100%',
    backgroundImage: `url(${props.frontSrc})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: `50% ${props.frontImgYPosition}`,
    backgroundOrigin: 'padding-box',
  })
);

const CarouselCardContainer = cannonStyled('div', {
  width: '80%',
  height: '100%',
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
});

const CarouselElements = cannonStyled('div', {
  display: 'flex',
  alignItems: 'flex-end',
  width: '100%',
  height: '100%',
});

export default (
  props: IPLFits & { isMobile?: boolean; isTablet?: boolean }
) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const API_URL = useAPIURL();
  // Find your safe quiz overlay
  const { toggleOverlay } = useFindYourSafe();

  // Re render carousel card everytime a component changes
  const [carousel, setCarousel] = useState([]);
  useEffect(() => {
    let elements = props.safesCarousel.map((carouselItem) => {
      const imageToDisplay = (
        carouselItem.cannon_safe?.node?.detailImages || []
      ).find((item: { type: string }) => item.type == 'front') || {
        image: {},
      };

      if (imageToDisplay.image?.url == undefined) {
        imageToDisplay.image = props.dummyImg;
      }

      const { image = {} as { url: string } } = imageToDisplay;
      const { url = '' } = image;

      return (
        <SafesCarousel
          id={carouselItem.cannon_safe?.node?.slug}
          displayImage={API_URL + url}
          title={carouselItem.cannon_safe?.node?.displayName || ''}
          slug={carouselItem.cannon_safe?.node?.slug}
          subTitle={carouselItem.cannon_safe?.node?.series.title}
          measures={`
          ${
            !carouselItem.cannon_safe?.node?.extDimensions.width
              ? ''
              : `${carouselItem.cannon_safe?.node?.extDimensions.width}w x`
          } 
          ${
            !carouselItem.cannon_safe?.node?.extDimensions.height
              ? '.'
              : `${carouselItem.cannon_safe?.node?.extDimensions.height}h x`
          } 
          ${
            !carouselItem.cannon_safe?.node?.extDimensions.depth
              ? ''
              : `${carouselItem.cannon_safe?.node?.extDimensions.depth}d`
          } 
          `}
          action={() =>
            (window.location.href = `/product/${carouselItem.cannon_safe?.node?.slug}`)
          }
        />
      );
    });
    setCarousel(elements);
  }, []);

  //[FrontImagePosition, heightGrid]
  const isSmallHeight = useMediaQuery({ query: '(max-height: 450px)' });
  const isXlHeight = useMediaQuery({ query: '(min-height: 800px)' });
  const minHeight = useMediaQuery({ query: '(max-height: 465px)' });

  let posAndHeight: string = isSmallHeight ? '5%' : isXlHeight ? '100%' : '10%';

  if (!props.enabled) return null;
  return (
    <Grid
      className='pl_showcase_grid'
      isMobile={isMobile}
      isMinHeight={minHeight}
    >
      <InfoContainer isMobile={isMobile}>
        <div>
          <TWH4
            color={theme.cannonColors.secondarySilver}
            align='left'
            weight={700}
          >
            {props.subtitle}
          </TWH4>
          <NSH3
            color={theme.cannonColors.primaryBlueDark}
            align='left'
            weight={900}
            margin={15}
          >
            {props.title}
          </NSH3>
          <TWH5
            color={theme.cannonColors.secondarySilverDark}
            align='left'
            weight={400}
            margin={20}
          >
            {props.description}
          </TWH5>
          <Button.RoundedButton
            color='primary'
            size='compact'
            margin={15}
            endEnhancer={<ArrowRight style={{ stroke: 'white' }} />}
            onClick={toggleOverlay}
          >
            {props.action}
          </Button.RoundedButton>
        </div>
      </InfoContainer>

      <CarouselContainer bgImage={API_URL + props.backgroundImage?.url}>
        <ImageOverlayContainer
          frontSrc={API_URL + props.frontImage?.url}
          frontImgYPosition={posAndHeight}
        ></ImageOverlayContainer>
        <CarouselElements>
          <CarouselCardContainer>
            <Carousel
              plugins={[
                {
                  resolve: arrowsPlugin,
                  options: {
                    arrowLeft: (
                      <Button.IconButton
                        aria-label='Previous'
                        $style={{
                          backgroundColor: theme.cannonColors.primaryBlueLight,
                          borderRadius: '4px',
                          zIndex: 2,
                          width: isMobile ? '21px' : '30px',
                          height: isMobile ? '21px' : '30px',
                        }}
                      >
                        <ArrowLeft style={{ stroke: 'white', width: '80%' }} />
                      </Button.IconButton>
                    ),
                    arrowLeftDisabled: (
                      <Button.IconButton
                        aria-label='Previous'
                        $style={{
                          backgroundColor: theme.cannonColors.primaryBlueLight,
                          borderRadius: '4px',
                          zIndex: 2,
                          width: isMobile ? '21px' : '30px',
                          height: isMobile ? '21px' : '30px',
                        }}
                      >
                        <ArrowLeft style={{ stroke: 'white', width: '80%' }} />
                      </Button.IconButton>
                    ),
                    arrowRight: (
                      <Button.IconButton
                        aria-label='Next'
                        $style={{
                          backgroundColor: theme.cannonColors.primaryBlueLight,
                          borderRadius: '4px',
                          zIndex: 2,
                          width: isMobile ? '21px' : '30px',
                          height: isMobile ? '21px' : '30px',
                        }}
                      >
                        <ArrowRight style={{ stroke: 'white', width: '80%' }} />
                      </Button.IconButton>
                    ),
                    arrowRightDisabled: (
                      <Button.IconButton
                        aria-label='Next'
                        $style={{
                          backgroundColor: theme.cannonColors.primaryBlueLight,
                          borderRadius: '4px',
                          zIndex: 2,
                          width: isMobile ? '21px' : '30px',
                          height: isMobile ? '21px' : '30px',
                        }}
                      >
                        <ArrowRight style={{ stroke: 'white', width: '80%' }} />
                      </Button.IconButton>
                    ),
                    addArrowClickHandler: true,
                  },
                },
                'infinite',
                'fastSwipe',
                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: 1,
                  },
                },
                {
                  resolve: slidesToScrollPlugin,
                  options: {
                    numberOfSlides: 1,
                  },
                },
              ]}
            >
              {carousel}
            </Carousel>
          </CarouselCardContainer>
        </CarouselElements>
      </CarouselContainer>
    </Grid>
  );
};
