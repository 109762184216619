import React, { useEffect, useState } from 'react';
import { useAPIURL } from '../../../hooks';
import { cannonStyled, TWH5, useCannonStyletron } from '../../../theme';
import { CannonSafeThemeT } from '../../../theme/CannonSafesTheme';
import scrollTo from 'gatsby-plugin-smoothscroll';
import FAQActiveCategory from './components/FAQActiveCategory';

interface IFAQsprops {
  data: IFAQ;
  isExpanded?: boolean;
  sentence: boolean;
  buttonSelected: string;
}

export const FlexCategories = cannonStyled('div', () => ({
  width: '90%',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  top: '450px',
}));

export const AccordionFlex = cannonStyled('div', () => ({
  width: '80%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: '10%',
}));

export const WhiteSquaredButtonStyle = cannonStyled(
  'div',
  (props: {
    active: boolean;
    marginRight: boolean;
    $theme?: CannonSafeThemeT;
    sentence?: boolean;
  }) => ({
    marginRight: props.marginRight ? 0 : '2vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '184px',
    width: '172px',
    borderRadius: '10px',
    border: '1px solid #F4F6FB',
    boxSizing: 'border-box',
    boxShadow: `0px 14px 26px rgba(3, 64, 120, 0.06)`,
    paddingTop: '24px',
    paddingBottom: '24px',
    paddingLeft: '15px',
    paddingRight: '15px',
    background: props.sentence
      ? props.$theme.cannonColors.primaryWhiteLight
      : props.active
      ? props.$theme.cannonColors.primaryBlue
      : props.$theme.cannonColors.primaryWhiteLight,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: props.active
        ? 'none'
        : props.$theme.cannonColors.primaryWhite,
    },
  })
);

const SectionMiddleWare = (props: IFAQsprops) => {
  const API_URL = useAPIURL();
  const [css, theme] = useCannonStyletron();

  //State for color changing
  const [active, setActive] = useState('');

  //State for text 'back to categories'
  const [activeText, setActiveText] = useState(false);

  //Constant for array length of Faq Sections
  const arrayFaqSection = props.data.strapiFaq.faqSection;

  //Query setter for active Category
  const handleChange = (item) => {
    if (item === active) {
      setActive('');
      setActiveText(false);
      scrollTo('#top');
    } else {
      setActive(item);
      setActiveText(true);
      scrollTo('#quest');
    }
  };

  useEffect(()=> {
    setActive(props.buttonSelected);
    scrollTo('#quest');
  }, [props.buttonSelected]);

  //Constant for MarginRight
  function arrayMarginRight(index) {
    if (index != arrayFaqSection.length - 1) {
      return false;
    } else {
      return true;
    }
  };

  //Select active Category
  const articleCategory =
    active === ''
      ? ''
      : arrayFaqSection.find((category) => category.title === active);

  return (
    <React.Fragment>
      <FlexCategories id='categories'>
        {props.data.strapiFaq.faqSection.map((item, index) => {
          //Icon constants for active state
          const iconNormal = API_URL + item?.iconButton?.url;
          const iconActive = API_URL + item?.iconActive?.url;

          return (
            <React.Fragment key={item.id}>
              <WhiteSquaredButtonStyle
                onClick={() => handleChange(item.title)}
                active={active === item.title}
                marginRight={arrayMarginRight(index)}
              >
                <img
                  style={{ width: '60px', height: '60px' }}
                  src={active === item.title ? iconActive : iconNormal}
                  alt={item.title}
                />
                <TWH5
                  key={item.title}
                  color={
                    active === item.title
                      ? theme.cannonColors.primaryWhiteLight
                      : theme.cannonColors.primaryBlue
                  }
                  weight={400}
                  align={'center'}
                >
                  {item.title}
                </TWH5>
              </WhiteSquaredButtonStyle>
            </React.Fragment>
          );
        })}
      </FlexCategories>
      {props.sentence && (
        <AccordionFlex>
          <FAQActiveCategory activeText={activeText} data={articleCategory} />
        </AccordionFlex>
      )}
    </React.Fragment>
  );
};

export default SectionMiddleWare;
