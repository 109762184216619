import React from 'react';
import { CannonSafeThemeT, cannonStyled, useCannonStyletron } from '../../theme/CannonSafesTheme';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useTableOrMobile } from '../../hooks';
import { TWH5 } from '../../theme';

interface MarkDownProps {
  color?: string;
  isMobile?: boolean;
  margin?: number;
  $theme?: CannonSafeThemeT;
  textSize?: string;
}

const TWUL = cannonStyled('ul', (props: MarkDownProps) => ({
  fontFamily: 'Titillium Web',
  fontSize: `${props.textSize}`,
  fontWeight: 400,
  color: props.$theme.cannonColors.secondarySilver,
  listStyleType: 'square',
  textAlign: 'left',
}));

const TWLI = cannonStyled('li', (props: MarkDownProps) => ({
  fontWeight: 400,
  fontSize: `${props.textSize}`,
}));

const BoldText = cannonStyled('strong', (props: MarkDownProps) => ({
  fontWeight: 600,
  fontSize: `${props.textSize}`,
}));

const Heading = cannonStyled('p', (props: { fontSize: string }) => ({
  fontFamily: 'Noto Serif',
  fontWeight: 900,
  fontSize: props.fontSize,
  textAlign: 'left',
  marginBottom: '15px'
}));

const TWA = cannonStyled('a', (props: MarkDownProps) => ({
  fontWeight: 700,
  fontSize: `${props.textSize}`,
  color: props.$theme.cannonColors.secondaryOrange,
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline',
  },
}));

const ImgElement = cannonStyled('img', {
  paddingLeft: '10px',
  maxWidth: `min(calc(100vw - 48px - 10px), 300px)`,
  display: 'inline-block',
  whiteSpace: 'nowrap',
});

const BlogMainText = (props: { mainText: string }) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();

  const textSize = 'min(4vw, 20px)';
  
  return (
    <div style={{ textAlign: 'left'}}>
    <ReactMarkdown
      components={{
        p: ({ node, ...props }) => (
          <TWH5
            color={theme.cannonColors.secondarySilverDark}
            margin={0}
            align='left'
            weight={400}
            $style={{ 
              fontSize: `${textSize}`,
              marginBottom: '30px',
             }}
            {...props}
          />
            
        ),
        h1: ({ node, ...props }) => <Heading fontSize='56px' {...props} />,
        h2: ({ node, ...props }) => <Heading fontSize='50px' {...props} />,
        h3: ({ node, ...props }) => <Heading fontSize='44px' {...props} />,
        h4: ({ node, ...props }) => <Heading fontSize='36px' {...props} />,
        h5: ({ node, ...props }) => <Heading fontSize='28px' {...props} />,
        h6: ({ node, ...props }) => <Heading fontSize='22px' {...props} />,
        ul: ({ node, ...props }) => <TWUL textSize={textSize} isMobile={isMobile} {...props} />,
        li: ({ node, ...props }) => <TWLI textSize={textSize} {...props} />,
        a: ({ node, ...props }) => <TWA textSize={textSize} {...props} />,
        img: ({ node, ...props }) => <ImgElement {...props} />,
        strong: ({ node, ...props }) => <BoldText textSize={textSize} {...props} />,
      }}
      rehypePlugins={[rehypeRaw]}
    >
      {props.mainText}
    </ReactMarkdown>
    </div>
  );
};

export default BlogMainText;