import { result } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { glossary } from '../constants';
import { IFAQQuestion } from '../pages/faq';

function sortAnyArray(a, b) {
  return a > b ? 1 : a === b ? 0 : -1;
}
function sortIntArray(a, b) {
  return ((a | 0) - (b | 0)) | 0;
}
function fastFilter(array, handle) {
  var out = [],
    value = 0;
  for (var i = 0, len = array.length | 0; i < len; i = (i + 1) | 0)
    if (handle((value = array[i]))) out.push(value);
  return out;
}

/* USAGE:
  filterArrayByAnotherArray(
      [1,3,5],
      [2,3,4]
  ) yields [1, 5], and it can work with strings too
*/
export default function filterArrayByAnotherArray(searchArray, filterArray) {
  if (
    // NOTE: This does not check the whole array. But, if you know
    //        that there are only strings or numbers (not a mix of
    //        both) in the array, then this is a safe assumption.
    typeof searchArray[0] == 'number' &&
    typeof filterArray[0] == 'number' &&
    (searchArray[0] | 0) === searchArray[0] &&
    (filterArray[0] | 0) === filterArray[0]
  ) {
    // if all entries in both arrays are integers
    searchArray.sort(sortIntArray);
    filterArray.sort(sortIntArray);
  } else {
    searchArray.sort(sortAnyArray);
    filterArray.sort(sortAnyArray);
  }
  // Progressive Linear Search
  var i = 0;
  return fastFilter(searchArray, function (currentValue) {
    while (filterArray[i] < currentValue) i = (i + 1) | 0;
    // +undefined = NaN, which is always false for <, avoiding an infinite loop
    return filterArray[i] !== currentValue;
  });
}

// Function for tokenize Array of Questions
export function tokenizeQuestions(questions: IFAQQuestion[]) {
  return questions.map((question) => ({
    data: question,
    tokens: question.question.split(' '),
  }));
}

//Tokenize sentence
export function tokenize(val: string) {
  const lowerCaseArray = val.toLocaleLowerCase();
  const spacelessArray = lowerCaseArray.split(' ');
  return filterArrayByAnotherArray(spacelessArray, glossary);
}

// Find minimum number operations to convert str1 to str2
function min(x, y, z) {
  if (x <= y && x <= z) return x;
  if (y <= x && y <= z) return y;
  else return z;
}

//Function for checking spelling mistakes
function editDist(str1: string, str2: string, m: number, n: number): number {
  // If first string is empty, the only option is to insert all characters of second string into first
  if (m == 0) return n;

  // If second string is empty, the only option is to remove all characters of first string
  if (n == 0) return m;

  // If last characters of two strings are same, nothing much to do. Ignore last characters and get count for remaining strings.
  if (str1[m - 1] == str2[n - 1]) return editDist(str1, str2, m - 1, n - 1);

  // If last characters are not same, consider all three operations on last character of first
  // string, recursively compute minimum cost for all three operations and take minimum of three
  // values.
  return (
    1 +
    min(
      editDist(str1, str2, m, n - 1), // Insert
      editDist(str1, str2, m - 1, n), // Remove
      editDist(str1, str2, m - 1, n - 1) // Replace
    )
  );
}

export function evaluateQuestion(
  question: string[],
  search: string[]
): boolean {
  let coincidences = 0;

  const questionParameter = question.join(' ');

  search.forEach((searchWord) => {
    if (questionParameter.includes(searchWord)) {
      coincidences++;
    }
  });

  return coincidences > 0;
}

interface IQuestion {
  id: string;
  question: string;
  answerBody: string;
  mediaURL: string;
}

export const useQuestionSearch = (
  sentence: string,
  questions: IFAQQuestion[]
): IQuestion[] => {
  const [response, setResponse] = useState<IQuestion[]>([]);
  const [maxSearchLength, setMaxSearchLength] = useState(0);
  const timeoutRef = useRef<any>();
  useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      // const tokenizedQuestions = tokenizeQuestions(questions);
      // const tokenizedSearch = tokenize(sentence);
      let result: IQuestion[] = [];
      questions.forEach((question) => {
        if (
          evaluateQuestion(
            question.question
              .toLowerCase()
              // .replace(/[^\p{L}\s]/gu, '')
              .split(' '),
            sentence.toLowerCase().split(' ')
          )
        ) {
          result.push(question);
        }
      });
      setResponse(result);
    }, 100);
  }, [sentence]);

  return response;
};

// const searchResult: IFAQQuestion[] = useQuestionSearch(
//   sentence,
//   questionsData
// );
