import React from 'react';
import {
  TWButtonMini,
  TWH5,
  cannonStyled,
  useCannonStyletron,
} from '../../../../theme';
import { IFileImage } from '../../../../@types';
import { useAPIURL, useTableOrMobile } from '../../../../hooks';
import VideoRounded from './VideoRounded';

const ColumnContainer = cannonStyled(
  'div',
  ({ isMobile }: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: isMobile ? '100%' : '48%',
    height: 'auto',
  })
);

const VideoContainer = cannonStyled('div', () => ({
  width: '100%',
  height: '320px',
  borderRadius: '15px',
}));

const TextContainer = cannonStyled('div', () => ({
  marginTop: '13px',
  marginLeft: '5px',
  marginBottom: '40px',
}));

const VideoCard = (props: any) => {
  const [css, theme] = useCannonStyletron();
  const API_URL = useAPIURL();
  const [isMobile] = useTableOrMobile();

  const {
    description = '',
    media = {} as IFileImage,
    url = '',
    videoPreviewImage = {} as IFileImage,
  } = props.videoData;

  const { url: videoPreviewImageUrl = '' } =
    videoPreviewImage || ({} as IFileImage);
  const mediaUrl =
    url?.length > 0
      ? url
      : media?.url?.length > 0
      ? `${API_URL}${media.url}`
      : null;

  return (
    <ColumnContainer isMobile={isMobile}>
      <VideoContainer>
        <VideoRounded
          videoPreview={videoPreviewImageUrl}
          src={mediaUrl || ''}
          alt={props.category}
          ext={media?.ext || ''}
        />
      </VideoContainer>
      <TextContainer>
        <TWButtonMini
          color={theme.cannonColors.primaryBlue}
          align='left'
          weight={700}
        >
          {props.category}
        </TWButtonMini>
        <TWH5
          color={theme.cannonColors.primarySolidBlack}
          align='left'
          weight={400}
        >
          {description}
        </TWH5>
      </TextContainer>
    </ColumnContainer>
  );
};

export default VideoCard;
