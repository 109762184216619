import React from 'react';
import { cannonStyled, NSH3, TWH5, useCannonStyletron } from '../../../theme';
import { GridContainer } from '../../../atoms/Container';
import { ICannonSafe } from '../../../pages';
import { SafeCard } from '../../../atoms/Cards';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import { ICannonElMobile } from '../../../@types';

interface ISafesResultsProps {
  safes: Array<ICannonSafe>;
  recommendations: Array<ICannonSafe>;
}

const ResultsWrapper = cannonStyled('div', (props: ICannonElMobile) => ({
  width: props.$isMobile ? '100%' : '70%',
  maxWidth: props.$isMobile ? '100%' : '70%',
  marginLeft: 'auto',
  marginRight: 'auto',
}));

const SafesResults: React.FC<ISafesResultsProps> = (data) => {
  const [isMobile, isTablet] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();
  const API_URL = useAPIURL();

  const mapSafes = (cannonSafe: ICannonSafe) => (
    <SafeCard
      key={cannonSafe.displayName}
      displayImage={API_URL + cannonSafe.displayImage?.url}
      title={cannonSafe.displayName}
      slug={cannonSafe.slug}
      showSubtitle
      subTitle={cannonSafe.series.title !== null ? `${cannonSafe.series.title} Series` : 'Series'}
      price={cannonSafe.averagePrice}
      showPrice
      description={cannonSafe.safeCategory.title}
      reviews={cannonSafe.reviews}
      action={() => (window.location.href = `/product/${cannonSafe.slug}`)}
      shortButton={!isMobile}
    />
  );

  return (
    <ResultsWrapper $isMobile={isMobile}>
      {data.safes.length === 0 && (
        <>
          <NSH3
            color={theme.cannonColors.primaryBlue}
            weight={900}
            align='center'
            $style={{
              marginBottom: '8px',
              marginTop: isMobile ? '40px' : '70px',
            }}
          >
            Maybe you're looking for...
          </NSH3>
          <TWH5
            color={theme.cannonColors.primaryGray}
            weight={400}
            align='center'
          >
            There are no matches for your criteria, but this are our
            recommendations for you
          </TWH5>
        </>
      )}
      <GridContainer
        isMobile={isMobile}
        isTablet={isTablet}
        defaultItems={3}
        spacing='15px'
        direction='horizontal'
        width='100%'
        $style={{ marginTop: '20px' }}
      >
        {data.safes.length > 0
          ? data.safes.map(mapSafes)
          : data.recommendations.map(mapSafes)}
      </GridContainer>
    </ResultsWrapper>
  );
};

export default SafesResults;
