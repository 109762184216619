import React from 'react';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import {
  ABH1,
  cannonStyled,
  NSH3,
  TWH4,
  useCannonStyletron,
} from '../../../theme';
import MediaComponent from '../OurLegacyStory/MediaComponent';

interface IPropsOurPeople {
  title: string;
  subtitle: string;
  textBody: string;
  url: string;
  ext: string;
}

const MainFlex = cannonStyled('div', (props: { isMobile: boolean }) => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  paddingTop: props.isMobile ? '5%' : 'none',
}));

const TextFlex = cannonStyled('div', (props: { isMobile: boolean }) => ({
  width: props.isMobile ? '100%' : '50%',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: props.isMobile ? '30px' : 'none',
}));

const ContainerFlex = cannonStyled('div', (props: { isMobile: boolean }) => ({
  width: '80%',
  height: 'auto',
  display: 'flex',
  flexDirection: props.isMobile ? 'column' : 'row',
  marginTop: props.isMobile ? '10px' : '10%',
  marginLeft: '10%',
  marginBottom: props.isMobile ? '10px' : '10%',
  marginRight: '10%',
  justifyContent: 'center',
  alignItems: 'center',
}));

const SectionMiddleware = (props: IPropsOurPeople) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const API_URL = useAPIURL();

  return (
    <MainFlex isMobile={isMobile}>
      <ContainerFlex isMobile={isMobile}>
        <TextFlex isMobile={isMobile}>
          <TWH4
            color={theme.cannonColors.secondarySilver}
            weight={700}
            align={'left'}
            className={css({
              paddingBottom: isMobile ? '10px' : '18px',
              fontSize: `min(3.2vw, 20px)`,
            })}
          >
            {props.title}
          </TWH4>
          <NSH3
            color={theme.cannonColors.primaryBlue}
            weight={900}
            align={'left'}
            className={css({ paddingBottom: isMobile ? '10px' : '25px' })}
          >
            {props.subtitle}
          </NSH3>
          <ABH1
            color={theme.cannonColors.secondarySilverDark}
            weight={400}
            align={'justify'}
            $style={{
              width: isMobile ? '100%' : '31vw',
              fontSize: `min(4vw, 16px)`,
            }}
          >
            {props.textBody}
          </ABH1>
        </TextFlex>
        <div
          className={css({
            width: isMobile ? '100%' : '44vw',
            height: 'auto',
            objectFit: 'cover',
            marginBottom: isMobile ? '50px' : 'none',
            paddingTop: '5%',
          })}
        >
          <MediaComponent
            media={{ url: props.url, ext: props.ext }}
            isBorder={true}
            $label={props.title}
          />
        </div>
      </ContainerFlex>
    </MainFlex>
  );
};

export default SectionMiddleware;
