import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { IFAQSection } from '../../../../pages/faq';
import {
  cannonStyled,
  TWH3,
  TWH5,
  useCannonStyletron,
} from '../../../../theme';
import StyledArrow from '../../../../assets/arrow.svg';
import FAQAccordion from './FAQAccordion';

//Interface
interface IProps {
  data: IFAQSection;
  activeText: boolean;
}

//Styled Components
const MainFlex = cannonStyled('div', () => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
}));

const Flex = cannonStyled('div', () => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '64px',
  paddingBottom: '100px',
  cursor: 'pointer',
}));

const SectionMiddleWare = (props: IProps) => {
  //Hooks
  const [css, theme] = useCannonStyletron();

  return (
    <React.Fragment>
      <MainFlex>
        <TWH3
          id='quest'
          color={theme.cannonColors.primaryBlue}
          weight={700}
          align={'left'}
          style={{ paddingBottom: '20px' }}
        >
          {props.data.title}
        </TWH3>
        {props.data.faqQuestion?.map((item) => {
          return (
            <React.Fragment key={item.id}>
              <FAQAccordion
                question={item.question}
                answerBody={item.answerBody}
                mediaURL={item.mediaURL}
              />
            </React.Fragment>
          );
        })}
        {props.activeText && (
          <Flex>
            <TWH5
              color={theme.cannonColors.primaryBlue}
              weight={600}
              align={'center'}
              onClick={() => {
                scrollTo('#top');
              }}
            >
              Back to categories
            </TWH5>
            <StyledArrow />
          </Flex>
        )}
      </MainFlex>
    </React.Fragment>
  );
};

export default SectionMiddleWare;
