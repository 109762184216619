import React from 'react';
import { IFileImage } from '../../@types';
import { MediaRounded } from '../../atoms/Image';
import { useAPIURL } from '../../hooks';
import { cannonStyled } from '../../theme';

const Container = cannonStyled('div', {
  margin: '5vh 0',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});
interface Video {
  video?: IFileImage;
  videoUrl?: string;
  videoPreviewImage?: IFileImage;
  title: string;
}

const BlogVideo = (data: Video) => {
  const API_URL = useAPIURL();

  const mediaUrl =
    data.videoUrl?.length > 0
      ? data.videoUrl
      : data.video != null
      ? `${API_URL}${data.video}`
      : null;

  return (
    <Container>
      <MediaRounded
        videoPreview={`${API_URL}${data.videoPreviewImage.url}`}
        src={mediaUrl || ''}
        alt={data.title}
        ext={data.video?.ext || ''}
      />
    </Container>
  );
};

export default BlogVideo;
