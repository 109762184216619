import { AspectRatioBox } from 'baseui/aspect-ratio-box';
import React from 'react';
import { IFileImage } from '../../@types';
import { useAPIURL, useTableOrMobile } from '../../hooks';
import { cannonStyled, NSH2, TWH5, useCannonStyletron } from '../../theme';

interface SideBySideProps {
  isMobile?: boolean;
  align?: string;
}

const Container = cannonStyled('div', (props: SideBySideProps) => ({
  width: '100%',
  margin: props.isMobile ? 0 : '20px 0',
}));

const FirstRow = cannonStyled('div', (props: SideBySideProps) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: props.isMobile ? '1fr' : '50% 50%',
  gridTemplateRows: props.isMobile ? 'auto 350px' : '1fr',
  gridTemplateAreas: props.isMobile
    ? `
    'info'
    'image'
  `
    : `
    'info image'
  `,
  marginBottom: '50px',
}));

const SecondRow = cannonStyled('div', (props: SideBySideProps) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: props.isMobile ? '1fr' : '50% 50%',
  gridTemplateRows: props.isMobile ? 'auto 350px' : '1fr',
  gridTemplateAreas: props.isMobile
    ? `
  'info'
  'image'
`
    : `
  'image info'
`,
  marginBottom: '50px',
}));

const ImgContainer = cannonStyled('div', (props: SideBySideProps) => ({
  gridArea: 'image',
  display: 'flex',
  justifyContent: props.align,
  height: '365px',
}));

const ImageDiv = cannonStyled('div', (props: SideBySideProps) => ({
  width: props.isMobile ? '100%' : '90%',
  overflow: 'hidden',
  borderRadius: '8px',
}));

const Image = cannonStyled('img', (props: SideBySideProps) => ({
  width: '100%',
  minHeight: '100%',
  objectFit: 'cover',
  objectPosition: '50% 50%',
}));

const InfoContainer = cannonStyled('div', (props: SideBySideProps) => ({
  display: 'flex',
  justifyContent: props.align,
  alignItems: 'center',
  overflow: 'hidden',
  marginTop: 0,
  paddingTop: 0,
}));

const InfoDiv = cannonStyled('div', (props: SideBySideProps) => ({
  width: props.isMobile ? '100%' : '90%',
}));

interface SideBySide {
  leftImage: IFileImage;
  leftText: string;
  leftTitle: string;
  rightImage: IFileImage;
  rightText: string;
  rightTitle: string;
}

const BlogSideBySide = (props: SideBySide) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();

  const API_URL = useAPIURL();

  let showElement = (element) => {
    return element === null ? false : true;
  }

  const showLeftImage:boolean = showElement(props.leftImage);
  const ShowRightImage:boolean = showElement(props.rightImage);

  return (
    <Container isMobile={isMobile}>
      <FirstRow isMobile={isMobile}>
        <InfoContainer align='flex-start'>
          <InfoDiv isMobile={isMobile}>
            {props.leftTitle && (
              <NSH2
                color={theme.cannonColors.primaryBlue}
                weight={700}
                align='left'
                $style={{
                  fontSize: isMobile ? '24px' : '36px',
                  marginBottom: '20px',
                }}
              >
                {props.leftTitle}
              </NSH2>
            )}

            {props.leftText && (
              <TWH5
                color={theme.cannonColors.secondarySilverDark}
                weight={400}
                margin={20}
                align={'left'}
                $style={{
                  fontSize: `min(3vw, 20px)`,
                }}
              >
                {props.leftText}
              </TWH5>
            )}
          </InfoDiv>
        </InfoContainer>

        <ImgContainer align='flex-end'>
          <ImageDiv isMobile={isMobile}>
            {ShowRightImage ? (<Image isMobile={isMobile} src={API_URL + props.rightImage.url} alt={props.leftTitle} />) : ('&nbps;')}
          </ImageDiv>
        </ImgContainer>
      </FirstRow>

      <SecondRow isMobile={isMobile}>
        <ImgContainer align='flex-start'>
          <ImageDiv isMobile={isMobile}>
            {showLeftImage ? <Image isMobile={isMobile} src={API_URL + props.leftImage.url} alt={props.rightTitle} /> : ''}
          </ImageDiv>
        </ImgContainer>

        <InfoContainer align='flex-end'>
          <InfoDiv isMobile={isMobile}>
            {props.rightTitle && (
              <NSH2
                color={theme.cannonColors.primaryBlue}
                weight={700}
                align={'left'}
                $style={{
                  fontSize: isMobile ? '24px' : '36px',
                  marginBottom: '20px',
                }}
              >
                {props.rightTitle}
              </NSH2>
            )}

            {props.rightText && (
              <TWH5
                color={theme.cannonColors.secondarySilverDark}
                weight={400}
                margin={20}
                align={'left'}
                $style={{
                  fontSize: `min(3vw, 20px)`,
                }}
              >
                {props.rightText}
              </TWH5>
            )}
          </InfoDiv>
        </InfoContainer>
      </SecondRow>
    </Container>
  );
};

export default BlogSideBySide;
