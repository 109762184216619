import React, { useEffect, useState } from 'react';
import {
  CannonSafeThemeT,
  useCannonStyletron,
} from '../../../theme/CannonSafesTheme';
import { cannonStyled, NSH2, TWH4, TWParagraph3 } from '../../../theme';
import { ParagraphSmall } from 'baseui/typography';
import { usePostService, useCoreAPIURL } from '../../../hooks';
import ThanksMail from '../../../assets/thanks-mail.svg';

const SignUpContent = cannonStyled('div', {
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '100%',
});

const TextWrapper = cannonStyled('div', {
  marginLeft: '20px',
});

const SignMeUpForm = () => {
  // STATE
  const [css, theme] = useCannonStyletron();

  //HANDLERS && FUNCTIONS
  return (
    <SignUpContent>
      <ThanksMail style={{colors: 'white', fill: 'white'}}/>
      <TextWrapper>
      <NSH2
        color={theme.cannonColors.primaryWhiteLight}
        align='left'
        weight={900}
        $style={{
          fontSize: `min(3.4vw, 42px)`
        }}
      >
        Welcome to our newsletter!
      </NSH2>
      <TWH4
        color={theme.cannonColors.primaryWhiteLight}
        align='left'
        weight={400}
      >
        You'll receive a confirmation through your email
      </TWH4>
      </TextWrapper>
    </SignUpContent>
  );
};

export default SignMeUpForm;
