import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { cannonStyled, TWH5, useCannonStyletron, NSH4 } from '../../../theme';
import { CannonSafeThemeT } from '../../../theme/CannonSafesTheme';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import { IBlog } from '../../../pages/blog';
import { BlogSection } from '../../../atoms/BlogComponents';
import Arrow from '../../../assets/arrow-right.svg';
import { AspectRatioBox, AspectRatioBoxBody } from 'baseui/aspect-ratio-box';
import { RoundedButton } from '../../../atoms/Button';
const query = graphql`
  query {
    allStrapiBlogCategories {
      nodes {
        Name
      }
    }
  }
`;
interface IBlogCategory {
  allStrapiBlogCategories: {
    nodes: Array<{ Name: string }>;
  };
}

interface BlogComponentProps {
  isMobile?: boolean;
  $theme?: CannonSafeThemeT;
  bgImage?: string;
}

const MainContainer = cannonStyled('div', {
  width: '100%',
  padding: 0,
  margin: 0,
});

const HighlightedPost = cannonStyled('div', (props: BlogComponentProps) => ({
  width: '100%',
  margin: '0 auto',
  backgroundColor: props.$theme.cannonColors.primaryWhiteLight,
  height: props.isMobile ? '360px' : '398px',
  display: 'flex',
  boxShadow: `0px 4px 32px ${props.$theme.cannonColors.primaryWhiteDark}`,
  borderRadius: '16px',
  cursor: 'pointer',
}));

const ImageContainer = cannonStyled('div', (props: BlogComponentProps) => ({
  width: props.isMobile ? '100%' : '50%',
  height: 'auto',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: props.isMobile ? 0 : '8px 0 0 8px',
  backgroundImage: `url(${props.bgImage})`,
  backgroundSize: 'cover',
}));

const InfoContainer = cannonStyled('div', (props: BlogComponentProps) => ({
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Overlay = cannonStyled('div', (props: BlogComponentProps) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: props.$theme.cannonColors.secondaryBlackLight,
  opacity: 0.6,
}));

const InfoWrap = cannonStyled('div', {
  width: '70%',
  display: 'flex',
  flexDirection: 'column',
});

const TabContainer = cannonStyled('div', (props: BlogComponentProps) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-evenly',
  marginTop: '80px',
  marginBottom: '15px',
  maxWidth: '1280px',
  flexWrap: 'wrap',
}));

const ZindexTop = cannonStyled('div', {
  zIndex: 9,
  width: '80%',
  position: 'absolute',
  margin: '230px 6% 0 6%',
});

const ArrowLeft = cannonStyled(Arrow, (props: BlogComponentProps) => ({
  stroke: props.$theme.cannonColors.primaryWhite,
}));

const EachTab = cannonStyled(
  'a',
  (props: BlogComponentProps & { active: boolean }) => ({
    fontFamily: 'Titillium Web',
    fontWeight: props.active ? 700 : 400,
    color: props.active
      ? props.$theme.cannonColors.primaryBlue
      : props.$theme.cannonColors.secondarySilver,
    padding: props.isMobile ? '0 30px' : '0 6%',
    marginRight: '5px',
    marginBottom: '3vh',
    height: '30px',
    fontSize: props.isMobile ? '14px' : '16px',
    minWidth: '140px',
    maxWidth: props.isMobile ? 'none' : '140px',
    width: '100%',
    cursor: 'pointer',
    borderBottom: props.active
      ? `2px solid ${props.$theme.cannonColors.secondaryOrangeLight}`
      : `2px solid ${props.$theme.cannonColors.primaryWhiteDark}`,
    borderBottomWidth: '100%',
    ':hover': {
      borderBottom: `2px solid ${props.$theme.cannonColors.secondarySilver}`,
    },
  })
);

export default (data: IBlog) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const API_URL = useAPIURL();

  const categoryData = useStaticQuery<IBlogCategory>(query);

  const [active, setActive] = useState('All Articles');

  const handleChange = (item) => {
    setActive(item);
  };

  // Find data for the main Banner
  const mainBlogEntry = data.allStrapiBlogEntries.nodes.find(
    (li) => li.mainArticle === true
  );

  // Select blog entries when button Tab clicked
  const articleBlogs =
    active === 'All Articles'
      ? data.allStrapiBlogEntries.nodes
      : data.allStrapiBlogEntries.nodes.filter(
          (blog) => blog.blogCategory.Name === active
        );

  const category = mainBlogEntry.blogCategory.Name
    .trim()
    .toLowerCase()
    .replace(/\./g, '')
    .replace(/ /g, '-');

  const textExtract = (str) => str.slice(0, 56) + '...';

  return (
    <MainContainer>

      <HighlightedPost
        isMobile={isMobile}
        onClick={() => {
          window.location.href = `${category}/${mainBlogEntry.slug}`;
        }}
      >

        {isMobile && (
          <ImageContainer
            isMobile
            bgImage={API_URL + mainBlogEntry.mainMedia.url}
          >
            <Overlay>&nbsp;</Overlay>

            <ZindexTop>
              <TWH5
                color={theme.cannonColors.primaryWhiteLight}
                weight={400}
                align='left'
              >
                {mainBlogEntry.blogCategory.Name}
              </TWH5>
              <NSH4
                color={theme.cannonColors.primaryWhiteLight}
                weight={900}
                align='left'
                $style={{
                  fontSize: `min(5vw, 20px)`,
                }}
              >
                {mainBlogEntry.title}
              </NSH4>
              <TWH5
                color={theme.cannonColors.primaryWhiteLight}
                weight={400}
                align='left'
                $style={{
                  fontSize: `min(3vw, 20px)`,
                }}
              >
                {textExtract(mainBlogEntry.previewText)}
              </TWH5>
            </ZindexTop>
          </ImageContainer>
        )}

        {!isMobile && (
          <>
          <ImageContainer bgImage={API_URL + mainBlogEntry.mainMedia.url}>
            </ImageContainer> 
            <InfoContainer>
              <InfoWrap>
                <TWH5
                  color={theme.cannonColors.secondaryOrangeDark}
                  weight={400}
                  align='left'
                >
                  {mainBlogEntry.blogCategory.Name}
                </TWH5>
                <NSH4
                  color={theme.cannonColors.primaryBlue}
                  weight={900}
                  align='left'
                  $style={{
                    fontSize: `min(2vw, 28px)`,
                  }}
                >
                  {mainBlogEntry.title}
                </NSH4>
                <TWH5
                  color={theme.cannonColors.secondaryBlackLight}
                  weight={400}
                  align='left'
                  $style={{
                    fontSize: `min(1.4vw, 20px)`,
                    paddingBottom: '30px',
                  }}
                >
                  {mainBlogEntry.previewText}
                </TWH5>
                <RoundedButton
                  color='primary'
                  size='default'
                  endEnhancer={<ArrowLeft />}
                  $style={{
                    width: '130px',
                  }}
                  onClick={() =>
                    (window.location.href = `${mainBlogEntry.slug}`)
                  }
                >
                  View more
                </RoundedButton>
              </InfoWrap>
            </InfoContainer>
          </>
        )}
        
      </HighlightedPost>

      <TabContainer>
        <EachTab
          isMobile={isMobile}
          active={active === 'All Articles'}
          onClick={() => handleChange('All Articles')}
        >
          All Articles
        </EachTab>
        {categoryData.allStrapiBlogCategories.nodes.map((item) => {
          return (
            <EachTab
              isMobile={isMobile}
              active={active === item.Name}
              onClick={() => handleChange(item.Name)}
            >
              {item.Name}
            </EachTab>
          );
        })}
      </TabContainer>

      <BlogSection dataInfo={articleBlogs} />
    </MainContainer>
  );
};
