import React, { useEffect, useState } from 'react';
import {
  cannonStyled,
  NSH3,
  TWButtonMini,
  TWH5,
  TWParagraph3,
} from '../../../theme';
import {
  CannonSafeThemeT,
  useCannonStyletron,
} from '../../../theme/CannonSafesTheme';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
// import ReactImageMagnify from '@milosmladenovicwork/react-image-magnify';
import { Paragraph3 } from 'baseui/typography';
import PlayIcon from '../../../assets/play-icon.svg';
import { Wrapper } from '../../layout';
import { SmallSpace } from '../../../atoms/Space';
import { OutlinedButton, RoundedButton } from '../../../atoms/Button';
import { ResponsiveContainer } from '../../../atoms/Container';
import { IFileImage } from '../../../@types';
import { Review } from '../../../pages';
import { StarRatingFn } from '../../../atoms';
import LandingImagesCarousel from './LandingImagesCarousel';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import '../../../atoms/Image/MediaRounded.css';
import { Player, ControlBar, BigPlayButton } from 'video-react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import LocallyProductLocator from '../LocallyProductLocator';
import analyticsCustomEvent from '../../../atoms/js/analyticsCustomEvent';
import UserManual from '../../../assets/user-manual.svg';

interface ILandingProps {
  isMobile?: boolean;
  $theme?: CannonSafeThemeT;
}

const LandingContainer = cannonStyled('div', (props: ILandingProps) => ({
  position: 'relative',
  width: '100%',
  display: props.isMobile ? 'flex' : 'grid',
  flexDirection: 'column',
  gridTemplateColumns: '100px 5fr 5fr',
}));

const LandingImage = cannonStyled('div', (props: ILandingProps) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  objectFit: 'cover',
  overflow: 'hidden',
  flexDirection: 'column',
  height: props.isMobile ? '300px' : 'auto',
  paddingBottom: props.isMobile ? '30px' : '50px',
  marginTop: props.isMobile ? '24px' : '0px',
}));

const LandingImageSelector = cannonStyled('div', (props: ILandingProps) => ({
  display: 'flex',
  flexDirection: props.isMobile ? 'row' : 'column',
  alignSelf: 'center',
  height: props.isMobile ? '150px' : '540px',
  paddingBottom: props.isMobile ? '30px' : '0px',
  overflow: 'hidden',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  MsOverflowStyle: 'none',
  scrollbarWidth: 'none',
  [props.isMobile ? 'overflowX' : 'overflowY']: 'scroll',
  width: '100%',
}));

export const LandingSelectorImageContainer = cannonStyled(
  'div',
  (props: ILandingProps) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80px',
    height: '80px',
    [props.isMobile ? 'marginRight' : 'marginBottom']: '10px',
    overflow: 'hidden',
    position: 'relative',
    background: '#FFFFFF',
    border: '1px solid rgba(158, 162, 169, 0.15)',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.02)',
    borderRadius: '2px',
    // border: `1px solid ${props.$theme.cannonColors.secondarySilver}`,
    ':hover': {
      border: `1px solid ${props.$theme.cannonColors.primaryBlue}`,
      objectFit: 'cover',
    },
  })
);

export const LandingSelectorImage = cannonStyled('img', {
  height: '100%',
});

export const LandingPreliminarInfoContainer = cannonStyled('div', {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
});

export const LandingHorizontalFlex = cannonStyled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: '16px',
});

export const VideoContainer = cannonStyled(
  'div',
  ({ img = '' }: { img?: string }) => ({
    width: '80px',
    height: '80px',
    position: 'absolute',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  })
);

const VideoPlayerContainer = cannonStyled('div', (props: any) => ({
  width: '90%',
  height: '90%',
  padding: '2px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: props.$theme.cannonColors.primaryWhiteDark,
}));

const ProductWrapper = cannonStyled(
  'div',
  ({ isMobile, isTablet }: { isMobile?: boolean; isTablet?: boolean }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    marginBottom: (isMobile || isTablet) ? '20px' : 0,
    marginTop: (isMobile || isTablet) ? '40px' : '20px',
    marginLeft: '10px',
    marginRight: '10px',
    height: 'auto',
  })
);

interface IProductLandingProps {
  detailImages: {
    image: IFileImage;
    imageDescription?: string;
    youtubeLink: string;
  }[];
  displayImage: IFileImage;
  safeCategory: string;
  displayName: string;
  seriesTitle: string;
  seriesDescription: string;
  averagePrice: number;
  reviews: Array<Review>;
  upc: string;
  retailerCategory: string;
  actionBanner: string;
  activeAction: boolean;
  shopOnlineLink: string;
  modelSKU: string;
  fileUrl: any;
}

const SectionMiddleware = (props: IProductLandingProps) => {
  const [isMobile, isTablet] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();
  const { width: windowWidth } = useWindowDimensions();
  const API_URL = useAPIURL();
  const [currentImage, setCurrentImage] = useState(props.displayImage);
  const [currentImageDescription, setCurrentImageDescription] = useState('');
  const [currentTypeOfFile, setCurrentTypeOfFile] = useState('img');
  const _images = [...props.detailImages];

  const images = _images
    .map((i) => ({ ...i.image, ...{ youtubeLink: i.youtubeLink } }))
    .filter((i) => i !== null && (i.url || i.youtubeLink));

  const reviewValue = props.reviews.length
    ? (
        props.reviews.reduce((sum, review) => sum + review.value, 0) /
        props.reviews.length
      ).toFixed(1)
    : '5';

  const customWidthFlag: boolean = windowWidth > 650 && windowWidth < 900;

  useEffect(() => {
    const descriptionImage = props.detailImages.find(
      (imageProp) => imageProp.image?.id === currentImage.id
    );
    setCurrentImageDescription(descriptionImage?.imageDescription || '');
  }, [currentImage]);

  function getUrlFormated(value: string) {
    if (value.includes('youtu')) {
      const tmp = value.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      const id =
        tmp[2] !== undefined ? tmp[2].split(/[^0-9a-z_\-]/i)[0] : tmp[0];
      return 'https://www.youtube.com/embed/' + id;
    } else if (value.includes('vimeo')) {
      const tmp = value.split('.com/');
      return 'https://player.vimeo.com/video/' + tmp[1];
    } else return value;
  }

  function handleActionBannerClicked(e) {
    const hasHttps = props.shopOnlineLink.includes('http');
    analyticsCustomEvent(`Shop Online`, {
      pathName: `${
        hasHttps ? props.shopOnlineLink : 'https://' + props.shopOnlineLink
      }`,
      safe_clicked: props.displayName,
    });
    window.open(
      `${hasHttps ? props.shopOnlineLink : 'https://' + props.shopOnlineLink}`,
      '_blank'
    );
  }

  //Download function for manual
  function download(action: string) {
    if (action === null) {
      alert(`Currently, there is no pdf to download.\nSorry `);
    } else {
      const path = API_URL + props.fileUrl;
      var doc = document.createElement('a');
      doc.href = path;
      doc.download = props.fileUrl;
      document.body.appendChild(doc);
      doc.click();
      document.body.removeChild(doc);
    }
  }
  
  return (
    <ProductWrapper isMobile={isMobile} isTablet={customWidthFlag}>
        <ResponsiveContainer flexDirection='column'>
          <LandingContainer isMobile={isMobile}>
            {!isMobile && (
              <>
                {images.length > 5 ? (
                  <LandingImagesCarousel
                    vertical
                    images={images}
                    displayImage={props.displayImage}
                    onImageSelectedChange={(value) => {
                      const { url = '', ext = '' } = value;
                      setCurrentImage(value);
                      setCurrentTypeOfFile(
                        value.youtubeLink
                          ? 'frame'
                          : ext.includes('mp4')
                          ? 'video'
                          : 'img'
                      );
                    }}
                  />
                ) : (
                  <LandingImageSelector tabIndex={1}>
                    {images.map((img) => (
                      <LandingSelectorImageContainer
                        className='selectorImageContainer'
                        key={img?.id}
                        onClick={() => {
                          const { url = '', ext = '' } = img;
                          setCurrentImage(img);
                          setCurrentTypeOfFile(
                            img.youtubeLink
                              ? 'frame'
                              : ext.includes('mp4')
                              ? 'video'
                              : 'img'
                          );
                        }}
                      >
                        <LandingSelectorImage
                          src={
                            !img?.mime?.includes('video') && !img.youtubeLink
                              ? API_URL + img?.url
                              : API_URL + props.displayImage?.url
                          }
                          alt='Landing Selector Image'
                        />
                        {img?.mime?.includes('video') && (
                          <VideoContainer>
                            <PlayIcon />
                          </VideoContainer>
                        )}

                        {img.youtubeLink && (
                          <VideoContainer>
                            <PlayIcon />
                          </VideoContainer>
                        )}
                      </LandingSelectorImageContainer>
                    ))}
                  </LandingImageSelector>
                )}

                <LandingImage isMobile={isMobile}>
                  {currentTypeOfFile === 'img' ? (
                    <img
                      src={API_URL + currentImage.url}
                      style={{
                        maxHeight: '95%',
                        maxWidth: '80%',
                        objectFit: 'contain',
                      }}
                      alt={props.displayImage}
                    />
                  ) : (
                    <VideoPlayerContainer>
                      {currentTypeOfFile === 'video' ? (
                        <Player fluid autoPlay>
                          <source src={API_URL + currentImage.url} />
                          <BigPlayButton position='center' />
                          <ControlBar autoHide={true} />
                        </Player>
                      ) : (
                        <iframe
                          width='100%'
                          height='100%'
                          style={{ border: 'none' }}
                          src={getUrlFormated(currentImage.youtubeLink || '')}
                          title='YouTube video player'
                          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        ></iframe>
                      )}
                    </VideoPlayerContainer>
                  )}

                  {/* <ReactImageMagnify
                  isActivatedOnTouch={isMobile}
                  imageStyle={{ objectFit: 'contain', aspectRatio: '1.4' }}
                  {...{
                    smallImage: {
                      alt: 'Wristwatch by Ted Baker London',
                      isFluidWidth: true,
                      src: API_URL + currentImage?.url,
                      sizes: 'maxWidth: 100px',
                    },
                    largeImage: {
                      src: API_URL + currentImage?.url,
                      width: 1000,
                      height: 1600,
                    },
                  }}
                /> */}
                  <Paragraph3
                    className={css({
                      fontFamily: 'Titillium Web !important',
                      fontSize: '12px !important',
                      fontWeight: 400,
                      textAlign: 'center',
                      marginLeft: '5%',
                      marginRight: '5%',
                      margintop: '12px',
                      color: theme.cannonColors.secondarySilver,
                    })}
                  >
                    {currentImageDescription}
                  </Paragraph3>
                </LandingImage>
                <LandingPreliminarInfoContainer>
                  <TWH5
                    aria-label={props.safeCategory}
                    color={theme.cannonColors.secondarySilverDark}
                    weight={700}
                    align='left'
                  >
                    {props.safeCategory}
                  </TWH5>
                  <NSH3
                    aria-label={props.displayName}
                    $style={{ textTransform: 'uppercase' }}
                    color={theme.cannonColors.primaryBlue}
                    weight={900}
                    align='left'
                  >
                    {props.displayName}
                  </NSH3>
                  <TWButtonMini
                    color={theme.cannonColors.secondarySilver}
                    weight={700}
                    align='left'
                    $style={{ margin: `8px 0px` }}
                  >
                    Model: {props?.modelSKU?.toLocaleUpperCase()}
                  </TWButtonMini>
                  <LandingHorizontalFlex>
                    <StarRatingFn ratingValue={parseFloat(reviewValue)} />
                    <SmallSpace direction='horizontal' />
                    <TWParagraph3
                      align='left'
                      color={theme.cannonColors.secondarySilver}
                      weight={400}
                    >
                      {reviewValue} / 5
                    </TWParagraph3>
                  </LandingHorizontalFlex>
                  <TWH5
                    $style={{ fontSize: '20px', marginTop: '10px' }}
                    color='#111111'
                    weight={700}
                    align='left'
                  >
                    {props.seriesTitle} Series
                  </TWH5>
                  <TWButtonMini
                    aria-label={
                      props.seriesDescription
                        ? props.seriesDescription
                        : 'Series Description text empty'
                    }
                    $style={{ fontSize: '16px' }}
                    color={theme.cannonColors.secondarySilverDark}
                    weight={400}
                    align='left'
                  >
                    {props.seriesDescription}
                  </TWButtonMini>
                  {/* <LandingHorizontalFlex>
                  <TWH5
                    color={theme.cannonColors.primaryGreen}
                    align='left'
                    weight={700}
                  >
                    ${props.averagePrice}
                  </TWH5>
                  <SmallSpace direction='horizontal' />
                  <TWParagraph3
                    $style={{ fontSize: '13px' }}
                    color={theme.cannonColors.secondarySilverDark}
                    align='left'
                    weight={400}
                  >
                    *Average Pricing. Retailers prices may vary
                  </TWParagraph3>
                </LandingHorizontalFlex> */}
                  <LandingHorizontalFlex></LandingHorizontalFlex>

                  <LandingHorizontalFlex>
                    {/* HOTFIX for now in future releases has to be the retailre button action back */}
                    {props.activeAction ? (
                      <>
                        <div
                          className={css({
                            width: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                          })}
                        >
                          <div
                            className={css({
                              display: 'flex',
                              flexDirection: 'row',
                            })}
                          >
                            <OutlinedButton
                              onClick={() => {
                                scrollTo('#specs');
                              }}
                              clean
                              color='secondary'
                              size='compact'
                            >
                              View specs
                            </OutlinedButton>
                            <RoundedButton
                              onClick={(e) => {
                                handleActionBannerClicked(e);
                              }}
                              clean
                              color='secondary'
                              size='compact'
                              $style={{
                                marginLeft: '20px',
                                padding: '12px 14px',
                              }}
                            >
                              {props.actionBanner}
                            </RoundedButton>
                          </div>
                          <OutlinedButton
                            href={API_URL + props.fileUrl}
                            // onClick={() => download(props.fileUrl)}
                            onClick={event => {
                              event.preventDefault();
                              window.location.href = '/user-manuals';
                            }}
                            color='primary'
                            size='compact'
                            startEnhancer={
                              <UserManual
                                className={css({
                                  fill: theme.cannonColors.primaryBlue,
                                })}
                              />
                            }
                            isMobile={isMobile}
                            isTablet={isTablet}
                            $style={{ marginTop: '10px', width: 'auto' }}
                          >
                            Search Manual By Lock Type
                          </OutlinedButton>
                        </div>
                      </>
                    ) : (
                      <div
                        className={css({
                          width: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                        })}
                      >
                        <RoundedButton
                          onClick={() => {
                            scrollTo('#specs');
                          }}
                          clean
                          color='secondary'
                          size='compact'
                        >
                          View specs
                        </RoundedButton>
                        <OutlinedButton
                          href={API_URL + props.fileUrl}
                          // onClick={() => download(props.fileUrl)}
                          onClick={event => {
                            event.preventDefault();
                            window.location.href = '/user-manuals';
                          }}
                          color='primary'
                          size='compact'
                          style={{marginTop: '20px'}}
                        >
                          Search Manual By Lock Type
                        </OutlinedButton>
                      </div>
                    )}
                  </LandingHorizontalFlex>
                  {/* For feature release */}
                  <LocallyProductLocator
                    upc={props.upc}
                    retailerCategory={props.retailerCategory}
                  />
                </LandingPreliminarInfoContainer>
              </>
            )}
            {isMobile && (
              <div>
                <LandingPreliminarInfoContainer>
                  <TWH5
                    color={theme.cannonColors.secondarySilverDark}
                    weight={700}
                    align='left'
                    $style={{ fontSize: '17px', overflow: 'auto' }}
                  >
                    {props.safeCategory}
                  </TWH5>
                  <NSH3
                    $style={{ textTransform: 'uppercase', fontSize: '24px' }}
                    color={theme.cannonColors.primaryBlue}
                    weight={800}
                    align='left'
                    style={{ overflow: 'auto' }}
                  >
                    {props.displayName}
                  </NSH3>
                  <TWButtonMini
                    color={theme.cannonColors.secondarySilver}
                    weight={700}
                    align='left'
                    $style={{ margin: `5px 0px`, overflow: 'auto' }}
                  >
                    Model: {props?.modelSKU?.toLocaleUpperCase()}
                  </TWButtonMini>
                  <LandingHorizontalFlex $style={{ marginTop: '10px' }}>
                    <StarRatingFn ratingValue={parseFloat(reviewValue)} />
                    <SmallSpace direction='horizontal' />
                    <TWParagraph3
                      align='left'
                      color={theme.cannonColors.secondarySilver}
                      weight={400}
                      style={{ overflow: 'auto' }}
                    >
                      {reviewValue} / 5
                    </TWParagraph3>
                  </LandingHorizontalFlex>
                </LandingPreliminarInfoContainer>
                <LandingImage isMobile={isMobile}>
                  {currentTypeOfFile === 'img' ? (
                    <img
                      src={API_URL + currentImage.url}
                      style={{ height: '250px' }}
                      alt=''
                    />
                  ) : (
                    <VideoPlayerContainer>
                      {currentTypeOfFile === 'video' ? (
                        <Player fluid autoPlay>
                          <source src={API_URL + currentImage.url} />
                          <BigPlayButton position='center' />
                          <ControlBar autoHide={true} />
                        </Player>
                      ) : (
                        <iframe
                          style={{ border: 'none' }}
                          width='100%'
                          height='100%'
                          src={getUrlFormated(currentImage.youtubeLink || '')}
                          title='YouTube video player'
                          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        ></iframe>
                      )}
                    </VideoPlayerContainer>
                  )}
                  <Paragraph3
                    color={theme.cannonColors.secondarySilverDark}
                    className={css({
                      fontFamily: 'Titillium Web !important',
                      fontSize: '10px !important',
                      fontWeight: 400,
                      marginRight: '5%',
                      marginLeft: '5%',
                      textAlign: 'center',
                      marginTop: '12px',
                    })}
                  >
                    {currentImageDescription}
                  </Paragraph3>
                </LandingImage>
                {images.length > Math.floor(windowWidth / 80) ? (
                  <LandingImagesCarousel
                    displayImage={props.displayImage}
                    images={images}
                    onImageSelectedChange={(value) => {
                      const { url = '', ext = '' } = value;
                      setCurrentImage(value);
                      setCurrentTypeOfFile(
                        value.youtubeLink
                          ? 'frame'
                          : ext.includes('mp4')
                          ? 'video'
                          : 'img'
                      );
                    }}
                  />
                ) : (
                  <LandingImageSelector isMobile>
                    {images.map((img) => (
                      <LandingSelectorImageContainer
                        className='selectorImageContainer'
                        key={img.id}
                        onClick={() => {
                          setCurrentImage(img);
                          const descriptionImage = props.detailImages.find(
                            (imageProp) => imageProp.image?.id === img.id
                          );
                          const { url = '', ext = '' } = img;
                          setCurrentTypeOfFile(
                            img.youtubeLink
                              ? 'frame'
                              : ext.includes('mp4')
                              ? 'video'
                              : 'img'
                          );
                          if (descriptionImage) {
                            setCurrentImageDescription(
                              descriptionImage.imageDescription || ''
                            );
                          }
                        }}
                        isMobile
                      >
                        <LandingSelectorImage
                          src={
                            !img?.mime?.includes('video') && !img.youtubeLink
                              ? API_URL + img?.url
                              : API_URL + props.displayImage?.url
                          }
                          alt='Landing Selector Image'
                        />
                        {img?.mime?.includes('video') && (
                          <VideoContainer>
                            <PlayIcon />
                          </VideoContainer>
                        )}

                        {img.youtubeLink && (
                          <VideoContainer>
                            <PlayIcon />
                          </VideoContainer>
                        )}
                      </LandingSelectorImageContainer>
                    ))}
                  </LandingImageSelector>
                )}
                <LandingPreliminarInfoContainer>
                  <TWH5
                    $style={{ fontSize: '19px' }}
                    color='#111111'
                    weight={700}
                    align='left'
                    style={{ overflow: 'auto' }}
                  >
                    {props.seriesTitle} Series
                  </TWH5>
                  <TWButtonMini
                    aria-label={`${props.seriesTitle} series description`}
                    $style={{ fontSize: '14.5px' }}
                    color={theme.cannonColors.secondarySilverDark}
                    weight={400}
                    align='left'
                    style={{ overflow: 'auto' }}
                  >
                    {props.seriesDescription}
                  </TWButtonMini>
                  <LandingHorizontalFlex>
                    <TWH5
                      aria-label={props.averagePrice}
                      $style={{ fontSize: '17px' }}
                      color={theme.cannonColors.primaryGreen}
                      align='left'
                      weight={700}
                      style={{ overflow: 'auto' }}
                    >
                      ${props.averagePrice}
                    </TWH5>
                    <SmallSpace direction='horizontal' />
                    <TWParagraph3
                      color={theme.cannonColors.secondarySilverDark}
                      align='left'
                      weight={400}
                      style={{ overflow: 'auto' }}
                    >
                      *Average Pricing. Retailers prices may vary
                    </TWParagraph3>
                  </LandingHorizontalFlex>

                  {/* HOTFIX for now in future releases has to be the retailre button action back */}
                  {props.activeAction ? (
                    <div
                      className={css({
                        width: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '10px',
                      })}
                    >
                      <div
                        className={css({
                          width: 'auto',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-evenly',
                        })}
                      >
                        <OutlinedButton
                          onClick={() => {
                            scrollTo('#specs');
                          }}
                          clean
                          color='secondary'
                          size='compact'
                          $style={{ width: '50%' }}
                        >
                          View specs
                        </OutlinedButton>
                        <RoundedButton
                          onClick={(e) => {
                            handleActionBannerClicked(e);
                          }}
                          clean
                          color='secondary'
                          size='compact'
                          $style={{
                            marginLeft: '20px',
                            padding: '12px 14px',
                            width: '50%',
                          }}
                        >
                          {props.actionBanner}
                        </RoundedButton>
                      </div>
                      <OutlinedButton
                        href={API_URL + props.fileUrl}
                        // onClick={() => download(props.fileUrl)}
                        onClick={event => {
                          event.preventDefault();
                          window.location.href = '/user-manuals';
                        }}
                        color='primary'
                        size='compact'
                        startEnhancer={
                          <UserManual
                            className={css({
                              fill: theme.cannonColors.primaryBlue,
                            })}
                          />
                        }
                        isMobile={isMobile}
                        isTablet={isTablet}
                        $style={{ marginTop: '10px', width: 'auto' }}
                      >
                        Search Manual By Lock Type
                      </OutlinedButton>
                    </div>
                  ) : (
                    <div
                      className={css({
                        display: 'flex',
                        flexDirection: 'column',
                      })}
                    >
                      <RoundedButton
                        onClick={() => {
                          scrollTo('#specs');
                        }}
                        clean
                        color='secondary'
                        size='compact'
                        isMobile={isMobile}
                        isTablet={isTablet}
                      >
                        View specs
                      </RoundedButton>
                      <OutlinedButton
                        href={API_URL + props.fileUrl}
                        // onClick={() => download(props.fileUrl)}
                        onClick={event => {
                          event.preventDefault();
                          window.location.href = '/user-manuals';
                        }}
                        color='primary'
                        size='compact'
                        startEnhancer={
                          <UserManual
                            className={css({
                              fill: theme.cannonColors.primaryBlue,
                            })}
                          />
                        }
                        $style={{
                          marginTop: '10px',
                          fontSize: `min(14px, 10px) !important`,
                          width: 'auto',
                        }}
                        isMobile={isMobile}
                        isTablet={isTablet}
                      >
                        Search Manual By Lock Type
                      </OutlinedButton>
                    </div>
                  )}
                  <LocallyProductLocator
                    upc={props.upc}
                    retailerCategory={props.retailerCategory}
                  />
                </LandingPreliminarInfoContainer>
              </div>
            )}
          </LandingContainer>
        </ResponsiveContainer>
    </ProductWrapper>
  );
};

export default SectionMiddleware;