import React from 'react';
import { useCannonStyletron } from '../../theme';

export default ({ src, alt, children }) => {
  const [css, theme] = useCannonStyletron();

  const containerClass = css({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    transform: 'scale(1.0001)',
  });

  const imgClass = css({
    minWidth: '100%',
    minHeight: '100%',
    objectFit: 'cover',
  });

  const imageContainerClass = css({
    position: 'relative',
    width: '100%',
    height: '100%',
    boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  });

  const childContainerClass = css({
    position: 'absolute',
    padding: '5%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  });

  return (
    <div className={containerClass}>
      <div className={imageContainerClass}>
        <img className={imgClass} src={src} alt={alt} />
        <div className={childContainerClass}>{children}</div>
      </div>
    </div>
  );
};
