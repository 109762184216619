import React from 'react';
import { cannonStyled } from '../../../theme';
import {
  CannonSafeThemeT,
  useCannonStyletron,
} from '../../../theme/CannonSafesTheme';
import { useAPIURL, useOrientation, useTableOrMobile } from '../../../hooks';
import { AspectRatioBox } from 'baseui/aspect-ratio-box';

interface ISeriesBannerProps {
  src?: string;
  title?: string;
  subtitle?: string;
  isMobile?: boolean;
  bannerAlign?: 'left' | 'center' | 'right';
  $theme?: CannonSafeThemeT;
  isRotated?: boolean;
}

const ContainerMain = cannonStyled(
  AspectRatioBox,
  (props: ISeriesBannerProps) => ({
    position: 'relative',
    width: '100%',
    height: '62vh',
    objectFit: 'cover',
  })
);

const ImgClass = cannonStyled('img', () => ({
  height: '100%',
  width: '100%',
  objectFit: 'cover',
}));

const TextContainer = cannonStyled('div', (props: ISeriesBannerProps) => ({
  position: 'absolute',
  padding: props.$theme.sizing.scale1400,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  marginLeft:
    props.bannerAlign === 'left'
      ? 0
      : props.bannerAlign === 'right'
      ? 'auto'
      : 'inherit',
  marginRight:
    props.bannerAlign === 'left'
      ? 'auto'
      : props.bannerAlign === 'right'
      ? 0
      : 'inherit',
  justifyContent: 'center',
  alignContent:
    props.bannerAlign === 'left'
      ? 'left'
      : props.bannerAlign === 'right'
      ? 'right'
      : 'center',
  boxShadow: '0px 7px 44px rgba(51, 51, 51, 0.25)',
  background:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(.png)',
}));

const Title = cannonStyled('h1', (props: ISeriesBannerProps) => ({
  color: 'rgba(255, 255, 255, 1) !important',
  fontFamily: 'Noto Serif !important',
  textAlign:
    props.bannerAlign === 'left'
      ? 'left'
      : props.bannerAlign === 'right'
      ? 'right'
      : 'center',
  fontSize: props.isRotated
    ? `min(5vw, 56px) !important`
    : `min(8vw, 56px) !important`,
  lineHeight: 'normal !important',
  fontWeight: 'bolder',
}));

const SubTitle = cannonStyled('h4', (props: ISeriesBannerProps) => ({
  color: 'rgba(255, 255, 255, 1) !important',
  fontFamily: 'Titillium Web !important',
  margin: 0,
  textAlign:
    props.bannerAlign === 'left'
      ? 'left'
      : props.bannerAlign === 'right'
      ? 'right'
      : 'center',
  fontSize: props.isRotated
    ? `min(2.5vw, 28px) !important`
    : `min(5vw, 28px) !important`,
  lineHeight: 'normal !important',
  fontWeight: 'normal',
}));

const SectionMiddleware = (props: ISeriesBannerProps) => {
  const [isMobile] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();
  const API_URL = useAPIURL();
  const { isRotated } = useOrientation();

  return (
    <ContainerMain isMobile={isMobile} aspectRatio={8 / 3}>
      <ImgClass src={API_URL + props.src} alt={props.title} />
      <TextContainer bannerAlign={props.bannerAlign}>
        <Title
          isRotated={isRotated}
          isMobile={isMobile}
          bannerAlign={props.bannerAlign}
        >
          {props.title}
        </Title>
        <SubTitle
          isRotated={isRotated}
          isMobile={isMobile}
          bannerAlign={props.bannerAlign}
        >
          {props.subtitle}
        </SubTitle>
      </TextContainer>
    </ContainerMain>
  );
};

export default SectionMiddleware;
function h1(
  h1: any,
  arg1: (props: ISeriesBannerProps) => {
    color: 'rgba(255, 255, 255, 1) !important';
    fontFamily: 'Noto Serif !important';
    textAlign: 'center' | 'left' | 'right';
    fontSize: string;
    lineHeight: string;
    fontWeight: 'bolder';
  }
) {
  throw new Error('Function not implemented.');
}
