import React from 'react';
import { MediaRounded } from '../../../atoms/Image';
import VideoLoop from '../../../atoms/VideoLoop';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import { cannonStyled, NSH1, TWH4, useCannonStyletron } from '../../../theme';

//Interface
interface IPropsOurLegacy {
  mediaTitle: string;
  mediaSubtitle: string;
  mediaOn: boolean;
  mediaExt: string;
  mediaLink: string;
  mediaURL: string;
}

const MainFlex = cannonStyled('div', () => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '8vh 0',
}));

const MediaFlex = cannonStyled('div', () => ({
  width: '100%',
  display: 'flex',
  marginTop: '10%',
  marginBottom: '10%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const SectionMiddleware = (props: IPropsOurLegacy) => {
  //Hooks
  const API_URL = useAPIURL();
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();

  const mediaUrl =
    props.mediaLink?.length > 0
      ? props.mediaLink
      : props.mediaURL?.length > 0
      ? `${API_URL}${props.mediaURL}`
      : null;

  return (
    <MainFlex>
      <MediaFlex>
        {props.mediaOn && (
          <>
            <NSH1
              color={theme.cannonColors.primarySolidBlack}
              weight={400}
              align={'center'}
              $style={{ fontSize: isMobile ? '40px' : '50px' }}
            >
              {props.mediaTitle}
            </NSH1>
            <TWH4
              color={theme.cannonColors.secondaryBlackLight}
              weight={400}
              className={css({ marginTop: '2%', marginBottom: '2vh' })}
              align={'center'}
            >
              {props.mediaSubtitle}
            </TWH4>
            <MediaRounded
              ext={props.mediaExt}
              src={mediaUrl}
              isOurLegacy={true}
            />
          </>
        )}
      </MediaFlex>
    </MainFlex>
  );
};

export default SectionMiddleware;
