import React, { useEffect, useState } from 'react';
import { CustomInput } from '../../../atoms';
import { useTableOrMobile } from '../../../hooks';
import {
  cannonStyled,
  NSH44,
  TWButtonMini,
  TWH3,
  useCannonStyletron,
} from '../../../theme';
import FAQMobile from '../FAQMobile/index';
import { FAQDesktop } from '../index';
import { IFAQ, IFAQQuestion, IFAQSection } from '../../../pages/faq';
import SearchIcon from '../../../assets/searchFAQ.svg';
import { useQuestionSearch } from '../../../hooks/useFilterSearch';
import FAQAccordion from '../FAQDesktop/components/FAQAccordion';

interface IFAQBannerProps {
  data: IFAQ;
}

const AccordionFlex = cannonStyled('div', () => ({
  width: '80%',
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '10%',
  alignItems: 'flex-start',
}));

const FlexResponse = cannonStyled('div', () => ({
  display: 'flex',
  width: '80%',
  marginTop: '150px',
  marginLeft: '10%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'none',
}));

const FlexWidth = cannonStyled('div', (props: { isMobile: boolean }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: props.isMobile ? '80%' : '922px',
  position: 'absolute',
  top: '300px',
}));

const WhiteSpace = cannonStyled('div', () => ({
  width: '100vw',
  height: '100%',
  marginLeft: '10%',
  marginRight: '10%',
  backgroundColor: 'none',
}));

const BodyFlex = cannonStyled('div', () => ({
  width: '100vw',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Flex = cannonStyled('div', () => ({
  width: '100vw',
  height: '350px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '200px',
  backgroundColor: '#F4F6FB',
}));

const SectionMiddleWare = (props: IFAQBannerProps) => {
  const [css, theme] = useCannonStyletron();

  //State for input
  const [sentence, setSentence] = useState('');
  const [changingBatteries, setChangingBatteries] = useState('');

  const [isMobile] = useTableOrMobile();
  //Constants
  const questionsData = props.data.strapiFaq.faqSection.reduce(
    (acc: IFAQQuestion[], section: IFAQSection) => {
      return [...acc, ...section.faqQuestion];
    },
    []
  );

  //Deleting search
  function deleteSearch() {
    setSentence('');
  }

  function handleSentence(val) {
    setSentence(val.target.value);
    // search(sentence);
  }

  useEffect(() => {
    if (typeof window === 'undefined' || !window.document) {
      return;
    } else {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const utm_source = urlParams.get('select')
      if (utm_source!= null) {
        const str = utm_source.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        setChangingBatteries(str)
      }
    }
  }, []);

  const matchedQuest = useQuestionSearch(sentence, questionsData);

  return (
    <React.Fragment>
      <BodyFlex>
        <Flex role='main' aria-label='FAQ'>
          <NSH44
            isMobile={isMobile}
            color={theme.cannonColors.primaryBlue}
            weight={900}
            align={'center'}
            style={{ fontSize: isMobile ? '31px' : '50px' }}
            aria-label='How can we help you?'
          >
            How can we help you?
          </NSH44>
          <FlexWidth isMobile={isMobile}>
            <CustomInput
              value={sentence}
              noMarginLeft
              rows={1}
              placeholder={'Write a keyword'}
              startEnhancer={
                <SearchIcon
                  className={css({
                    stroke: theme.cannonColors.secondarySilver,
                  })}
                  alt='Search Icon'
                />
              }
              noLabel={false}
              onChange={handleSentence}
            />
          </FlexWidth>
        </Flex>
        {isMobile ? (
          <FAQMobile
            data={props.data}
            sentence={sentence === '' ? true : false}
            buttonSelected={changingBatteries}
          />
        ) : (
          <FAQDesktop
            data={props.data}
            sentence={sentence === '' ? true : false}
            buttonSelected={changingBatteries}
          />
        )}
        <WhiteSpace>
          {sentence && (
            <div style={{ paddingBottom: '100px' }}>
              <FlexResponse>
                <TWH3
                  color={theme.cannonColors.primaryBlue}
                  weight={700}
                  align={'left'}
                  className={css({
                    fontSize: `min(5vw, 30px)`,
                    paddingBottom: '17px',
                  })}
                >
                  Matches with your search...
                </TWH3>
                <TWButtonMini
                  color={theme.cannonColors.primaryBlue}
                  weight={700}
                  align={'left'}
                  onClick={deleteSearch}
                  className={css({ cursor: 'pointer' })}
                >
                  Remove Search
                </TWButtonMini>
              </FlexResponse>
              {matchedQuest.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    <AccordionFlex>
                      <FAQAccordion
                        sentence={sentence}
                        question={item.question}
                        answerBody={item.answerBody}
                        mediaURL={item.mediaURL}
                      />
                    </AccordionFlex>
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </WhiteSpace>
      </BodyFlex>
    </React.Fragment>
  );
};

export default SectionMiddleWare;
