import { graphql, useStaticQuery } from 'gatsby';
import { IFileImage } from '../../../@types';

const query = graphql`
  query {
    strapiCannonQuality {
      id
      description
      displayName
      bottomDescription
      item {
        name
        value
        image {
          id
          alternativeText
          caption
          created_at
          ext
          hash
          height
          mime
          name
          provider
          size
          updated_at
          url
          width
        }
      }
    }
  }
`;

interface ICannonQuality {
  strapiCannonQuality: {
    id: number;
    description: string;
    bottomDescription: string;
    displayName: string;
    item: Array<{ name: string; value: string; image?: IFileImage }>;
  };
}

export const useCannonQuality = (): ICannonQuality => useStaticQuery(query);
