import React from 'react';
import {
  cannonStyled,
  NSH3,
  TWH1,
  TWParagraph3,
  useCannonStyletron,
} from '../../../../theme';
import CapabilityCard, {
  IThemeProp,
} from '../../../../atoms/Cards/CapabilityCard';
import { ISpecification, SpecificationCapacity } from '../../../../pages';
import { useAPIURL, useTableOrMobile } from '../../../../hooks';

// TODO: Refactor everything into individual files

const GunGrid = cannonStyled('div', (props: { isMobile?: boolean }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  // gridTemplateRows: props.isMobile ? '15vh' : 'auto',
  gridColumnGap: props.isMobile ? '10px' : '50px',
  width: '100%',
  marginBottom: '32px',
}));

export const SpecGrid = cannonStyled(
  'div',
  (props: IThemeProp & { isMobile?: boolean, isTablet?: boolean }) => ({
    display: 'grid',
    gridTemplateColumns: props.isMobile ? '1fr' : '1fr 1px 1fr',
    gridTemplateRows: props.isMobile ? 'auto 1px auto' : '1fr',
    gridGap: props.isTablet ? '20px' : '50px',
    marginTop: '25px',
    width: '100%',
  })
);

export const DividerDecorator = cannonStyled(
  'span',
  (props: IThemeProp & { color: string }) => ({
    height: '100%',
    width: '100%',
    backgroundColor: `${props.color}!important`,
  })
);

export const ItemFlex = cannonStyled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
});

export const SpecContainer = cannonStyled(
  'div',
  (props: IThemeProp & { lastItem: boolean; isMobile?: boolean }) => ({
    paddingBottom: props.isMobile ? '15px' : '6px',
    paddingTop: props.isMobile ? '15px' : '6px',
    // paddingLeft: '20px',
    // paddingRight: '20px',
    borderBottom: '1px solid #D7D9DD',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '25px',
  })
);

export const SpecValueContainer = cannonStyled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const SpecTitle = cannonStyled(
  'span',
  (props: IThemeProp & { isMobile?: boolean }) => ({
    fontFamily: 'Titillium Web',
    fontSize: props.isMobile ? '14px' : 'min(3vw, 15px)',
    fontWeight: 400,
    textAlign: 'left',
    color: '#4E4E4E',
    height: props.isMobile ? '32px' : '64px',
    display: 'flex',
    alignItems: 'center',
  })
);

export const SpecDescription = cannonStyled(
  NSH3,
  (props: IThemeProp & { isMobile?: boolean }) => ({
    fontFamily: 'Titillum Web !important',
    fontSize: props.isMobile ? '14px' : 'min(3vw, 15px)',
    display: 'flex',
    alignItems: 'center',
    height: props.isMobile ? '32px' : '64px',
  })
);

const Component = (props: {
  isMobile?: boolean;
  specification: ISpecification;
}) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const API_URL = useAPIURL();

  const rowSize = Math.ceil(props.specification.list.length / 2);

  return (
    <>
      <GunGrid isMobile={props.isMobile}>
        {props.specification.support.map((gun) => (
          <CapabilityCard
            key={gun.id}
            isMobile={props.isMobile}
            imgSrc={API_URL + gun.image?.url}
            title={`${gun.value}`}
            description={gun.name}
          />
        ))}
      </GunGrid>
      <SpecGrid isMobile={props.isMobile}>
        {props.isMobile ? (
          <div>
            {props.specification.list.map((spec, index) => (
              <SpecContainer
                key={spec.id}
                isMobile={props.isMobile}
                lastItem={index === props.specification.list.length - 1}
              >
                <SpecTitle isMobile>{spec.name}</SpecTitle>
                <SpecValueContainer>
                  <TWH1
                    style={{ fontSize: '14px' }}
                    color={theme.cannonColors.primaryBlue}
                    align='left'
                    weight={700}
                  >
                    {spec.value}
                  </TWH1>
                </SpecValueContainer>
              </SpecContainer>
            ))}
          </div>
        ) : (
          <>
            <div>
              {props.specification.list.slice(0, rowSize).map((spec, index) => (
                <SpecContainer key={spec.id} lastItem={index === rowSize - 1}>
                  <SpecTitle>{spec.name}</SpecTitle>
                  <SpecValueContainer>
                    <TWH1
                      style={{ fontSize: '16px' }}
                      color={theme.cannonColors.primaryBlue}
                      align='left'
                      weight={700}
                    >
                      {spec.value}
                    </TWH1>
                  </SpecValueContainer>
                </SpecContainer>
              ))}
            </div>
            <DividerDecorator color={'#969BA6'} />
            <div>
              {props.specification.list.slice(rowSize).map((spec, index) => (
                <SpecContainer key={spec.id} lastItem={index === rowSize - 1}>
                  <SpecTitle>{spec.name}</SpecTitle>
                  {/* <SpecDescription
                    align='left'
                    weight={700}
                    color={theme.cannonColors.primaryBlue}
                  >
                    {spec.value}
                  </SpecDescription> */}
                  <SpecValueContainer>
                    <TWH1
                      style={{ fontSize: '16px' }}
                      color={theme.cannonColors.primaryBlue}
                      align='left'
                      weight={700}
                    >
                      {spec.value}
                    </TWH1>
                  </SpecValueContainer>
                </SpecContainer>
              ))}
            </div>
          </>
        )}
      </SpecGrid>
    </>
  );
};

export default Component;
