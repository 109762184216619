import React from 'react';
import { Button } from '../../../atoms';
import { GridContainer, ResponsiveContainer } from '../../../atoms/Container';
import { cannonStyled, useCannonStyletron, NSH2, TWH4 } from '../../../theme';
import { Wrapper } from '../../layout';
import { useTableOrMobile } from '../../../hooks';
import { ICannonSafe } from '../../../pages';
import Carousel, {
  arrowsPlugin,
  slidesToShowPlugin,
  slidesToScrollPlugin,
} from '@brainhubeu/react-carousel';
import { useMediaQuery } from 'react-responsive';
import ArrowLeft from '../../../assets/arrow-left.svg';
import ArrowRight from '../../../assets/arrow-right.svg';
import '@brainhubeu/react-carousel/lib/style.css';
import SeriesCard from '../../../atoms/Cards/SeriesCard';

interface ISeriesDisplay {
  title: string;
  enabled: boolean;
  description: string;
  action: string;
  list?: Array<ICannonSafe>;
}

const MainWrapper = cannonStyled(Wrapper, {
  margin: '90px 0px',
});

const Subtitle = cannonStyled(TWH4, {
  fontSize: `min(4vw, 24px) !important`,
});

const SectionMiddleware = (data: ISeriesDisplay) => {
  const [css, theme] = useCannonStyletron();
  const isTablet = useMediaQuery({
    query: `(min-width: 800px) and (max-width: 1279px)`,
  });

  const [isMobile] = useTableOrMobile();
  const maxShow = isTablet ? 2 : isMobile ? 1 : 3;
  const maxSlide = 1;
  const safesLength = Object.keys(data.list).length;

  if (!data.enabled) return null;

  if (safesLength < 3) {
    return (
      <MainWrapper
        noScroll
        compact
        isPadded
        autoHeight
        isCentered
        direction='vertical'
      >
        <NSH2
          align='center'
          color={theme.cannonColors.primaryBlue}
          weight={900}
        >
          {(data.title || '').toUpperCase()}
        </NSH2>
        <Subtitle
          align='center'
          color={theme.cannonColors.primaryGray}
          weight={400}
        >
          {data.description}
        </Subtitle>
        <ResponsiveContainer
          flexDirection={isMobile ? 'column' : 'row'}
          $style={{
            marginTop: '90px',
            marginBottom: '90px',
          }}
        >
          <GridContainer
            width={'auto'}
            spacing={'9vh'}
            isTablet={isTablet}
            isMobile={isMobile}
            direction={
              isTablet ? 'vertical' : isMobile ? 'vertical' : 'horizontal'
            }
            items={safesLength}
            className={css({
              justifyContent: 'center',
              alignContent: 'center',
            })}
          >
            {data.list.map((cannonSafe) => (
              <SeriesCard
                action={() =>
                  (window.location.href = `/product/${cannonSafe.slug}`)
                }
                displayName={cannonSafe.displayName}
                averagePrice={cannonSafe.averagePrice}
                displayImage={cannonSafe.displayImage.url}
                height={cannonSafe.extDimensions.height}
                width={cannonSafe.extDimensions.width}
                depth={cannonSafe.extDimensions.depth}
                weight={cannonSafe.weight}
                firearms={cannonSafe.firearmCapacity}
                waterIcon={cannonSafe.features.floodProtection}
              />
            ))}
          </GridContainer>
        </ResponsiveContainer>
      </MainWrapper>
    );
  } else {
    return (
      <>
        <MainWrapper
          noScroll
          compact
          isPadded
          autoHeight
          isCentered
          direction='vertical'
        >
          <NSH2
            isMobile={isMobile}
            align='center'
            color={theme.cannonColors.primaryBlue}
            weight={900}
          >
            {(data.title || '').toUpperCase()}
          </NSH2>
          <Subtitle
            isMobile={isMobile}
            align='center'
            color={theme.cannonColors.primaryGray}
            weight={400}
          >
            {data.description}
          </Subtitle>
          <ResponsiveContainer
            $style={{
              marginTop: '90px',
              marginBottom: '90px',
            }}
          >
            <Carousel
              // offset={10}
              isTablet={isTablet}
              isMobile={isMobile}
              plugins={[
                {
                  resolve: arrowsPlugin,
                  options: {
                    arrowLeft: (
                      <Button.IconButton
                        $style={{
                          backgroundColor: theme.cannonColors.primaryBlue,
                          borderRadius: '4px',
                        }}
                      >
                        <ArrowLeft style={{ stroke: 'white' }} />
                      </Button.IconButton>
                    ),
                    arrowLeftDisabled: (
                      <Button.IconButton
                        $style={{
                          backgroundColor: theme.cannonColors.primaryBlue,
                          borderRadius: '4px',
                        }}
                      >
                        <ArrowLeft style={{ stroke: 'white' }} />
                      </Button.IconButton>
                    ),
                    arrowRight: (
                      <Button.IconButton
                        $style={{
                          backgroundColor: theme.cannonColors.primaryBlue,
                          borderRadius: '4px',
                        }}
                      >
                        <ArrowRight style={{ stroke: 'white' }} />
                      </Button.IconButton>
                    ),
                    arrowRightDisabled: (
                      <Button.IconButton
                        $style={{
                          backgroundColor: theme.cannonColors.primaryBlue,
                          borderRadius: '4px',
                        }}
                      >
                        <ArrowRight style={{ stroke: 'white' }} />
                      </Button.IconButton>
                    ),
                    addArrowClickHandler: true,
                  },
                },
                'infinite',
                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: maxShow,
                  },
                },
                {
                  resolve: slidesToScrollPlugin,
                  options: {
                    numberOfSlides: maxSlide,
                  },
                },
              ]}
            >
              {data.list.map((cannonSafe) => (
                <SeriesCard
                  action={() =>
                    (window.location.href = `/product/${cannonSafe.slug}`)
                  }
                  slug={cannonSafe.slug}
                  displayName={cannonSafe.displayName}
                  averagePrice={cannonSafe.averagePrice}
                  displayImage={cannonSafe.displayImage.url}
                  height={cannonSafe.extDimensions.height}
                  width={cannonSafe.extDimensions.width}
                  depth={cannonSafe.extDimensions.depth}
                  weight={cannonSafe.weight}
                  firearms={cannonSafe.firearmCapacity}
                  waterIcon={cannonSafe.features.floodProtection}
                />
              ))}
            </Carousel>
          </ResponsiveContainer>
        </MainWrapper>
      </>
    );
  }
};

export default SectionMiddleware;
