import React, { useEffect, useMemo, useState } from 'react';
import { cannonStyled, TWH5 } from '../../../theme';
import { CannonSafeThemeT } from '../../../theme/CannonSafesTheme';
import { useTableOrMobile } from '../../../hooks';
import { IHomeBanner } from '../../../pages';
import { Paragraph2 } from 'baseui/typography';
import { Button } from '../../../atoms';
import CloseIcon from '../../../assets/close-icon.svg';

interface IMainBannerProps {
  isMobile: boolean;
  isRotated?: boolean;
  $theme?: CannonSafeThemeT;
  bgColor?: string;
}

const MainContainer = cannonStyled('div', (props: IMainBannerProps) => ({
  height: 'fit-content',
  width: '100%',
  background: props.bgColor,
  position: 'fixed',
  zIndex: 100,
}));

const FlexRowContainer = cannonStyled('div', () => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '5px 20px 0px 10px',
}));

const SectionMiddleware = (data: IHomeBanner) => {
  const [isMobile] = useTableOrMobile();
  const [isClosed, setIsClosed] = useState(false);

  const handleCloseBanner = () => {
    setIsClosed(true);
  };

  useEffect(() => {
    if (localStorage.getItem('cannonHomeBanner') === 'closed') {
      setIsClosed(true);
    }
  }, [localStorage]);

  useEffect(() => {
    if (
      data?.strapiHomeBanner &&
      data.strapiHomeBanner.bannerEnabled === false
    ) {
      localStorage.removeItem('cannonHomeBanner');
    }
  }, [data?.strapiHomeBanner?.bannerEnabled]);

  if (!data.strapiHomeBanner.bannerEnabled || isClosed) return null;

  return (
    <MainContainer
      bgColor={data.strapiHomeBanner.bannerBackgroundColor}
      isMobile={isMobile}
    >
      <FlexRowContainer>
        <div>
          {data.strapiHomeBanner.bannerTitle && (
            <TWH5
              color={data.strapiHomeBanner.bannerTextColor}
              weight={'500'}
              align={'left'}
            >
              {data.strapiHomeBanner.bannerTitle}
            </TWH5>
          )}
          {data.strapiHomeBanner.bannerBodyText && (
            <Paragraph2
              marginTop={'5px'}
              marginBottom={'10px'}
              color={data.strapiHomeBanner.bannerTextColor}
            >
              {data.strapiHomeBanner.bannerBodyText} {isClosed?.toString()}
            </Paragraph2>
          )}
        </div>
        <Button.IconButton aria-label='facebook' onClick={handleCloseBanner}>
          <CloseIcon />
        </Button.IconButton>
      </FlexRowContainer>
    </MainContainer>
  );
};

export default SectionMiddleware;
