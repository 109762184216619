import React, { Key, useState } from 'react';
import { cannonStyled, NSH2 } from '../../../theme';
import { useCannonStyletron } from '../../../theme/CannonSafesTheme';
import { useTableOrMobile } from '../../../hooks';
import { Wrapper } from '../../layout';
import { ResponsiveContainer } from '../../../atoms/Container';
import { Tabs, Tab, FILL, ORIENTATION } from 'baseui/tabs-motion';
import { VideosGallery } from './components';
import { IVideoInfo } from '../../../pages/videos';

interface IVideosProps {
  videosArr: Array<IVideoInfo>;
  title: string;
}

const ContainerWrapper = cannonStyled(Wrapper, () => ({
  width: '100%',
  padding: 0,
  margin: 0,
}));

const VideosSection = (props: IVideosProps) => {
  const tabsTitle = [
    { title: 'All videos', code: 'allVideos' },
    { title: 'How to - Help', code: 'howToHelp' },
    { title: 'Exclusive features', code: 'exclusiveFeatures' },
    { title: 'Cannon Series', code: 'cannonSeries' },
    { title: 'Warranty - Fire Protection', code: 'warrantyFireProtection' },
    { title: 'FAQs', code: 'faqs' },
  ];

  const [isMobile] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();
  const [active, setActive] = useState<Key>(tabsTitle[0].title);

  const filterVideosByCategory = (category: string) => {
    const result = props.videosArr.filter(
      (video) => video.category === category
    );
    return result;
  };

  return (
    <ContainerWrapper
      id='container'
      noScroll
      autoHeight
      isCentered
      direction='vertical'
      style={{ height: 'auto', minHeight: 'auto' }}
    >
      <ResponsiveContainer flexDirection='column'>
        <div style={{ width: '100%', marginBottom: '20px' }}>
          <NSH2
            color={theme.cannonColors.primaryBlue}
            weight={900}
            align='left'
          >
            {props.title}
          </NSH2>
        </div>
        {/* <Tabs
          activeKey={active}
          onChange={({ activeKey }) => setActive(activeKey)}
          activateOnFocus
          orientation={ORIENTATION.horizontal}
          fill={FILL.fixed}
          overrides={{
            Root: {
              style: () => ({
                minHeight: '60vh',
                // overflowX: 'scroll'
                width: '100%',
                padding: 0,
              }),
            },
            TabHighlight: {
              style: () => ({
                backgroundColor: theme.cannonColors.secondaryOrange,
                height: '2px',
                '&:hover': {
                  background: 'transparent',
                },
                // borderBottom: `2px solid ${theme.cannonColors.secondaryOrange}`
              }),
            },
            TabBorder: {
              style: () => ({
                backgroundColor: theme.cannonColors.secondarySilver,
                borderRadius: '2.5px',
                marginTop: '3px',
                height: '2px',
              }),
            },
          }}
        >
          {tabsTitle.map((specification, index) => (
            <Tab
              key={specification.title}
              title={specification.title}
              overrides={{
                Tab: {
                  style: () => ({
                    backgroundColor: 'transparent',
                    fontFamily: 'Titillium Web !important',
                    fontSize: 'min(3.4vw, 17px) !important',
                    fontWeight: active === specification.title ? 'bold' : 400,
                    color:
                      active === specification.title
                        ? theme.cannonColors.primaryBlue
                        : theme.cannonColors.secondarySilver,
                    '&:active': {
                      fontSize: '16px !important',
                    },
                    '&:hover': {
                      background: 'none',
                    },
                  }),
                },
              }}
            >
              {specification.code === 'allVideos' && (
                <>
                  {props.videosArr.length > 0 ? (
                    <VideosGallery videosArr={props.videosArr} />
                  ) : (
                    <NSH2
                      color={theme.cannonColors.primaryBlue}
                      weight={900}
                      align='center'
                      style={{ marginTop: '20px' }}
                    >
                      No videos uploaded yet
                    </NSH2>
                  )}
                </>
              )}
              {specification.code === 'howToHelp' && (
                <>
                  {filterVideosByCategory(specification.code).length > 0 ? (
                    <VideosGallery
                      videosArr={filterVideosByCategory(specification.code)}
                    />
                  ) : (
                    <NSH2
                      color={theme.cannonColors.primaryBlue}
                      weight={900}
                      align='center'
                      style={{ marginTop: '20px' }}
                    >
                      No <i>How to videos</i> uploaded yet
                    </NSH2>
                  )}
                </>
              )}
              {specification.code === 'exclusiveFeatures' && (
                <>
                  {filterVideosByCategory(specification.code).length > 0 ? (
                    <VideosGallery
                      videosArr={filterVideosByCategory(specification.code)}
                    />
                  ) : (
                    <NSH2
                      color={theme.cannonColors.primaryBlue}
                      weight={900}
                      align='center'
                      style={{ marginTop: '20px' }}
                    >
                      No <i>Exclusive Features</i> videos uploaded yet
                    </NSH2>
                  )}
                </>
              )}
              {specification.code === 'cannonSeries' && (
                <>
                  {filterVideosByCategory(specification.code).length > 0 ? (
                    <VideosGallery
                      videosArr={filterVideosByCategory(specification.code)}
                    />
                  ) : (
                    <NSH2
                      color={theme.cannonColors.primaryBlue}
                      weight={900}
                      align='center'
                      style={{ marginTop: '20px' }}
                    >
                      No <i>Cannon Series</i> videos uploaded yet
                    </NSH2>
                  )}
                </>
              )}
              {specification.code === 'warrantyFireProtection' && (
                <>
                  {filterVideosByCategory(specification.code).length > 0 ? (
                    <VideosGallery
                      videosArr={filterVideosByCategory(specification.code)}
                    />
                  ) : (
                    <NSH2
                      color={theme.cannonColors.primaryBlue}
                      weight={900}
                      align='center'
                      style={{ marginTop: '20px' }}
                    >
                      No <i>Warranty Videos</i> uploaded yet
                    </NSH2>
                  )}
                </>
              )}
              {specification.code === 'faqs' && (
                <>
                  {filterVideosByCategory(specification.code).length > 0 ? (
                    <VideosGallery
                      videosArr={filterVideosByCategory(specification.code)}
                    />
                  ) : (
                    <NSH2
                      color={theme.cannonColors.primaryBlue}
                      weight={900}
                      align='center'
                      style={{ marginTop: '20px' }}
                    >
                      No <i>FAQs</i> videos uploaded yet
                    </NSH2>
                  )}
                </>
              )}
            </Tab>
          ))}
        </Tabs> */}
        <VideosGallery videosArr={props.videosArr} />
      </ResponsiveContainer>
    </ContainerWrapper>
  );
};

export default VideosSection;
