import React from 'react';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import { cannonStyled, NSH1, TWH4, useCannonStyletron } from '../../../theme';

interface ILegacyBannerProps {
  title: string;
  subtitle: string;
  url: string;
}

const BannerFlex = cannonStyled(
  'div',
  (props: { url: string; isMobile: boolean }) => ({
    width: '100vw',
    height: '100vh',
    backgroundImage: `url(${props.url})`,
    backgroundSize: `cover`,
    backgroundPosition: `50% 50%`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: props.isMobile ? 'scroll' : 'fixed',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  })
);

const ColorOverlay = cannonStyled('div', (props: { isMobile: boolean }) => ({
  backgroundColor: 'rgba(0,0,0,0.4)',
  position: 'absolute',
  zIndex: 1,
  top: 0,
  right: 0,
  // height: "97vh",
  height: '100vh',
  width: '100%',
}));

const BannerTextFlex = cannonStyled('div', () => ({
  margin: '20%',
  zIndex: 2,
}));

const SectionMiddleware = (props: ILegacyBannerProps) => {
  const API_URL = useAPIURL();
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();

  return (
    <BannerFlex url={API_URL + props.url} isMobile={isMobile}>
      <BannerTextFlex>
        <NSH1
          color={theme.cannonColors.primaryWhite}
          weight={900}
          align={'center'}
        >
          {props.title}
        </NSH1>
        <TWH4
          color={theme.cannonColors.primaryWhite}
          weight={400}
          align={'center'}
          className={css({ paddingTop: '20px' })}
        >
          {props.subtitle}
        </TWH4>
      </BannerTextFlex>
      <ColorOverlay isMobile={isMobile} />
    </BannerFlex>
  );
};

export default SectionMiddleware;
