import React from 'react';
import { ResponsiveContainer } from '../../../atoms/Container';
import { ImageWithCenteredC } from '../../../atoms/Image';
import { useAPIURL, useNewLineText, useTableOrMobile } from '../../../hooks';

import { NSH1, TWH4, useCannonStyletron, cannonStyled } from '../../../theme';
import { IPLActionBanner } from '../../../@types';
import { Button } from '../../../atoms';

const ButtonContainer = cannonStyled('div', {
  display: 'flex',
  columnGap: '16px',
  marginTop: '24px',
});

const WrapperDiv = cannonStyled('div', (props: { isMobile: boolean }) => ({
  height: props.isMobile ? '480px' : `calc(100vh - 113px)`,
  minHeight: '500px',
  maxHeight: '780px',
  position: 'relative',
  margin: 0,
  padding: 0,
}));

const Overlay = cannonStyled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 0,
  background:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(.png)',
});

const SectionMiddleware = (data: IPLActionBanner) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const API_URL = useAPIURL();
  let colorCss = 'tertiary';

  if (!data.enabled) return null;

  const title = useNewLineText(
    data.title
  );
  const description = useNewLineText(
    data.description
  );

  return (
    <WrapperDiv isMobile={isMobile}>
      <ImageWithCenteredC
        alt={''}
        src={API_URL + data.image.url}
      >
        <Overlay />
        <ResponsiveContainer
          $style={{
            paddingBottom: isMobile ? '8%' : '5%',
            paddingTop: '5%',
            zIndex: 1,
            minHeight: '350px',
          }}
          bottomLeft
          fullHeight
          flexDirection='column'
        >
          <NSH1
            color={theme.cannonColors.primaryWhiteLight}
            margin={0}
            align='left'
            weight={900}
            $style={{ fontSize: 'min(8vw, 50px)' }}
          >
            {title}
          </NSH1>
          <TWH4
            $style={{ maxWidth: '70%' }}
            color={theme.cannonColors.primaryWhiteLight}
            align='left'
            weight={400}
            margin={10}
          >
            {description}
          </TWH4>
          <ButtonContainer>
            {
            data.actions && (data.actions.map(
              (element, i) => (
                (colorCss = i % 2 == 0 ? 'tertiary' : 'secondary'),
                (
                  <Button.RoundedButton
                    size='compact'
                    margin={20}
                    color={colorCss}
                    onClick={() => (window.location.href = element.linkToPage)}
                  >
                    {element.title}
                  </Button.RoundedButton>
                )
              )
            ))}
          </ButtonContainer>
        </ResponsiveContainer>
      </ImageWithCenteredC>
    </WrapperDiv>
  );
};

export default SectionMiddleware;