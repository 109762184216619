import { number } from 'joi';
import React from 'react';
import { useTableOrMobile } from '../../hooks';
import { useCannonStyletron } from '../../theme';

interface Props {
  width?: string;
  src?: string;
  isLoop?: boolean;
  hasControls?: boolean;
  hasAutoPlay?: boolean;
}

const VideoLoop = ({
  width = '',
  src = '',
  isLoop = true,
  hasControls = false,
  hasAutoPlay = true,
}: Props) => {
  // HOOKS

  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();

  // CLASSES

  const fullScreenVideoContainer = css({
    position: 'relative',
    height: '100%',
    width: width ? width : '90vw',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    borderRadius: isMobile ? '12px' : '',
    WebkitBorderRadius: isMobile ? '12px' : '',
    MozBorderRadius: isMobile ? '12px' : '',
    KhtmlBorderRadius: isMobile ? '12px' : '',
    WebkitTransform: 'translateZ(0)',
  });
  const fullScreenVideoContainerVideo = css({
    position: 'absolute',
    width: width ? width : 'auto',
    height: 'auto',
    minWidth: '100%',
    minHeight: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    WebkitBorderRadius: isMobile ? '12px' : '',
    MozBorderRadius: isMobile ? '12px' : '',
    borderRadius: isMobile ? '12px' : '',
    KhtmlBorderRadius: isMobile ? '12px' : '',
    verticalAlign: 'middle',
  });

  return (
    <div className={fullScreenVideoContainer}>
      <video
        controls={hasControls}
        loop={isLoop}
        className={fullScreenVideoContainerVideo}
        autoPlay={hasAutoPlay}
        muted
        playsInline
      >
        <source src={src} type='video/mp4' />
      </video>
    </div>
  );
};

export default VideoLoop;
